import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { validator } from '../../../utils/fnValidator';
import { ConfigurableKeys } from './ConfigurableSelect';

export type ConfigurableValueDialogProps = {
    open: boolean;
    type: ConfigurableKeys;
    title: string;
    existingValue?: string | number;
    existingLabel?: string;
    tooltipKey?: string;
    onClose: () => void;
    onSave: (value: string | number, label?: string) => void;
    hideLabelField?: boolean;
};

export const ConfigurableValueDialog: FC<ConfigurableValueDialogProps> = ({
    type,
    title,
    open,
    tooltipKey,
    existingValue,
    existingLabel,
    onClose,
    onSave,
    hideLabelField
}) => {
    const [value, setValue] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [newValueError, setNewValueError] = useState<string>('');

    const isNumber = type === ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS;
    const isAlphaNumericNoSpecialCharacters =
        type === ConfigurableKeys.ITEM_ALTERATE_BEHAVIOUR_LABEL || type === ConfigurableKeys.MODULE_ALTERATE_BEHAVIOUR_LABEL;

    const validateNewValue = () => {
        const nameEror = validator({ required: true }, `${value}`);
        setNewValueError(nameEror);
        return !nameEror.length;
    };
    const handleSaveClick = () => {
        if (!validateNewValue()) return;

        onSave(isNumber ? parseInt(value) : value.trim(), label);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        setValue('');
        setLabel('');
        setNewValueError('');
        onClose?.();
    };

    const addButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    useEffect(() => {
        if (!existingValue) return;
        setValue(`${existingValue}`);
        setLabel(!hideLabelField ? existingLabel || '' : '');
    }, [existingValue]);

    if (!open) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={existingValue ? `Edit ${title}` : `Create ${title}`}
            actionButtons={[cancelButton, addButton]}
            onClose={handleCloseClick}
        >
            <DialogTextField
                value={`${value}`}
                onChange={(e: any) => {
                    if (isNumber && !e.target.value.match(/^([0-9]+)?(\.)?([0-9])?$/)) return;
                    if (isAlphaNumericNoSpecialCharacters && !e.target.value.match(/^[a-zA-Z0-9_]*$/)) return;
                    setValue(e.target.value);
                }}
                toolTipText={tooltipKey || ''}
                tooltipIsKey={!!tooltipKey}
                error={newValueError}
                placeholder={'Value'}
                label={'Value'}
            />
            {!hideLabelField && (
                <DialogTextField
                    value={label}
                    onChange={(e: any) => {
                        setLabel(e.target.value);
                    }}
                    placeholder={'Label'}
                    label={'Label'}
                    toolTipText="configurable_values_label"
                    tooltipIsKey
                    optional
                />
            )}
        </GenericDialog>
    );
};
