import React, { useEffect, useState } from 'react';
import icons from '../../../../assets/images/icons';
import { DialogTextField } from '../../../common/Dialog/GenericDialog';

import SVGInline from 'react-inlinesvg';
import PageActions from '../../../common/PageActions/PageActions';
import {
    ActionIconHolder,
    AudienceDescriptionContainer,
    AudienceListContainer,
    AudienceTemplateDescription,
    AudienceTemplateName,
    AudienceTemplateWrapper,
    AudienceTitleContainer,
    GridView,
    ItemActions,
    ItemAddIcon,
    ItemCard,
    ItemCardAdd,
    ItemIcon,
    ItemName,
    ItemType,
    ListAddNew,
    ListItemContainer,
    ListView
} from '../../../TargetGroups/UXElements/Audiences/Audiences.css';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import { Audience, audienceFilterKeys, predefinedAudienceValues } from '../../../../types/Audience';
import _ from 'lodash';
import { DIALOG_NAMES, dialogConfirm } from '../../../../utils/fnDialogs';

import { renderTooltip, renderTooltipWithKey, tooltipTypes } from '../../../common/Tooltips/Tooltips';
import { Loader } from '../../../common/Loader/Loader';
import { EMPTY_WORD_STRING } from '../../../../utils/Globals';
import { useNavigate, useParams } from 'react-router-dom';
import { Template, templateTypes } from '../../../../types/Template';
import {
    fetchTemplate,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../../../style/styled-components/reusable.css';
import { audiencesIcons } from '../../../../assets/images/icons/audiencesIcons';
import Sidebar from '../../Sidebar/Sidebar';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import {
    FieldAndToggleContainer,
    GroupField,
    GroupFieldsContainer,
    GroupNameContainer,
    GroupStatusContainer
} from '../../../TargetGroups/GroupEdit.css';
import ToggleViewSwitch from '../../../common/Switch/ToggleViewSwitch';
import { generateUID } from '../../../../utils/fnGenerator';
import NewAudience from '../../../TargetGroups/Dialogs/NewAudience';
import { PageRoutes } from '../../../../types/RouteTypes';
import { ObjectActions } from '../../../common/Actions/Actions';
import GenericTable, { HeaderTableCell, tableActions } from '../../../common/Table/Table';
import { TableRowWrapper, WidthTableCell } from '../../../common/Table/Table.css';
import { TableRow } from '@material-ui/core';
import { audienceVersionTypeSymbols } from '../../../../utils/fnData';

export const Audiences: React.FC = () => {
    const { group_id: templateId } = useParams();
    const [template, setTemplate] = useState<Template | undefined>(undefined);
    const [audiences, setAudiences] = useState<any[]>([]);
    const [view, setView] = useState<'GRID' | 'LIST'>('GRID');
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [creatingAudience, setCreatingAudience] = useState<boolean>(false);

    const { error, loading } = useAppSelector((state) => state.templates);

    //  Hold the Audience object which is being worked on
    const [audienceToEdit, setAudienceToEdit] = useState<any>(undefined);
    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState(false);

    const [openHeaderValuesDialog, setOpenHeaderValuesDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadTemplate = async () => {
        const response = await dispatch(fetchTemplate({ templateId: `${templateId}`, type: templateTypes.AUDIENCE })).unwrap();
        if (response.template) {
            let audiences: any[] = response.template.audienceValues || [];
            setAudiences(audiences);
            setTemplate(response.template);
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response) {
            loadTemplate();
        }
    };

    const releaseTemplate = async () => {
        const response = await dispatch(_releaseTemplate({ templateId: `${templateId}`, type: templateTypes.AUDIENCE })).unwrap();
        if (response) {
            loadTemplate();
        }
    };

    const retractTemplate = async () => {
        const response = await dispatch(_retractTemplate({ templateId: `${templateId}`, type: templateTypes.AUDIENCE })).unwrap();
        if (response) {
            loadTemplate();
        }
    };

    const removeAudience = async (id: string) => {
        const newTemplate = {
            ...template
        };

        const audienceValues = [...(newTemplate.audienceValues || [])];

        const index = audienceValues?.findIndex((aud: any) => aud._id === id);
        if (index >= 0) {
            audienceValues.splice(index, 1);
            newTemplate.audienceValues = audienceValues;
            updateTemplate(newTemplate);
        }
    };

    useEffect(() => {
        loadTemplate();
    }, [templateId]);

    useEffect(() => {
        setShowUnsaved(checkUnsaved());
    }, [creatingAudience, audienceToEdit]);

    const handleAddClick = () => {
        setAudienceToEdit({
            _id: generateUID(),
            name: '',
            deviceType: predefinedAudienceValues.deviceType[0]
        });
        setCreatingAudience(true);
    };

    const expandAudience = (audience: any) => {
        if (audience?._id === audienceToEdit?._id) {
            setIsEditing(false);
            setAudienceToEdit(undefined);
            return;
        }
        setAudienceToEdit(_.cloneDeep(audience));
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Audience',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeAudience(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Audience?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one!
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };
    const renderCreatingAudience = () => {
        if (!audienceToEdit) return;
        return (
            <>
                <TableRow style={{ height: '16px' }} />
                <TableRowWrapper key={audienceToEdit._id}>
                    <WidthTableCell colSpan={2} $um={'px'}>
                        <AudienceTemplateWrapper>
                            <AudienceTemplateName>
                                <DialogTextField
                                    label={'Audience Name'}
                                    value={audienceToEdit?.name || ''}
                                    onChange={(evt: any) => {
                                        audienceToEdit && setAudienceToEdit({ ...audienceToEdit, name: evt.target.value });
                                    }}
                                    placeholder={'Audience Name'}
                                />
                            </AudienceTemplateName>
                            <AudienceTemplateDescription>
                                <DialogTextField
                                    label={'Audience Description'}
                                    placeholder={'Audience Description'}
                                    value={audienceToEdit?.description || ''}
                                    onChange={(evt: any) => {
                                        audienceToEdit && setAudienceToEdit({ ...audienceToEdit, description: evt.target.value });
                                    }}
                                />
                            </AudienceTemplateDescription>
                        </AudienceTemplateWrapper>
                    </WidthTableCell>
                </TableRowWrapper>

                {/* SPACE BETWEEN ROWS */}
                <TableRow style={{ height: '8px' }} />
                <TableRowWrapper>
                    <WidthTableCell style={{ padding: 0 }} colSpan={2} $um={'px'}>
                        {view === 'GRID' ? renderAudienceGridView(audienceToEdit) : renderAudienceListView(audienceToEdit)}
                    </WidthTableCell>
                </TableRowWrapper>
            </>
        );
    };
    const splitAudienceKeys = (s: string) => {
        return (s.charAt(0).toUpperCase() + s.slice(1)).match(/[A-Z][a-z]+/g)?.join(' ');
    };

    const calculateAudienceValues = (audienceValue: any) => {
        if (typeof audienceValue !== 'object') return audienceValue;
        if (Array.isArray(audienceValue) && audienceValue.length) {
            return audienceValue.slice(0, 3).join(', ') + (audienceValue.length > 3 ? '...' : '');
        }
        if (['type', 'firstVersion', 'secondVersion'].some((r) => Object.keys(audienceValue).indexOf(r) >= 0)) {
            const type = audienceValue['type'];
            if (Object.keys(audienceValue).length > 2) {
                return `${audienceValue['firstVersion']} ${audienceVersionTypeSymbols[type]} ${audienceValue?.secondVersion}`;
            }
            return ` ${audienceVersionTypeSymbols[type]} ${audienceValue['firstVersion']} `;
        }
        return (Object.keys(audienceValue) as Array<keyof typeof audienceValue>).map((k, i) => {
            if (!audienceValue) return;
            return <span key={`${i}_value`}>{`${audienceValue[k]} `}</span>;
        });
    };

    const checkUnsaved = () => {
        if (creatingAudience) return true;
        if (!audienceToEdit) return false;

        const oldAud = audiences.find((audience) => audience._id === audienceToEdit?._id);
        return !_.isEqual(oldAud, audienceToEdit);
    };

    const renderAlertUnsavedChanges = (redirectTo?: string) => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSaveClick();
                redirectTo && navigate(redirectTo);
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
                redirectTo && navigate(redirectTo);
            },
            true
        );
    };
    const handleSaveClick = async () => {
        const newTemplate = {
            ...template
        };
        const audienceValues = [...(newTemplate.audienceValues || [])];
        if (creatingAudience) {
            newTemplate.audienceValues = [...audienceValues, audienceToEdit];
        } else {
            audienceValues.splice(
                audienceValues.findIndex((aud: any) => aud._id === audienceToEdit._id),
                1,
                audienceToEdit
            );
            newTemplate.audienceValues = audienceValues;
        }
        await updateTemplate(newTemplate);
        handleCancelClick();
    };

    const handleCancelClick = () => {
        setCreatingAudience(false);
        setIsEditing(false);
        setAudienceToEdit(undefined);
    };

    const renderAudienceListView = (audience?: Audience) => {
        if (!audience) return;
        const items: any = [];
        (Object.keys(audience) as Array<keyof typeof audience>).map((key, index) => {
            if (audienceFilterKeys.includes(key)) {
                const itemValue = calculateAudienceValues(audience[key]);
                const iconKey = ['deviceClass', 'deviceType', 'operatingSystem'].includes(key) && audience[key];

                items.push(
                    <AudienceListContainer key={`${index}_listview_item`}>
                        <ListItemContainer>
                            <ItemName key={key}>
                                <ItemIcon>
                                    <SVGInline
                                        src={!!iconKey ? audiencesIcons[key][iconKey as any] : audiencesIcons[key] || icons.valueIcon}
                                    />
                                </ItemIcon>
                                {_.truncate(itemValue, { length: 25 })}
                            </ItemName>
                            <ItemType>{splitAudienceKeys(key)}</ItemType>
                        </ListItemContainer>
                        <ObjectActions
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                setOpenHeaderValuesDialog(true);
                            }}
                            onRemove={() => {
                                if (audienceToEdit) {
                                    const newAud = { ...audienceToEdit };
                                    delete newAud[key];
                                    setAudienceToEdit(newAud);
                                }
                            }}
                            tooltipTexts={{
                                edit: 'target_groups_audiences_icon_value_edit',
                                delete: 'target_groups_audiences_icon_value_delete'
                            }}
                        />
                    </AudienceListContainer>
                );
            }
        });
        items.push(
            <>
                <AudienceListContainer
                    onClick={() => {
                        setOpenHeaderValuesDialog(true);
                    }}
                >
                    <ListAddNew>
                        <ItemName>
                            Add Values
                            {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'target_groups_audiences_icon_value_add')}
                        </ItemName>
                    </ListAddNew>
                </AudienceListContainer>
            </>
        );
        return <ListView>{items}</ListView>;
    };

    const renderAudienceGridView = (audience?: Audience) => {
        if (!audience) return;
        const hasBothMandatoryKeys: boolean = audience.hasOwnProperty('deviceClass') && audience.hasOwnProperty('deviceType');
        const items: any = [];
        (Object.keys(audience) as Array<keyof typeof audience>).map((key, index) => {
            if (audienceFilterKeys.includes(key) && audience[key]) {
                const showDeleteIcon: boolean = !['deviceClass', 'deviceType'].includes(key) || hasBothMandatoryKeys;
                const itemValue = calculateAudienceValues(audience[key]);
                const iconKey = ['deviceClass', 'deviceType', 'operatingSystem'].includes(key) && audience[key];
                items.push(
                    <ItemCard key={`${index}_gridview_item`}>
                        <ItemIcon>
                            <SVGInline src={!!iconKey ? audiencesIcons[key][iconKey as any] : audiencesIcons[key] || icons.valueIcon} />
                        </ItemIcon>
                        <div>
                            <ItemName>{_.truncate(itemValue, { length: 18 })}</ItemName>
                            <ItemType>{_.truncate(splitAudienceKeys(key), { length: 18 })}</ItemType>
                        </div>
                        <ItemActions>
                            <ActionIconHolder>
                                {renderTooltipWithKey(
                                    <SVGInline
                                        src={icons.editLightIcon}
                                        onClick={() => {
                                            setOpenHeaderValuesDialog(true);
                                        }}
                                    />,
                                    'target_groups_audiences_icon_value_edit'
                                )}
                            </ActionIconHolder>
                            <ActionIconHolder>
                                {showDeleteIcon &&
                                    renderTooltipWithKey(
                                        <SVGInline
                                            src={icons.trashLightIcon}
                                            onClick={() => {
                                                if (audienceToEdit) {
                                                    const newAud = { ...audienceToEdit };
                                                    delete newAud[key];
                                                    setAudienceToEdit(newAud);
                                                }
                                            }}
                                        />,
                                        'target_groups_audiences_icon_value_delete'
                                    )}
                            </ActionIconHolder>
                        </ItemActions>
                    </ItemCard>
                );
            }
        });
        items.push(
            <ItemCardAdd
                key={`addcard_item`}
                onClick={() => {
                    setOpenHeaderValuesDialog(true);
                }}
            >
                <ItemName>Add Values</ItemName>
                <ItemAddIcon>
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'target_groups_audiences_icon_value_add')}
                </ItemAddIcon>
            </ItemCardAdd>
        );
        return <GridView>{items}</GridView>;
    };

    const buildTableColumns = () =>
        Object.entries({
            name: 'Audience Name'
        }).map(([key, value]) => {
            return <HeaderTableCell key={`${key}_cell`} text={value} columnSize={{ name: { $um: 'px' } }} />;
        });

    const buildTableRows = () => {
        return (
            <>
                {audiences.map((audience: any, index) => {
                    const expanded = audienceToEdit?._id === audience._id;
                    const isLast = audiences.length - 1 === index;
                    return (
                        <>
                            <TableRowWrapper key={audience._id}>
                                {/*In case of editing, the input field is rendered*/}
                                {expanded && isEditing ? (
                                    <WidthTableCell colSpan={1} $um={'px'}>
                                        <AudienceTemplateWrapper>
                                            <AudienceTemplateName>
                                                <DialogTextField
                                                    label={'Audience Name'}
                                                    value={audienceToEdit?.name || ''}
                                                    onChange={(evt: any) => {
                                                        audienceToEdit && setAudienceToEdit({ ...audienceToEdit, name: evt.target.value });
                                                    }}
                                                    placeholder={'Audience Name'}
                                                />
                                            </AudienceTemplateName>
                                            <AudienceTemplateDescription>
                                                <DialogTextField
                                                    label={'Audience Description'}
                                                    placeholder={'Audience Description'}
                                                    value={audienceToEdit?.description || ''}
                                                    onChange={(evt: any) => {
                                                        audienceToEdit &&
                                                            setAudienceToEdit({ ...audienceToEdit, description: evt.target.value });
                                                    }}
                                                />
                                            </AudienceTemplateDescription>
                                        </AudienceTemplateWrapper>
                                    </WidthTableCell>
                                ) : (
                                    <>
                                        {/* AUDIENCE TITLE TABLE CELL */}
                                        <WidthTableCell $um={'px'}>
                                            <AudienceTitleContainer
                                                onClick={() => (showUnsaved ? renderAlertUnsavedChanges() : expandAudience(audience))}
                                            >
                                                {audience.name || EMPTY_WORD_STRING}
                                            </AudienceTitleContainer>
                                            {audience.description && (
                                                <AudienceDescriptionContainer>
                                                    {renderTooltip(
                                                        <TruncatedText>{audience.description}</TruncatedText>,
                                                        tooltipTypes.TEXT,
                                                        audience.description
                                                    )}
                                                </AudienceDescriptionContainer>
                                            )}
                                        </WidthTableCell>
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell $width={57} $um={'px'}>
                                    <ObjectActions
                                        actions={[tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandAudience(audience);
                                        }}
                                        open={expanded}
                                        onEdit={() => {
                                            if (
                                                showUnsaved &&
                                                (audience._id !== audienceToEdit?._id ||
                                                    (isEditing && audience._id === audienceToEdit?._id))
                                            )
                                                return renderAlertUnsavedChanges();
                                            setIsEditing(audience._id === audienceToEdit?._id ? !isEditing : true);
                                            audienceToEdit?._id !== audience._id && expandAudience(audience);
                                            setCreatingAudience(false);
                                        }}
                                        onRemove={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            handleDeleteClick(audience._id);
                                        }}
                                        tooltipTexts={{
                                            edit: 'target_groups_audiences_icon_edit',
                                            delete: 'target_groups_audiences_icon_delete',
                                            arrowClose: 'target_groups_audiences_icon_arrow_hide_values',
                                            arrowOpen: 'target_groups_audiences_icon_arrow_show_values'
                                        }}
                                    />
                                </WidthTableCell>
                            </TableRowWrapper>

                            {/* AUDIENCES TABLE ROW */}
                            {expanded && (
                                <>
                                    {/* SPACE BETWEEN ROWS */}
                                    <TableRow style={{ height: '8px' }} />
                                    <TableRowWrapper>
                                        <WidthTableCell style={{ padding: 0 }} colSpan={2} $um={'px'}>
                                            {view === 'GRID'
                                                ? renderAudienceGridView(audienceToEdit)
                                                : renderAudienceListView(audienceToEdit)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}
                {creatingAudience && renderCreatingAudience()}
            </>
        );
    };

    const titleJSX = () => (
        <GroupNameContainer>
            <span>{template?.values?.name}</span>
            {template && (
                <GroupStatusContainer>
                    {renderTooltipWithKey(
                        <SVGInline src={!!template?.released ? icons.groupActiveIcon : icons.groupInactiveIcon} />,
                        !!template?.released
                            ? 'superadmin_target_groups_templates_icon_released'
                            : 'superadmin_target_groups_templates_icon_not_released'
                    )}
                </GroupStatusContainer>
            )}
        </GroupNameContainer>
    );

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    loading={loading}
                    title={titleJSX()}
                    withoutSearch
                    withAddButton
                    withProfile
                    backIcon
                    addLabel={'Add Group Template Audience'}
                    onBack={() => navigate(-1)}
                    onAdd={() => (showUnsaved ? renderAlertUnsavedChanges() : handleAddClick())}
                />
                <FieldAndToggleContainer>
                    <GroupFieldsContainer>
                        <GroupField onClick={() => {}} $active={false}>
                            Experience
                        </GroupField>
                        <GroupField onClick={() => {}} $active={true}>
                            Audiences
                        </GroupField>
                    </GroupFieldsContainer>
                    <ToggleViewSwitch
                        checked={view === 'GRID'}
                        toggleCallback={() => setView(view === 'LIST' ? 'GRID' : 'LIST')}
                        tooltipTexts={{ list: 'target_groups_icon_switch_view_list', grid: 'target_groups_icon_switch_view_grid' }}
                    />
                </FieldAndToggleContainer>

                {loading && <Loader title={'Audiences'} />}
                {error && <BackendErrorDialog error={error} />}
                {(!!audiences.length || creatingAudience) && !loading && (
                    <GenericTable columns={buildTableColumns()} body={buildTableRows()} />
                )}

                {!loading && (
                    <PageActionsWrapper>
                        <PageActionButton
                            onClick={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleAddClick();
                            }}
                            label={'Add Group Template Audience'}
                            type={'BLUE'}
                        />
                    </PageActionsWrapper>
                )}

                <PageActions
                    onSave={() => {
                        handleSaveClick();
                    }}
                    onCancel={() => {
                        if (showUnsaved) return renderAlertUnsavedChanges(PageRoutes.SUPERADMIN_GROUPS);
                        handleCancelClick();
                        navigate(PageRoutes.SUPERADMIN_GROUPS);
                    }}
                    onRelease={template && !template.released ? () => releaseTemplate() : undefined}
                    onRetract={template && template.released ? () => retractTemplate() : undefined}
                    disabled={{ save: !showUnsaved, release: loading || showUnsaved, retract: loading || showUnsaved }}
                />
                <NewAudience
                    open={openHeaderValuesDialog}
                    audience={audienceToEdit}
                    onSave={(newAudience) => {
                        setAudienceToEdit(newAudience);
                    }}
                    onClose={() => setOpenHeaderValuesDialog(false)}
                    noValidation
                />
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};
