import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { contentSourceTypes, Item, itemTypes } from '../../../types/Item';
import _ from 'lodash';
import { getDataByService, getDynamicItems } from '../../../utils/fnPreviews';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import {
    PreviewCard,
    PreviewCardOverlay,
    PreviewContainer,
    PreviewLaneWrapper,
    PreviewSection,
    PreviewToggle
} from './DataPreviewDialog.css';
import { CardTypeIndicator } from '../VisualEditor.css';
import { Module } from '../../../types/Module';
import { UNSAFE_useEffectOnce } from '../../../hooks/useEffectOnce';
import { alphaHexToRGBA } from '../../../utils/fnColor';
import { setPreviewItems, unsetPreviewItems } from '../../../redux/slices/previewItemsSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { SUPERADMIN_PREVIEW_PROJECT_ID_PARAM } from '../../../utils/Globals';
import usePrevious from '../../../hooks/usePrevious';

export enum DialogPreviewTypes {
    SOURCE = 'SOURCE',
    ITEM = 'ITEM',
    MODULE = 'MODULE'
}
type DataPreviewProps = {
    previewType: DialogPreviewTypes;
    object?: any;
    service?: string;
    url?: string;
    isPreviewOpen: boolean;
    togglePreview: () => void;
    sourceId?: string;
    isPortrait?: boolean;
    isSuperadminUI?: boolean; // used for sending a special value for projectId if the preview happens in the superadmin UI where there is no project
    previewIsLocked?: boolean; // used if preview is locked regardless of the service
};

type ItemPreviewProps = {
    item: Item;
    fullWidth?: boolean;
    expanded?: boolean;
    isSuperadminUI?: boolean;
    isPortrait?: boolean;
};

const ItemPreview: FC<ItemPreviewProps> = ({ item, fullWidth, expanded, isSuperadminUI, isPortrait }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [itemImages, setItemImages] = useState<string[]>([]);
    const previousItemId = usePrevious(item._id);
    const { singleAsset, maxNumberOfItems, itemType } = item;

    const calculateSelectorBackground = (item: Item) => {
        if (item?.backgroundColor) {
            const { r, g, b, a } = alphaHexToRGBA(item.backgroundColor);
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        }
        if (item?.backgroundImage) {
            const url = Object.values(item.backgroundImage).find((val) => !!val);
            if (!url) return '';
            return `${decodeURIComponent(url)}`;
        }
        return '';
    };
    const loadDynamicImage = useCallback(
        _.debounce(async (item: Item) => {
            const dItem = await getDynamicItems(item, false, isSuperadminUI);
            // if expanded set in state maximumNrOfItems(if set) otherwise show a maximum of 10 images of the assets,
            // else set in state only the first one since the rest is not used
            setItemImages(
                !Array.isArray(dItem) ? [dItem.image] : dItem.slice(0, expanded ? item.maxNumberOfItems || 10 : 1).map((item) => item.image)
            );
            setLoading(false);
        }, 1000),
        []
    );

    UNSAFE_useEffectOnce(() => {
        if (!item || loading) return;
        if (item.itemType === itemTypes.EDITORIAL) {
            return setItemImages([calculateSelectorBackground(item)]);
        }
        if (previousItemId != item._id) {
            loadDynamicImage(item);
            setLoading(true);
        }
    }, [item, loading]);

    const noOfItems = singleAsset ? '1' : maxNumberOfItems || '∞';
    const renderExpandedDynamicPreview = () => {
        if (itemType !== itemTypes.DYNAMIC) return <></>;
        return (
            <PreviewLaneWrapper $fullWidth={fullWidth}>
                {loading ? (
                    <PreviewCard $isPortrait={isPortrait} $singleAsset={fullWidth} key={`loader-${item._id}`} $fullWidth={fullWidth}>
                        <PreviewCardOverlay>
                            <SVGInline src={icons.spinnerIcon} />
                        </PreviewCardOverlay>
                    </PreviewCard>
                ) : (
                    itemImages.map((itemImage) => (
                        <PreviewCard
                            $singleAsset={fullWidth && itemImages.length === 1}
                            key={itemImage}
                            $fullWidth={fullWidth}
                            $image={itemImage}
                        />
                    ))
                )}
            </PreviewLaneWrapper>
        );
    };
    const renderCollapsedPreview = () => {
        return (
            <PreviewCard $isPortrait={isPortrait} $singleAsset={fullWidth} $fullWidth={fullWidth} $image={itemImages[0]}>
                {loading ? (
                    <PreviewCardOverlay>
                        <SVGInline src={icons.spinnerIcon} />
                    </PreviewCardOverlay>
                ) : (
                    <CardTypeIndicator>
                        <SVGInline src={itemType === itemTypes.EDITORIAL ? icons.editorialIcon : icons.dynamicIcon} />
                        {itemType === itemTypes.DYNAMIC && <div>{noOfItems}</div>}
                    </CardTypeIndicator>
                )}
            </PreviewCard>
        );
    };
    return expanded && itemType === itemTypes.DYNAMIC ? renderExpandedDynamicPreview() : renderCollapsedPreview();
};

export const DataPreviewDialog: FC<DataPreviewProps> = ({
    previewType,
    object,
    service,
    url,
    isPreviewOpen,
    togglePreview,
    sourceId,
    isPortrait,
    isSuperadminUI,
    previewIsLocked
}) => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const mockData = [...Array(isPortrait ? 6 : 4).keys()];
    const [sourceData, setSourceData] = useState<any[]>(mockData);
    const [loading, setLoading] = useState(false);
    const [isPreviewLocked, setIsPreviewLocked] = useState<boolean>(!!previewIsLocked);
    const [items, setItems] = useState<Item[]>([]);

    const noOfItems = service === contentSourceTypes.PRIME_VIDEO ? 50 : 10;

    const dispatch = useDispatch();

    //used for new source preview
    const loadSourcePreview = useCallback(
        _.debounce(async (service: string, url: string, sourceId?: string) => {
            const items = await getDataByService(
                service,
                url,
                noOfItems,
                undefined,
                sourceId,
                isSuperadminUI ? SUPERADMIN_PREVIEW_PROJECT_ID_PARAM : activeProjectId
            );

            if (Array.isArray(items) && items.length) {
                setSourceData(items);
                dispatch(setPreviewItems(items));
            }
            setLoading(false);
        }, 1000),
        []
    );

    useEffect(() => {
        if (!object) return;
        setItems(previewType === DialogPreviewTypes.ITEM ? [object as Item] : (object as Module).items || []);
    }, [object]);

    useEffect(() => {
        if (!service || !isPreviewOpen) return;

        setSourceData(mockData);
        if (
            [
                contentSourceTypes.ALLENTE_GATEWAY,
                contentSourceTypes.ALLENTE_GATEWAY_STAGING,
                contentSourceTypes.ALLENTE_LOCAL_STB,
                contentSourceTypes.KALTURA,
                contentSourceTypes.KALTURA_STAGING,
                contentSourceTypes.RECORDINGS
            ].includes(service as contentSourceTypes) ||
            previewIsLocked
        ) {
            return setIsPreviewLocked(true);
        }
        setIsPreviewLocked(false);
        if (url) {
            setLoading(true);
            dispatch(unsetPreviewItems());
            loadSourcePreview(service, url, sourceId);
        }
    }, [service, url, sourceId, isPreviewOpen]);

    const renderItemPreview = () => {
        return !items.length ? (
            <PreviewCard $isPortrait={isPortrait} $fullWidth $singleAsset />
        ) : (
            <ItemPreview isPortrait={isPortrait} item={items[0]} fullWidth expanded isSuperadminUI={isSuperadminUI} />
        );
    };

    const renderModulePreview = () => {
        return (
            <PreviewContainer>
                <PreviewLaneWrapper>
                    {!items.length && <PreviewCard $isPortrait={isPortrait} />}
                    {items.map((item) => (
                        <ItemPreview
                            item={item}
                            isPortrait={isPortrait}
                            key={`item_${item._id}`}
                            expanded={items.length === 1}
                            isSuperadminUI={isSuperadminUI}
                        />
                    ))}
                </PreviewLaneWrapper>
            </PreviewContainer>
        );
    };

    const renderSourcePreview = () => {
        return (
            <PreviewContainer>
                <PreviewLaneWrapper>
                    {sourceData.slice(0, noOfItems).map((item, index) => {
                        const { image } = item;
                        return (
                            <PreviewCard $isPortrait={isPortrait} $image={image} key={`Preview_${index}`}>
                                {loading && (
                                    <PreviewCardOverlay>
                                        <SVGInline src={icons.spinnerIcon} />
                                    </PreviewCardOverlay>
                                )}
                                {isPreviewLocked && (
                                    <PreviewCardOverlay>
                                        <SVGInline src={icons.lockedIcon} />
                                    </PreviewCardOverlay>
                                )}
                            </PreviewCard>
                        );
                    })}
                </PreviewLaneWrapper>
            </PreviewContainer>
        );
    };

    const renderPreview = () => {
        const previewMethods: { [key in DialogPreviewTypes]: () => void } = {
            [DialogPreviewTypes.ITEM]: renderItemPreview,
            [DialogPreviewTypes.MODULE]: renderModulePreview,
            [DialogPreviewTypes.SOURCE]: renderSourcePreview
        };

        return previewMethods[previewType]();
    };

    if (!object && !service) {
        return null;
    }

    return (
        <PreviewSection>
            <PreviewToggle onClick={() => togglePreview()}>
                Data Preview <SVGInline src={isPreviewOpen ? icons.arrowUpIcon : icons.arrowDownIcon} />{' '}
                {isPreviewLocked && renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_icon_preview')}
                {service === contentSourceTypes.PRIME_VIDEO &&
                    renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_icon_preview_amazon')}
            </PreviewToggle>
            {isPreviewOpen && renderPreview()}
        </PreviewSection>
    );
};
