import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import { isAutoCollectionSupported, templates } from '../../../../types/Module';
import { Template, TemplateDefaultValues, templateType, templateTypes } from '../../../../types/Template';
import { useAppSelector } from '../../../../hooks/redux';
import { ModulesState } from '../../../../redux/slices/moduleSlice';
import icons, { TemplateIcons } from '../../../../assets/images/icons';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import { allowedTemplatesForCollection, moduleTypes } from '../../../../utils/Globals';
import {
    DialogCheckbox,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTextField,
    DialogToggleButton
} from '../../../common/Dialog/GenericDialog';
import {
    ToggleExtraFields,
    CheckboxListContainer,
    CheckboxWrapper,
    ModuleTypeWrapper,
    OptionSection,
    OptionsToggle,
    TemplateIconWrapper,
    TemplateOption
} from '../../../Modules/Dialogs/NewModule.css';
import { validator } from '../../../../utils/fnValidator';
import { generateUID } from '../../../../utils/fnGenerator';
import { dialogAlert, ToastAlert } from '../../../../utils/fnDialogs';
import { itemTypes } from '../../../../types/Item';
import { NewTemplate } from '../Common/NewTemplate';
import { TEMPLATES_WITHOUT_PREVIEW, TEMPLATES_WITHOUT_TOP_TEN } from '../../../Modules/Dialogs/NewModule';

type NewModuleTemplateProps = {
    open: boolean;
    module?: Template;
    onClose: () => void;
    onSave: (arg: Template) => void;
    onRelease: (type?: templateType) => void;
    onRetract: (type?: templateType) => void;
    restrictedTemplates?: templates[];
    restrictedCollection?: boolean;
    restrictedContentWorld?: boolean;
};

export const NewModule: FC<NewModuleTemplateProps> = ({
    open,
    module,
    onSave,
    onClose,
    onRelease,
    onRetract,
    restrictedTemplates,
    restrictedCollection,
    restrictedContentWorld
}) => {
    const { validTemplates }: ModulesState = useAppSelector((state) => state.modules);

    const [includeProviderLogoCard, setIncludeProviderLogoCard] = useState<boolean>(module?.values.includeProviderLogoCard || false);
    const [viewAllCardPlacement, setViewAllCardPlacement] = useState<string>(module?.values.viewAllCardPlacement || 'end');
    const [moduleType, setModuleType] = useState<string>(module?.values.moduleType || moduleTypes.STRIPE);
    const [autoCollection, setAutoCollection] = useState<boolean>(module?.values.autoCollection || false);
    const [showAutoScroll, setShowAutoScroll] = useState<boolean>(!!module?.values.autoScroll || true);
    const [autoScroll, setAutoScroll] = useState<string>(module?.values.autoScroll ? `${module.values.autoScroll / 1000}` : '10');

    const [template, setTemplate] = useState<templates | undefined>(module?.values.template);
    const [pinnable, setPinnable] = useState<boolean>(module?.values.pinnable || false);
    const [hideContentMarker, setHideContentMarker] = useState<boolean>(module?.values.hideContentMarker || false);
    const [invisible, setInvisible] = useState<boolean>(module?.values.invisible || false);
    const [topTen, setTopTen] = useState<boolean>(module?.values.topTen || false);
    const [preview, setPreview] = useState<boolean>(module?.values.preview || false);
    const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);
    const [name, setName] = useState<string>(module?.values.name || '');
    const [contentWorld, setContentWorld] = useState<boolean>(module?.values.contentWorld || false);
    const [templateOptions, setTemplateOptions] = useState<any[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});

    // contentWorld should be enabled only for modules with editorial items
    const disableContentWorld =
        module?.itemTemplates?.some((item) => item.values?.itemType === itemTypes.DYNAMIC) || restrictedContentWorld;

    useEffect(() => {
        let options =
            validTemplates?.map((item, index) => ({
                value: item.value,
                valueForSearch: item.title,
                label: (
                    <TemplateOption key={index}>
                        <TemplateIconWrapper>
                            <SVGInline src={TemplateIcons[item.key]} />
                        </TemplateIconWrapper>
                        <span>{item.title}</span>
                    </TemplateOption>
                )
            })) || [];

        if (moduleType === moduleTypes.COLLECTION) {
            options = options.filter((opt) => allowedTemplatesForCollection.includes(opt.value));
            if (!options.find((opt) => opt.value === template)) setTemplate(undefined);
        }

        if (restrictedTemplates?.length) {
            options = options.filter((opt) => !restrictedTemplates.includes(opt.value));
        }

        if (topTen) {
            options = options.filter((opt) => !TEMPLATES_WITHOUT_TOP_TEN.includes(opt.value));
        }

        if (preview) {
            options = options.filter((opt) => !TEMPLATES_WITHOUT_PREVIEW.includes(opt.value));
        }

        setTemplateOptions(options);
    }, [validTemplates, moduleType, restrictedTemplates, topTen, preview]);

    useEffect(() => {
        if (!module) return;
        setOptionsExpanded(
            module.values.includeProviderLogoCard ||
                module.values.pinnable ||
                module.values.autoCollection ||
                module.values.template === templates.GALLERY ||
                module.values.invisible ||
                module.values.hideContentMarker ||
                module.values.contentWorld ||
                module.values.topTen ||
                module.values.preview
        );

        setAutoScroll(module.values?.autoScroll ? `${module.values.autoScroll / 1000}` : '10');
        setIncludeProviderLogoCard(module.values?.includeProviderLogoCard || false);
        setViewAllCardPlacement(module.values?.viewAllCardPlacement || 'end');
        setModuleType(module.values?.moduleType || moduleTypes.STRIPE);
        setAutoCollection(module.values?.autoCollection || false);
        setShowAutoScroll(!!module.values?.autoScroll || false);
        setTemplate(module.values?.template);
        setPinnable(module.values?.pinnable || false);
        setTopTen(module.values?.topTen || false);
        setPreview(module.values?.preview || false);
        setContentWorld(module.values.contentWorld || false);
        setInvisible(module.values?.invisible || false);
        setHideContentMarker(module.values?.hideContentMarker || false);
        setName(module.values?.name || '');
    }, [module]);

    // if autoCollection is unchecked, reset ViewAllCardPlacement to end
    useEffect(() => {
        !autoCollection && setViewAllCardPlacement('end');
    }, [autoCollection]);

    // if autoScroll is unchecked reset autoscrollValue
    useEffect(() => {
        !showAutoScroll && setAutoScroll('10');
    }, [showAutoScroll]);

    useEffect(() => {
        if (!isAutoCollectionSupported(template, preview)) {
            setAutoCollection(false);
        }
    }, [template, preview]);

    const validateModule = (values: TemplateDefaultValues) => {
        const { icon } = values;
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 3 }, name);
        newErrors.icon = validator({ required: true, minLength: 3 }, icon);
        newErrors.template = validator({ required: true, minLength: 3 }, template || '');

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const handleClose = () => {
        setAutoCollection(false);
        setShowAutoScroll(true);
        setAutoScroll('10');
        setViewAllCardPlacement('end');
        setModuleType(moduleTypes.STRIPE);
        setTemplate(undefined);
        setPinnable(false);
        setPreview(false);
        setTopTen(false);
        setInvisible(false);
        setHideContentMarker(false);
        setContentWorld(false);
        setOptionsExpanded(false);
        setIncludeProviderLogoCard(false);
        setName('');
        setErrors({});
        onClose();
    };

    const notSupportedTemplateAlertValues = {
        title: 'Warning',
        subtitle: `Auto-Collection is not supported for ${template} template.`,
        text: 'Auto-Collection will be disabled.'
    };

    const renderNotSupportedTemplateDialog = () => {
        return dialogAlert('', false, notSupportedTemplateAlertValues, undefined, false, icons.warningYellowIcon);
    };

    const shouldShowAutocollectionWarning = !isAutoCollectionSupported(template, preview) && autoCollection && !!module;

    const onSaveDialog = (values: TemplateDefaultValues) => {
        const { icon, iconBackground, tenantIds, projectIds } = values;
        if (!validateModule(values)) return;

        if (shouldShowAutocollectionWarning) {
            ToastAlert(
                'warning',
                notSupportedTemplateAlertValues.title,
                notSupportedTemplateAlertValues.subtitle,
                undefined,
                undefined,
                () => {
                    renderNotSupportedTemplateDialog();
                }
            );
        }

        const newModule: Template = {
            icon,
            iconBackground,
            type: templateTypes.MODULE,
            _id: module?._id || generateUID(),
            lastModified: module?.lastModified,
            audienceValues: module?.audienceValues || [],
            tenantIds,
            projectIds,
            values: {
                ...module?.values,
                name,
                template,
                pinnable,
                topTen,
                preview,
                invisible,
                hideContentMarker,
                moduleType,
                viewAllCardPlacement,
                includeProviderLogoCard,
                autoCollection: isAutoCollectionSupported(template, preview) ? autoCollection : false,
                contentWorld
            }
        };

        if (showAutoScroll) newModule.values.autoScroll = parseFloat(autoScroll) * 1000;

        if (module?.values.autoScroll && !showAutoScroll) {
            newModule.values.autoScroll = 0;
        }

        if (template && TEMPLATES_WITHOUT_PREVIEW.includes(template)) {
            newModule.values.preview = false;
        }

        if (template && TEMPLATES_WITHOUT_TOP_TEN.includes(template)) {
            newModule.values.topTen = false;
        }

        if (moduleType === moduleTypes.COLLECTION) {
            newModule.values.preview = false;
            newModule.values.topTen = false;
            newModule.values.autoCollection = false;
            newModule.values.contentWorld = false;
        }

        onSave(newModule);
    };

    const handleChangeAutoScrollValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const floatingNumberValue = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        setAutoScroll(floatingNumberValue);
        setErrors(_.omit(errors, 'autoscroll'));
    };
    const hasEditorialItem = module?.itemTemplates?.some((item) => item.values?.itemType === itemTypes.EDITORIAL);
    const disableAutoCollection =
        (module?.itemTemplates && module.itemTemplates.length > 1) || (hasEditorialItem && module?.itemTemplates?.length === 1);

    // if there's no module(means we are creating), or there's no item inside the module,
    // then we allow the Provider logo checkbox otherwise check the items
    const isEveryItemSameProvider =
        !module ||
        !module.itemTemplates?.length ||
        module.itemTemplates
            ?.filter((item) => item.values?.itemType === itemTypes.DYNAMIC)
            .every((item, _, items) => item.values?.contentSourceType === items[0].values?.contentSourceType);

    const renderContentWorldTooltip = (): string => {
        switch (true) {
            case restrictedContentWorld:
                return 'module_template_content_world_disabled';
            case disableContentWorld:
                return 'module_content_world_disabled_dynamic_list';
            case includeProviderLogoCard:
                return 'module_content_world_disabled_provider_logo';
            case topTen:
                return 'module_content_world_disabled_topTen';
            case autoCollection:
                return 'module_content_world_disabled_auto_collection';
            default:
                return 'module_content_world';
        }
    };

    const renderAutoCollectionTooltip = (): string => {
        switch (true) {
            case disableAutoCollection:
                return 'modules_auto_collection_disabled';
            case contentWorld:
                return 'modules_auto_collection_disabled_contentWorld';
            case topTen:
                return 'modules_auto_collection_disabled_topTen';
            default:
                return 'modules_auto_collection_info';
        }
    };

    const renderOptions = () => {
        return (
            <>
                <CheckboxListContainer>
                    {/* PINNABLE */}
                    <CheckboxWrapper>
                        <DialogCheckbox
                            onClick={() => {
                                setPinnable((prevState) => !prevState);
                            }}
                            value={pinnable}
                            text={'Pinnable'}
                            active={pinnable}
                            disabled={invisible}
                        />
                        {renderTooltipWithKey(
                            <SVGInline src={icons.infoIcon} />,
                            invisible ? 'modules_is_pinnable_info_disabled' : 'modules_is_pinnable_info'
                        )}
                    </CheckboxWrapper>

                    {/* CONTENT WORLD*/}
                    {moduleType === moduleTypes.STRIPE && (
                        <CheckboxWrapper>
                            <DialogCheckbox
                                onClick={() => {
                                    setContentWorld(!contentWorld);
                                }}
                                value={contentWorld}
                                text={'Content World'}
                                active={contentWorld}
                                disabled={disableContentWorld || includeProviderLogoCard || topTen || autoCollection}
                            />
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, renderContentWorldTooltip())}
                        </CheckboxWrapper>
                    )}

                    {/* PROVIDER LOGO CARD */}

                    <CheckboxWrapper>
                        <DialogCheckbox
                            onClick={() => {
                                setIncludeProviderLogoCard((prevState) => !prevState);
                            }}
                            value={includeProviderLogoCard}
                            text={'Provider Logo Card'}
                            active={includeProviderLogoCard}
                            disabled={!isEveryItemSameProvider || contentWorld}
                        />
                        {renderTooltipWithKey(
                            <SVGInline src={icons.infoIcon} />,
                            contentWorld
                                ? 'modules_include_provider_card_disabled_contentWorld'
                                : isEveryItemSameProvider
                                ? 'modules_include_provider_card_info'
                                : 'modules_include_provider_card_disabled_same_provider'
                        )}
                    </CheckboxWrapper>

                    {/* PREVIEW */}
                    {moduleType === moduleTypes.STRIPE && (!template || (template && !TEMPLATES_WITHOUT_PREVIEW.includes(template))) && (
                        <CheckboxWrapper>
                            <DialogCheckbox
                                onClick={() => {
                                    setPreview(!preview);
                                }}
                                value={preview}
                                text={'Preview'}
                                active={preview}
                            />
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'modules_preview_info')}
                        </CheckboxWrapper>
                    )}

                    {/* INVISIBLE */}
                    <CheckboxWrapper>
                        <DialogCheckbox
                            onClick={() => {
                                setInvisible(!invisible);
                            }}
                            value={invisible}
                            text={'Hide Module on Page'}
                            active={invisible}
                            disabled={pinnable}
                        />
                        {renderTooltipWithKey(
                            <SVGInline src={icons.infoIcon} />,
                            pinnable ? 'modules_invisible_info_disabled' : 'modules_invisible_info'
                        )}
                    </CheckboxWrapper>

                    {/* TOP TEN */}
                    {moduleType === moduleTypes.STRIPE && (!template || (template && !TEMPLATES_WITHOUT_TOP_TEN.includes(template))) && (
                        <CheckboxWrapper>
                            <DialogCheckbox
                                onClick={() => {
                                    setTopTen(!topTen);
                                }}
                                value={topTen}
                                text={'Top X'}
                                active={topTen}
                                disabled={contentWorld || autoCollection}
                            />
                            {renderTooltipWithKey(
                                <SVGInline src={icons.infoIcon} />,
                                contentWorld
                                    ? 'modules_top_ten_info_disabled_contentWorld'
                                    : autoCollection
                                    ? 'modules_top_ten_info_disabled_auto_collection'
                                    : 'modules_top_ten_info'
                            )}
                        </CheckboxWrapper>
                    )}

                    {/* HIDE CONTENT MARKER */}
                    <CheckboxWrapper>
                        <DialogCheckbox
                            onClick={() => {
                                setHideContentMarker(!hideContentMarker);
                            }}
                            value={hideContentMarker}
                            text={'Hide Content Marker'}
                            active={hideContentMarker}
                        />
                        {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'module_hide_content_marker_info')}
                    </CheckboxWrapper>
                </CheckboxListContainer>

                {/* INCLUDE AUTO-COLLECTION */}
                {moduleType === moduleTypes.STRIPE && isAutoCollectionSupported(template, preview) && (
                    <>
                        <DialogToggleButton
                            text={'Include Auto-Collection'}
                            checked={autoCollection}
                            toggleCallback={() => setAutoCollection((prevState) => !prevState)}
                            disabled={disableAutoCollection || contentWorld || topTen}
                            tooltipText={renderAutoCollectionTooltip()}
                        />

                        {autoCollection && (
                            <ToggleExtraFields>
                                <DialogRadioButton
                                    active={viewAllCardPlacement === 'end'}
                                    onClick={() => setViewAllCardPlacement('end')}
                                    text={
                                        <>
                                            View all card is placed on the <b>End</b>
                                        </>
                                    }
                                />
                                <DialogRadioButton
                                    active={viewAllCardPlacement === 'beginning'}
                                    onClick={() => setViewAllCardPlacement('beginning')}
                                    text={
                                        <>
                                            View all card is placed on the <b>Beginning</b>
                                        </>
                                    }
                                />
                            </ToggleExtraFields>
                        )}
                    </>
                )}

                {/* AUTO SCROLL */}
                {template === templates.GALLERY && (
                    <>
                        <DialogToggleButton
                            text={'Auto Scroll'}
                            checked={showAutoScroll}
                            toggleCallback={() => setShowAutoScroll((prevState) => !prevState)}
                        />
                        {showAutoScroll && (
                            <ToggleExtraFields>
                                <DialogTextField
                                    value={autoScroll}
                                    error={errors.autoScroll}
                                    label={'Select Time (sec)'}
                                    InputProps={{ endAdornment: <span>(sec)</span> }}
                                    placeholder={'Enter auto scroll value in seconds'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeAutoScrollValue(e)}
                                />
                            </ToggleExtraFields>
                        )}
                    </>
                )}
            </>
        );
    };
    const extraValues = {
        pinnable,
        invisible,
        hideContentMarker,
        template,
        moduleType,
        autoCollection,
        autoScroll,
        viewAllCardPlacement,
        includeProviderLogoCard,
        contentWorld,
        preview,
        topTen
    };

    const children = (
        <>
            {/* MODULE TYPE */}
            <ModuleTypeWrapper>
                <DialogRadioButton
                    disabled={!!module}
                    text={_.startCase(moduleTypes.STRIPE)}
                    active={moduleType === moduleTypes.STRIPE}
                    onClick={() => setModuleType(moduleTypes.STRIPE)}
                />
                <DialogRadioButton
                    disabled={!!module || restrictedCollection}
                    text={_.startCase(moduleTypes.COLLECTION)}
                    active={moduleType === moduleTypes.COLLECTION}
                    onClick={() => {
                        setModuleType(moduleTypes.COLLECTION);
                        setAutoCollection(false);
                        setTopTen(false);
                        setPreview(false);
                        setContentWorld(false);
                    }}
                />
                {!!module && renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'modules_type_disabled')}
                {!module &&
                    restrictedCollection &&
                    renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'collection_module_type_disabled')}
            </ModuleTypeWrapper>

            {/* MODULE TEMPLATE */}
            <DialogDropdownSingle
                error={errors.template}
                options={templateOptions}
                labelText={'UI Template'}
                placeholder={'UI Template'}
                value={templateOptions.find((option) => option.value === template) || null}
                onChange={(value: any) => {
                    setTemplate(value.value);
                    setErrors(_.omit(errors, 'template'));
                    !optionsExpanded && value.value === templates.GALLERY && setOptionsExpanded(true);
                }}
                withTopMargin
            />

            {/* MODULE TITLE */}
            <DialogTextField
                value={name}
                error={errors.name}
                label={'Add Template Title'}
                placeholder={'Add Template Title'}
                onChange={(e: any) => {
                    setName(e.target.value);
                    setErrors(_.omit(errors, 'name'));
                }}
                withTopMargin
            />

            {/* MODULE OPTIONS */}
            <OptionSection $notLastElement>
                <OptionsToggle onClick={() => setOptionsExpanded(!optionsExpanded)}>
                    Options <SVGInline src={optionsExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </OptionsToggle>

                {optionsExpanded && renderOptions()}
            </OptionSection>
        </>
    );

    if (!open) return null;

    return (
        <NewTemplate
            open={open}
            template={module}
            templateType={templateTypes.MODULE}
            onRelease={onRelease}
            onClose={handleClose}
            onSave={onSaveDialog}
            onRetract={onRetract}
            errors={errors}
            setErrors={setErrors}
            warnings={
                shouldShowAutocollectionWarning ? [{ icon: icons.infoIcon, onClick: () => renderNotSupportedTemplateDialog() }] : undefined
            }
            extraValues={extraValues}
        >
            {children}
        </NewTemplate>
    );
};

export default NewModule;
