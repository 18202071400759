import { FC, useEffect, useState } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../../style/styled-components/reusable.css';
import Sidebar from '../Sidebar/Sidebar';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import {
    FeatureColumnWrapper,
    FeatureContainer,
    FeatureSections,
    FeatureSubSection,
    FeatureSubTitle,
    FeatureTitle,
    FeatureTitleRow,
    ProjectsDropdownWrapper
} from './FeatureConfig.css';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { ModulesState, fetchModulesTemplates } from '../../../redux/slices/moduleSlice';
import { DialogCheckbox, DialogDropdownSingle } from '../../common/Dialog/GenericDialog';
import { SourcesState, fetchServices } from '../../../redux/slices/sourceSlice';
import { useParams } from 'react-router-dom';
import { ProjectsState, fetchProjects, updateProject as editProject } from '../../../redux/slices/projectsSlice';
import _ from 'lodash';
import { Project, RestrictedSection, restrictedSectionsTypes } from '../../../types/Project';
import PageActions from '../../common/PageActions/PageActions';
import { Loader } from '../../common/Loader/Loader';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { fetchMenuTypes, menusState } from '../../../redux/slices/menusSlice';
import { MenuTypes } from '../../../types/Menu';
import useScreenSize from '../../../hooks/useScreenSize';
import { Capabilities } from '../../Capabilities/CapabilitiesTopScreen';
import icons from '../../../style';
import { ToastAlert, dialogAlert } from '../../../utils/fnDialogs';

const FEATURE_TITLE_MAPPING = {
    [restrictedSectionsTypes.TEMPLATES]: 'Templates',
    [restrictedSectionsTypes.SERVICES]: 'Services',
    [restrictedSectionsTypes.MISCELLANEOUS]: 'Miscellaneous',
    [restrictedSectionsTypes.CAPABILITIES]: 'Capabilities'
};

const UncheckableFeatureValues = [MenuTypes.MAIN];
export const FeatureConfig: FC = () => {
    const { validTemplates, error: templatesError }: ModulesState = useAppSelector((state) => state.modules);
    const { services, error: servicesError }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { menuTypes, error: menuTypesError }: menusState = useAppSelector((state) => state.menus);
    const { projects, error: projectsError, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const [selectedProject, setSelectedProject] = useState<string>('');
    const [projectsOptions, setProjectsOptions] = useState<any[]>([]);
    const [initialRestrictedSections, setInitialRestrictedSections] = useState<RestrictedSection>({});
    const [restrictedSections, setRestrictedSections] = useState<RestrictedSection>({});
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);

    const { isMobile } = useScreenSize();
    const numOfColumns = isMobile ? 2 : 3;
    const { tenant_id: tenantId } = useParams();
    const dispatch = useDispatch();
    const loadTemplates = async () => {
        await dispatch(fetchModulesTemplates()).unwrap();
    };

    const loadMenuTypes = async () => {
        return await dispatch(fetchMenuTypes()).unwrap();
    };

    const loadServices = async () => {
        await dispatch(fetchServices({})).unwrap();
    };

    const loadProjects = async () => {
        await dispatch(fetchProjects({})).unwrap();
    };

    const updateProject = async (project: Project) => {
        await dispatch(editProject(project)).unwrap();
        await loadProjects();
    };

    const renderDisabledAggregatServicesDialog = () => {
        const values = {
            title: 'Aggregate disabled',
            text: 'Please ensure that all of the services listed in the Aggregate section are disabled, otherwise they will be still visible in sources section'
        };
        ToastAlert('warning', values.title, values.text, undefined, undefined, () => {
            dialogAlert('', false, values, undefined, undefined, icons.warningYellowIcon);
        });
    };

    useEffect(() => {
        if (!selectedProject) return;
        loadTemplates();
        loadServices();
        loadMenuTypes();
    }, [selectedProject]);

    useEffect(() => {
        loadProjects();
    }, [tenantId]);

    useEffect(() => {
        if (!projects) return;

        const projectsPerTenant = projects?.filter((project) => {
            return project.tenantId?.includes(`${tenantId}`);
        });
        const options = projectsPerTenant.map((option: any) => {
            return {
                label: option.name,
                value: option._id
            };
        });

        setProjectsOptions(options);
    }, [projects]);

    useEffect(() => {
        if (projectsLoading) {
            setInitialRestrictedSections({});
            setRestrictedSections({});
        }
    }, [projectsLoading]);

    useEffect(() => {
        if (!selectedProject || !projectsOptions.some((opt) => opt.value === selectedProject)) {
            setSelectedProject(projectsOptions[0]?.value || '');
        }
    }, [projectsOptions]);

    useEffect(() => {
        if (!selectedProject) return;
        const project = projects.find((proj) => proj._id === selectedProject);
        setInitialRestrictedSections(project?.restrictedSections || {});
        setRestrictedSections(project?.restrictedSections || {});
    }, [projects, selectedProject]);

    useEffect(() => {
        setShowUnsaved(checkShowUnsaved());
    }, [initialRestrictedSections, restrictedSections]);

    const onSave = () => {
        const oldProject = projects.find((proj) => proj._id === selectedProject || '');

        if (!oldProject) return;

        const newProject: Project = {
            ...oldProject,
            restrictedSections: _.isEmpty(restrictedSections)
                ? undefined
                : _.omitBy(restrictedSections, (value: string[]) => value.length === 0)
        };
        if (newProject.logo) {
            newProject.logo = encodeURIComponent(newProject.logo);
        }
        updateProject(newProject);
    };

    const onCancel = () => {
        setRestrictedSections(initialRestrictedSections);
    };

    const checkShowUnsaved = () => {
        return JSON.stringify(initialRestrictedSections) !== JSON.stringify(restrictedSections);
    };

    const onSelect = (feature: restrictedSectionsTypes, key: string, isSelected: boolean) => {
        let oldRestrictedSections = { ...restrictedSections };
        const oldValues = [...(oldRestrictedSections?.[feature] || [])];

        if (isSelected) {
            oldRestrictedSections = { ...oldRestrictedSections, [feature]: [...oldValues, key] };
        } else {
            const index = oldValues.indexOf(key);
            if (index !== -1) {
                oldValues?.splice(index, 1);
                oldRestrictedSections = { ...oldRestrictedSections, [feature]: oldValues };
            }
        }
        setRestrictedSections(oldRestrictedSections);
    };

    const renderFeatureSections = (feature: restrictedSectionsTypes) => {
        let array: any[] = [];
        switch (feature) {
            case restrictedSectionsTypes.TEMPLATES:
                array = validTemplates || [];
                break;
            case restrictedSectionsTypes.SERVICES:
                array = services;
                break;
            case restrictedSectionsTypes.MISCELLANEOUS:
                array = [
                    { subtitle: 'Menu Types', values: menuTypes },
                    { subtitle: 'Encoding', values: [{ key: 'bitmovin_encoding', title: 'Bitmovin encoding' }] }
                ];
                break;
            case restrictedSectionsTypes.CAPABILITIES:
                array = Object.values(Capabilities).map((key) => ({ key, title: _.capitalize(key) }));
                break;
            default:
                array = [];
                break;
        }

        const getColumns = (values: any[]) => {
            const numOfRows = Math.ceil(values.length / numOfColumns);
            const elements = Array.from({ length: numOfColumns }).map((_, i) => {
                const start = i * numOfRows;
                const end = start + numOfRows;
                return values.slice(start, end);
            });

            return elements?.map((elem, index) => {
                return (
                    <FeatureColumnWrapper key={index}>
                        {elem.map((template: any) => {
                            const isSelected = !restrictedSections[feature]?.includes(template.key);

                            return (
                                <DialogCheckbox
                                    key={template.key}
                                    value={isSelected}
                                    active={isSelected}
                                    text={template.title || template.value}
                                    disabled={UncheckableFeatureValues.includes(template.value)}
                                    onClick={() => {
                                        if (template.key === Capabilities.AGGREGATE && isSelected) {
                                            renderDisabledAggregatServicesDialog();
                                        }
                                        onSelect(feature, template.key, isSelected);
                                    }}
                                />
                            );
                        })}
                    </FeatureColumnWrapper>
                );
            });
        };

        if (feature === restrictedSectionsTypes.MISCELLANEOUS) {
            return array.map((value) => {
                return (
                    <>
                        <FeatureSubTitle>{value.subtitle}</FeatureSubTitle>
                        <FeatureSubSection>{getColumns(value.values)}</FeatureSubSection>
                    </>
                );
            });
        }

        return <FeatureSections>{getColumns(array)}</FeatureSections>;
    };

    const renderTables = () => {
        return Object.values(restrictedSectionsTypes).map((feature) => {
            const title = FEATURE_TITLE_MAPPING[feature];

            return (
                <FeatureContainer key={`${feature}_config`}>
                    <FeatureTitleRow>
                        <FeatureTitle>{title}</FeatureTitle>
                    </FeatureTitleRow>

                    {renderFeatureSections(feature)}
                </FeatureContainer>
            );
        });
    };
    const error = projectsError || servicesError || templatesError || menuTypesError;

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle title={'Feature Configuration'} withProfile withoutSearch />

                    {projectsLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <ProjectsDropdownWrapper>
                                <DialogDropdownSingle
                                    value={projectsOptions.find((option) => option.value === selectedProject) || ''}
                                    onChange={(value: any) => setSelectedProject(value.value)}
                                    placeholder={'Project Name'}
                                    options={projectsOptions}
                                    noLabel
                                />
                            </ProjectsDropdownWrapper>
                            {selectedProject ? renderTables() : <>Please Select a project</>}
                        </>
                    )}

                    <PageActions onSave={() => onSave()} onCancel={onCancel} disabled={{ save: !showUnsaved }} />
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};
