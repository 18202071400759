import React, { FC } from 'react';
import { ProviderLogoCardContainer, ProviderCardSizeKey, ProviderLogoCardBox, ProviderLogoImg } from '../VisualEditor.css';
import { providerCardLogos } from '../../../assets/images/providerCardLogos';

export const supportedProvidersForLogos = [
    'youtube',
    'youtube_reco',
    'prime_video',
    'horizon_sports',
    'motor_racing',
    'swr3',
    'ted_talks'
] as const;
export type providerLogos = typeof supportedProvidersForLogos[number];

const ProviderLogoCard: FC<{ provider: providerLogos; template: ProviderCardSizeKey }> = ({ provider, template }) => {
    const renderProviderLogo = () => {
        switch (provider) {
            case 'youtube':
            case 'youtube_reco':
                return <ProviderLogoImg src={providerCardLogos.YoutubeCardLogo} alt="youtube" />;
            case 'prime_video':
                return <ProviderLogoImg src={providerCardLogos.PrimeVideoCardLogo} alt="prime_video" />;
            case 'horizon_sports':
                return <ProviderLogoImg src={providerCardLogos.HorizonSportsCardLogo} alt="horizon_sports" />;
            case 'motor_racing':
                return <ProviderLogoImg src={providerCardLogos.MotorRacingCardLogo} alt="motor_racing" />;
            case 'swr3':
                return <ProviderLogoImg src={providerCardLogos.SWR3Logo} alt="swr3" />;
            case 'ted_talks':
                return <ProviderLogoImg src={providerCardLogos.TedTalksLogo} alt="ted_talks" />;
            default:
                return null;
        }
    };

    return (
        <ProviderLogoCardBox>
            <ProviderLogoCardContainer $type={template} $provider={provider}>
                {renderProviderLogo()}
            </ProviderLogoCardContainer>
        </ProviderLogoCardBox>
    );
};

export default ProviderLogoCard;
