import React, { useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { logos } from '../../assets/images/providerLogos';
import { useAppSelector, useAppDispatch as useDispatch } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { fetch3rdPartyServices, fetchServices, SourcesState } from '../../redux/slices/sourceSlice';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import getTheme from '../../style/themes/theme';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import Sidebar from '../common/Sidebar/Sidebar';
import {
    ContentProviderCards,
    ContentProviderCard,
    ContentProviderStatusContainer,
    ContentProvidersContainer,
    ContentProviderSettings,
    ContentProviderStatus,
    ContentProvidersTitle,
    ContentProviderLogoContainer,
    ContentProviderTooltip
} from './Aggregate.css';
import { ProviderSettingsDialog } from './Dialogs/ProviderSettings';
import { Capabilities, CapabilitiesScreen, capabilitiesCircleSlugs } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { Project, restrictedSectionsTypes } from '../../types/Project';
import { ProjectsState } from '../../redux/slices/projectsSlice';
import { openDocumentation } from '../../utils/parsers';
import { Loader } from '../common/Loader/Loader';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';

const supportedServicesLogos: Record<string, string> = {
    youtube: logos.YoutubeLogoAggregate,
    prime_video: logos.PrimeVideoLogoAggregate,
    stingray_karaoke: logos.StingrayLogoAggregate,
    dazn: logos.DAZNLogoAggregate
};

const supportedServicesKeys: Record<string, string[]> = {
    youtube: ['youtube', 'youtube_reco'],
    prime_video: ['prime_video'],
    stingray_karaoke: ['stingray_karaoke'],
    dazn: ['dazn']
};

enum serviceStates {
    AVAILABLE = 'AVAILABLE',
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

const serviceStateLabels = {
    [serviceStates.AVAILABLE]: 'Setup',
    [serviceStates.DISABLED]: 'Learn more',
    [serviceStates.ENABLED]: 'Settings'
};

const theme: any = getTheme();

const Aggregate: React.FC = () => {
    const { services, loading: servicesLoading, thirdPartyServices }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { projects, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const { isRestrictedArea: isAggregateRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'aggregate');

    const [showSettingsDialog, setShowSettingsDialog] = useState(false);
    const [selectedService, setSelectedService] = useState('');
    const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined);

    useEffect(() => {
        loadServices(activeProjectId || '');
    }, [activeProjectId]);

    useEffect(() => {
        if (!projects.length || !activeProjectId) return;
        const project = projects.find((project) => project._id === activeProjectId);
        setSelectedProject(project);
    }, [projects, activeProjectId]);

    useEffect(() => {
        load3rdPartyServices();
    }, []);

    const dispatch = useDispatch();

    const loadServices = async (projectId: string) => {
        return await dispatch(fetchServices({ projectId })).unwrap();
    };

    const load3rdPartyServices = async () => {
        return await dispatch(fetch3rdPartyServices()).unwrap();
    };

    const renderContentProviders = () => {
        return (
            <ContentProvidersContainer>
                <ContentProvidersTitle>Content Providers</ContentProvidersTitle>
                {services.length ? (
                    <ContentProviderCards>
                        {Object.keys(supportedServicesKeys).map((key, index) => {
                            const unsetService = !selectedProject?.previewTokens?.[key];
                            const enabled =
                                !isAggregateRestricted && services.some((service) => supportedServicesKeys[key].includes(service.key));
                            const tokenDependant = thirdPartyServices.some(
                                (service) => supportedServicesKeys[key].includes(service.key) && service.tokenDependant
                            );
                            const logoIsSvg = supportedServicesLogos[key].endsWith('.svg');
                            const serviceState = enabled
                                ? unsetService && tokenDependant
                                    ? serviceStates.AVAILABLE
                                    : serviceStates.ENABLED
                                : serviceStates.DISABLED;
                            return (
                                <ContentProviderCard key={`${key}-${index}`}>
                                    <ContentProviderLogoContainer
                                        $backgroundColor={key === 'prime_video' ? theme.palette.background.providers.amazon : undefined}
                                        disabled={!enabled}
                                    >
                                        {logoIsSvg ? (
                                            <SVGInline src={supportedServicesLogos[key]} />
                                        ) : (
                                            <img src={supportedServicesLogos[key]} />
                                        )}
                                    </ContentProviderLogoContainer>
                                    <ContentProviderStatus>
                                        {serviceState === serviceStates.ENABLED && <SVGInline src={icons.checkmarkCircularFilledIcon} />}
                                        <ContentProviderStatusContainer>
                                            {serviceState}
                                            {serviceState === serviceStates.AVAILABLE && (
                                                <ContentProviderTooltip>
                                                    {renderTooltipWithKey(
                                                        <SVGInline src={icons.infoIcon} />,
                                                        'aggregate_available_provider'
                                                    )}
                                                </ContentProviderTooltip>
                                            )}
                                        </ContentProviderStatusContainer>
                                    </ContentProviderStatus>
                                    <ContentProviderSettings
                                        onClick={() => {
                                            if (!enabled) {
                                                return openDocumentation(capabilitiesCircleSlugs[Capabilities.AGGREGATE], true);
                                            }
                                            setSelectedService(key);
                                            setShowSettingsDialog(true);
                                        }}
                                        label={serviceStateLabels[serviceState]}
                                        type={'DEFAULT'}
                                    ></ContentProviderSettings>
                                </ContentProviderCard>
                            );
                        })}
                    </ContentProviderCards>
                ) : null}
            </ContentProvidersContainer>
        );
    };

    const loading = projectsLoading || servicesLoading;

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle title="Aggregate" withProfile />
                <CapabilitiesScreen type={Capabilities.AGGREGATE} />
                {loading ? <Loader title={'Aggregate'} /> : renderContentProviders()}
            </MainContentWrapper>
            <ProviderSettingsDialog
                open={showSettingsDialog}
                onSave={() => setShowSettingsDialog(false)}
                onClose={() => setShowSettingsDialog(false)}
                selectedService={selectedService}
            />
        </ApplicationWrapper>
    );
};

export default Aggregate;
