import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { FileFieldContainer } from '../common/Dialog/GenericDialog.css';
import { DragAndDropWrapper } from '../../HOCs/DragAndDrop/DragAndDrop.css';
import { devices } from '../../style/styled-components/reusable.css';
import { ContentMarker } from './Dialogs/ContentMarkersDialog';

const theme: any = getTheme();

export const DialogColorFieldsContainer = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyleViewName = styled.div`
    gap: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    ${fontSizesCSS.Body2};
`;

export const TemplateStyleViewName = styled(StyleViewName)`
    color: ${theme.palette.primary.main};
`;

export const StyleViewPages = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyleDefault = styled.div`
    span {
        padding: 0;
    }
`;

export const StyleViewLastModified = styled.div`
    ${fontSizesCSS.Body1};
    gap: 4px;
    cursor: pointer;

    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-left: auto;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
        ${devices.tablet} {
            display: none;
        }
    }}
`;
export const ColorsContainerTitle = styled.div`
    margin-bottom: 24px;
`;
export const ColorsContainerValues = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        width: 0;
    }
`;

export const ColorCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;
`;

export const ColorName = styled.div`
    ${fontSizesCSS.Caption3};
`;

export const ColorValue = styled.div`
    margin-bottom: 8px;
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.text.main};
`;

export const ColorsContainer = styled.div`
    background: ${theme.palette.white.main};
    padding: 24px;
    box-sizing: border-box;
`;

export const StyleFieldsContainer = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 24px;
  
    .MuiFormControl-root {
        width: 320px;
        margin: 0
    }

    [id$='select_wrapper_Part of Page(s)'] {
        width: 414px;
       
        .cc_select__multi-value {
            padding: 6px;
            div.custom-value-label {
                color ${theme.palette.primary.main};
                ${fontSizesCSS.Caption3};
            }
        }
    }

    ${devices.tablet} {
        [id$='select_wrapper_Part of Page(s)'] {
            width: 175px 
        }
        .MuiFormControl-root {
            width: auto;
        } 
    }
`;

export const ImageHeader = styled.div`
    ${fontSizesCSS.Body2};
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const ImageFieldContainer = styled.div<{ isDialog?: boolean }>`
    background-color: ${theme.palette.white.main};
    margin: 1px 0;
    padding: 24px;
    border-radius: 4px;
    ${(props) =>
        props.isDialog
            ? css`
                  overflow: auto;
                  scrollbar-width: none;
                  &::-webkit-scrollbar {
                      display: none;
                  }
                  ${ImageFields} {
                      width: 100%;
                  }
              `
            : css``};

    ${devices.desktopS} {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
export const ImageFields = styled.div<{ $applyForAll?: boolean }>`
    gap: 16px;
    display: flex;
    padding-bottom: 30px;
    margin-bottom: 16px;

    ${devices.desktopS} {
        overflow-x: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    ${/*for now revert to not showing fields if apply for all is true */ ''}
    ${FileFieldContainer} {
        &:not(:first-child) {
            ${({ $applyForAll }) =>
                $applyForAll &&
                `
            opacity: 0.6;
            pointer-events: none;`}
        }
        height: 108px;
        width: 188px;
        border: none;
        padding: 0;
        ${DragAndDropWrapper} {
            background: ${theme.palette.background.imageFields};
            padding: 0;
            border-radius: 3px;
            border: 2px dashed ${theme.palette.background.innerBorder};
        }
    }
    label {
        ${fontSizesCSS.Caption3}
    }
    svg {
        opacity: 0.7;
    }
`;

export const StyleTextFieldWrapper = styled.div`
    ${devices.tablet} {
        width: 100%;
    }

    width: 500px;
`;
export const PageStyleTogglesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const PageStyleArrowsContainer = styled.div`
    cursor: pointer;
    svg:first-child {
        transform: rotate(180deg);
    }
`;

export const PageStyleSectionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
`;

// CONTENT MARKERS

export const ContentMarkersContainer = styled(ImageFieldContainer)``;
export const ContentMarkersHeader = styled(ImageHeader)`
    margin-bottom: 16px;
`;
export const ContentMarkerName = styled.div`
    ${fontSizesCSS.Body2};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        position: absolute;
        display: none;
    }
`;
export const ContentMarkerField = styled.div<{ $contentMarker?: ContentMarker }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 36px;
    background: ${({ $contentMarker }) => $contentMarker?.backgroundColor || theme.palette.background.imageFields};
    border: 2px ${({ $contentMarker }) => $contentMarker?.borderColor || theme.palette.background.innerBorder};
    border-style: ${({ $contentMarker }) => ($contentMarker?.borderColor ? 'solid' : 'dashed')};
    border-radius: 4px;
    cursor: pointer;
    svg {
        width: 13px;
        height: 13px;
        opacity: 0.7;
    }
    ${ContentMarkerName} {
        color: ${({ $contentMarker }) => $contentMarker?.fontColor || theme.palette.white.main};
    }
    ${({ $contentMarker }) =>
        $contentMarker &&
        `&:hover {
            ${ContentMarkerName} {
            background: ${theme.palette.background.hovered};
            span {
                opacity: 0.7;
            }
            svg {
                display: block;
            }
        }
    }`}
`;
