import { FilterObject } from '../components/common/Select/FancyFilter';
import { getDateStandardFormat } from './fnDate';

export const ACCEPTED_FILTER_FIELDS = {
    _id: '_id',
    source: 'source',
    maxNumberOfItems: 'maxNumberOfItems',
    placed: 'placed',
    conditionIds: 'conditionIds',
    publishedBefore: 'publishedBefore',
    publishedAfter: 'publishedAfter',
    modifiedBy: 'modifiedBy',
    publishStatus: 'publishStatus',
    audiences: 'audiences',
    conditions: 'conditions',
    abGroups: 'abGroups',
    translated: 'translated'
} as const;

export type AcceptedObjectFilterField = typeof ACCEPTED_FILTER_FIELDS[keyof typeof ACCEPTED_FILTER_FIELDS];

export type ObjectFilter = {
    [key in AcceptedObjectFilterField]?: string[];
};

export const selectValueToObjectFilter = (values: any[]): ObjectFilter => {
    const filterObject: { [key: string]: string[] } = {};

    values.forEach((value: any) => {
        const field: string = value?.field?.value;
        const filterValue = value?.filterValue?.value;

        switch (field) {
            case 'displayConditions':
            case 'targetingHeaders':
                filterObject[filterValue] = [];
                break;
            case 'displayConditionValues':
                const splitValue = filterValue.split('.');
                const key = splitValue.slice(0, 2).join('.');
                const value = splitValue[2];
                if (filterObject[key]) {
                    filterObject[key].push(value);
                }
                break;
            case 'targetingHeaderValues':
                const headerSplitValue = filterValue.split('.');
                const headerKey = headerSplitValue[0];
                const headerValue = headerSplitValue.slice(1).join('.');
                if (filterObject[headerKey]) {
                    filterObject[headerKey].push(headerValue);
                }
                break;
            default:
                if (filterObject[field]) {
                    filterObject[field].push(filterValue);
                } else {
                    filterObject[field] = [filterValue];
                }
                break;
        }
    });

    return filterObject;
};

export const objectFilterToSelectValue = (
    storedState: ObjectFilter,
    fields: any[],
    filterValues: { [key: string]: any[] }
): FilterObject[] => {
    const selectedValue: FilterObject[] = [];

    // Iterate over each key-value pair in storedState
    for (const [fieldValue, filterValueArray = []] of Object.entries(storedState)) {
        // Find the corresponding field object in fields
        const fieldObject = fields.find((field) => field.value === fieldValue);
        // Iterate over each filterValue in filterValueArray
        for (const filterValue of filterValueArray) {
            // Find the corresponding filterValue object in filterValues
            const filterValueObject =
                fieldValue === 'modifiedBefore' || fieldValue === 'modifiedAfter'
                    ? {
                          value: filterValueArray[0],
                          label: getDateStandardFormat(parseInt(filterValueArray[0]))
                      }
                    : filterValues[fieldValue].find((fv) => fv.value === filterValue);

            // Push the constructed FilterObject to selectedValue
            selectedValue.push({
                field: fieldObject,
                filterValue: filterValueObject,
                value: fieldValue + filterValueObject.value
            });
        }
    }

    return selectedValue;
};

export const objectFilterToQueryParam = (filter: ObjectFilter): { [key in AcceptedObjectFilterField as `filter[${key}]`]: string } => {
    return Object.entries(filter).reduce(
        (acc, [key, value]) => ({ ...acc, [`filter[${key}]`]: value?.join(',') }),
        {} as { [key in AcceptedObjectFilterField as `filter[${key}]`]: string }
    );
};
