import { FormLabel } from '@material-ui/core';
import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { FileFieldContainer, LabelWrapper, RadioText } from '../common/Dialog/GenericDialog.css';
import { ToggleExtraFields, OptionsToggle } from '../Modules/Dialogs/NewModule.css';
import { NameWrapper } from '../Modules/Modules.css';
import { SelectWrapper } from '../common/Select/Select.css';

const theme: any = getTheme();
export const ItemName = styled(NameWrapper)``;

export const ImageFieldsContainer = styled.div`
    display: flex;
    gap: 8px;
    ${FileFieldContainer} {
        margin-bottom: 0;
        ${LabelWrapper} {
            height: 36px;
            display: flex;
            align-items: flex-end;
        }
    }
`;

export const VideoLabel = styled.div`
    ${fontSizesCSS.Body2};
    margin: 24px 0;
`;

export const ContentSourceLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
        path {
            fill: ${theme.palette.text.main};
        }
    }
`;

export const SupportedLabel = styled(FormLabel)`
    font-size: 12px;
`;

export const ActionFieldsToggle = styled(OptionsToggle)`
    margin-bottom: 24px;
    width: fit-content;
    cursor: pointer;
`;

export const ContentWorldExtraFields = styled(ToggleExtraFields)``;

export const ContentWorldField = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    margin-left: 32px;
`;
