import React, { FC, useEffect, useState } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../style';
import {
    ArrowContainer,
    ServiceTableRow,
    ServiceTitle,
    SourceName,
    SourcePlaced,
    SourceSmallTableRow,
    TemplateNameWrapper
} from '../../../Sources/Sources.css';
import {
    createTemplate as _createTemplate,
    deleteTemplate as _deleteTemplate,
    fetchTemplates,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    templatesState,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { fetchServices, SourcesState } from '../../../../redux/slices/sourceSlice';
import { fetchLanguageCodes } from '../../../../redux/slices/languagesSlice';
import { generateDateStringForTables } from '../../../../utils/fnDate';
import { ActionIconHolder, LastModified } from '../../../TargetGroups/UXElements/Audiences/Audiences.css';
import { Loader } from '../../../common/Loader/Loader';
import { dialogConfirm } from '../../../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import Button from '../../../Buttons/Button/Button';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionsWrapper,
    PlacedInPublishedWarningMessageWrapper
} from '../../../../style/styled-components/reusable.css';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import { ObjectActions } from '../../../common/Actions/Actions';
import GenericTable, { tableActions } from '../../../common/Table/Table';
import { Template, templateTypes } from '../../../../types/Template';
import { NewSourceTemplateDialog as NewSourceDialog } from './NewSource';
import { TemplateImage, TemplateImageWrapper } from '../Templates.css';
import { PUBLISHED_STATUS } from '../../../../utils/fnPublish';
import { SearchBarContainer } from '../../../common/SearchBar/SearchBar.css';
import { SearchBar } from '../../../common/SearchBar/SearchBar';
import Labels from '../../../common/Labels/Labels';
import { MoreInfoTypes } from '../../../common/Dialog/MoreInfoDialog';
import { PageRoutes } from '../../../../types/RouteTypes';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '../../../../hooks/useScreenSize';
import { WidthTableCell } from '../../../common/Table/Table.css';
import { SourcesTemplatesTableSizes } from '../../../../types/TableSizes';
import { TableRow } from '@material-ui/core';

export const Sources: FC<any> = () => {
    const [expandedService, setExpandedService] = useState<string>('');
    const [newSourceDialogOpen, setNewSourceDialogOpen] = useState<boolean>(false);
    const [templateToEdit, setTemplateToEdit] = useState<Template | undefined>(undefined);
    const [duplicating, setDuplicating] = useState<boolean>(false);

    const { templates: storeTemplates, templateTypeLoading, loading }: templatesState = useAppSelector((state) => state.templates);
    const { services: Services }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { isMobile, isTablet } = useScreenSize();

    const [sourceTemplates, setSourceTemplates] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [newId, setNewId] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadTemplates = async (searchTerm?: string) => {
        await dispatch(fetchTemplates({ type: templateTypes.DYNAMIC_SOURCE, searchTerm })).unwrap();
    };

    const handleOnSearch = (searchTerm: string) => {
        loadTemplates(searchTerm);
    };

    const loadServices = async (projectId: string) => {
        return await dispatch(fetchServices({ projectId, forTemplates: true })).unwrap();
    };

    const loadCodes = async () => {
        return await dispatch(fetchLanguageCodes()).unwrap();
    };

    const createTemplate = async (template: Template) => {
        const response = await dispatch(_createTemplate(template)).unwrap();

        if (response.id) {
            loadTemplates();
            setNewId(response.id);
            setSearchTerm(undefined);
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const deleteTemplate = async (id: string) => {
        const response = await dispatch(_deleteTemplate({ templateId: id, type: templateTypes.DYNAMIC_SOURCE })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const releaseTemplate = async (id: string) => {
        const response = await dispatch(_releaseTemplate({ templateId: id, type: templateTypes.DYNAMIC_SOURCE })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const retractTemplate = async (id: string) => {
        const response = await dispatch(_retractTemplate({ templateId: id, type: templateTypes.DYNAMIC_SOURCE })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const handleDeleteIconClick = (id: string) => {
        const values = {
            title: 'Remove Source',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                deleteTemplate(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Source Template?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one!
                    <PlacedInPublishedWarningMessageWrapper>
                        If the Source Template is placed in an Item Template, it will also be removed from it!
                    </PlacedInPublishedWarningMessageWrapper>
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    useEffect(() => {
        loadTemplates(searchTerm)
            .then(() => loadServices(''))
            .then(() => loadCodes());
    }, []);

    useEffect(() => {
        if (loading || templateTypeLoading.dynamicSource) return;
        setSourceTemplates(storeTemplates.dynamicSource || []);

        if (storeTemplates?.dynamicSource?.length) {
            if (templateToEdit) {
                setTemplateToEdit(storeTemplates.dynamicSource.find((template) => template._id === templateToEdit._id));
            } else if (newId && newSourceDialogOpen) {
                setTemplateToEdit(storeTemplates.dynamicSource.find((template) => template._id === newId));
                setNewId('');
            }
        }
    }, [loading, templateTypeLoading.dynamicSource]);

    const renderExpandedService = (service: string) => {
        return sourceTemplates.map((template, index) => {
            const source = template.values;
            if (!source || source.service !== service) return;

            const dateString = generateDateStringForTables(template.lastModified || 0);

            return (
                <SourceSmallTableRow key={index} data-cy={`source-container-${index}`}>
                    <WidthTableCell {...SourcesTemplatesTableSizes.name}>
                        <TemplateNameWrapper>
                            <TemplateImageWrapper $background={template?.iconBackground}>
                                <TemplateImage src={template?.icon} />
                            </TemplateImageWrapper>
                            <SourceName>
                                <div>{source.name}</div>
                            </SourceName>
                        </TemplateNameWrapper>
                    </WidthTableCell>

                    <WidthTableCell {...SourcesTemplatesTableSizes.placed}>
                        <SourcePlaced>
                            <Labels
                                values={template.placed || []}
                                type={MoreInfoTypes.CONNECTED_TO}
                                noOfLabels={isMobile ? 1 : isTablet ? 2 : 3}
                                onClickLabel={(obj) => {
                                    navigate(PageRoutes.SUPERADMIN_ITEMS, { state: { itemId: obj._id } });
                                }}
                            />
                        </SourcePlaced>
                    </WidthTableCell>

                    <WidthTableCell {...SourcesTemplatesTableSizes.lastModified}>
                        <LastModified>
                            <span>{dateString}</span>
                        </LastModified>
                    </WidthTableCell>

                    <WidthTableCell {...SourcesTemplatesTableSizes.actions}>
                        <ObjectActions
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                setTemplateToEdit(template);
                                setNewSourceDialogOpen(true);
                            }}
                            onRemove={() => {
                                handleDeleteIconClick(template._id || '');
                            }}
                            tooltipTexts={{
                                edit: 'superadmin_source_templates_icon_edit',
                                delete: 'superadmin_source_templates_icon_delete',
                                published: template.released
                                    ? 'superadmin_source_templates_icon_released'
                                    : 'superadmin_source_templates_icon_unreleased'
                            }}
                            publishedStatus={template.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED}
                        />
                    </WidthTableCell>
                </SourceSmallTableRow>
            );
        });
    };

    const buildTableBody = () => {
        const servicesToShow = Services.filter((service) => sourceTemplates.some((template) => template.values.service === service.key));

        return (
            <>
                {servicesToShow.map((service, index) => {
                    const isExpanded = expandedService === service.key;
                    return (
                        <>
                            <ServiceTableRow
                                key={index}
                                data-cy={`source-service-${index}`}
                                onClick={() => {
                                    setExpandedService(isExpanded ? '' : service.key);
                                }}
                            >
                                <WidthTableCell {...SourcesTemplatesTableSizes.name} colSpan={3}>
                                    <ServiceTitle>{service.title}</ServiceTitle>
                                </WidthTableCell>

                                <WidthTableCell {...SourcesTemplatesTableSizes.actions}>
                                    <ArrowContainer>
                                        {isExpanded ? 'Close' : 'Open'}
                                        <ActionIconHolder>
                                            {renderTooltipWithKey(
                                                <SVGInline src={isExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} onClick={() => {}} />,
                                                `${isExpanded ? 'sources_icon_hide' : 'sources_icon_show'}`
                                            )}
                                        </ActionIconHolder>
                                    </ArrowContainer>
                                </WidthTableCell>
                            </ServiceTableRow>
                            {isExpanded && renderExpandedService(service.key)}
                            <TableRow style={{ height: '24px' }} />
                        </>
                    );
                })}
            </>
        );
    };

    const isEmpty = !sourceTemplates.length;

    return (
        <>
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={templateTypeLoading.dynamicSource}
                        title="Source Templates"
                        withProfile
                        withoutSearch
                        withAddButton
                        addLabel="Create Source Template"
                        onAdd={() => {
                            setNewSourceDialogOpen(true);
                        }}
                    />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Name'}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                            disabled={templateTypeLoading.dynamicSource || loading}
                        />
                    </SearchBarContainer>
                    {templateTypeLoading.dynamicSource ? (
                        <Loader title={'Source Templates'} />
                    ) : isEmpty ? (
                        <PageActionsWrapper>
                            <Button
                                id="new-source-template-button"
                                onClick={() => {
                                    setNewSourceDialogOpen(true);
                                }}
                                label={'Create Source Template'}
                                type={'BLUE'}
                            />
                        </PageActionsWrapper>
                    ) : (
                        <GenericTable columns={[]} body={buildTableBody()} />
                    )}
                </MainContentWrapper>

                <NewSourceDialog
                    open={newSourceDialogOpen}
                    onClose={() => {
                        setNewSourceDialogOpen(false);
                        setTemplateToEdit(undefined);
                        setDuplicating(false);
                    }}
                    onSave={(template) => {
                        if (templateToEdit && !duplicating) {
                            updateTemplate(template);
                        } else {
                            createTemplate(template);
                        }
                    }}
                    onRelease={(id) => releaseTemplate(id)}
                    onRetract={(id) => retractTemplate(id)}
                    template={templateToEdit}
                />
            </ApplicationWrapper>
        </>
    );
};
