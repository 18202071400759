import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { Template, templateTypes } from '../../../../types/Template';
import { supportedResolutionsType } from '../../../Items/Dialogs/BackgroundDialog';
import { availableColors, defaultColorValues } from '../../../../types/PageStyle';
import { fetchContentMarkers, PageStylesState } from '../../../../redux/slices/pageStylesSlice';
import { FilesState, uploadFilesSync } from '../../../../redux/slices/fileManagerSlice';
import { useNavigate } from 'react-router-dom';
import useScreenSize from '../../../../hooks/useScreenSize';
import useBlockNavigation from '../../../../hooks/useBlockNavigation';
import _ from 'lodash';
import { isOpenFileUrl } from '../../../../utils/fnUrl';
import { API_ERROR_CODES, EMPTY_WORD_STRING, getImgixUrl } from '../../../../utils/Globals';
import { DIALOG_NAMES, dialogConfirm } from '../../../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../assets/images/icons';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../../../style/styled-components/reusable.css';
import {
    ColorCard,
    ColorName,
    ColorsContainer,
    ColorsContainerValues,
    ImageFieldContainer,
    ImageFields,
    ImageHeader,
    PageStyleSectionTitle,
    PageStyleTogglesContainer,
    StyleViewLastModified,
    TemplateStyleViewName
} from '../../../PageStyle/PageStyle.css';
import { generateDateStringForTables } from '../../../../utils/fnDate';

import { renderLockedError } from '../../../../utils/fnLockingSystem';
import { DialogCheckbox, DialogFileField, DialogToggleButton } from '../../../common/Dialog/GenericDialog';
import CustomColorPicker from '../../../common/ColorPicker/CustomColorPicker';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import { Loader } from '../../../common/Loader/Loader';
import PageActions from '../../../common/PageActions/PageActions';
import { ContentMarker } from '../../../PageStyle/Dialogs/ContentMarkersDialog';
import {
    acceptedExtensionsPageBackground,
    acceptedExtensionsPageLogo,
    handleImages,
    PAGE_STYLE_ADD_BUTTON_ID,
    pageStyleImageInfo,
    PageStyleStripeArrows
} from '../../../PageStyle/PageStyle';
import Sidebar from '../../Sidebar/Sidebar';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import {
    createTemplate as _createTemplate,
    deleteTemplate as _deleteTemplate,
    fetchTemplates,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate,
    templatesState,
    updateTemplate as _updateTemplate
} from '../../../../redux/slices/templatesSlice';

import { NewPageStyleTemplate } from './NewPageStyleTemplate';
import { TemplateImage, TemplateImageWrapper } from '../Templates.css';
import { PUBLISHED_STATUS } from '../../../../utils/fnPublish';
import { GroupField, GroupFieldsContainer } from '../../../TargetGroups/GroupEdit.css';
import { TableRow } from '@material-ui/core';
import { MoreInfoTypes } from '../../../common/Dialog/MoreInfoDialog';

import { PageRoutes } from '../../../../types/RouteTypes';
import { ObjectActions } from '../../../common/Actions/Actions';
import GenericTable, { HeaderTableCell, tableActions } from '../../../common/Table/Table';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import { PageStyleTemplatesTableSizes } from '../../../../types/TableSizes';
import { TableRowWrapper, WidthTableCell } from '../../../common/Table/Table.css';
import { ResourceCard } from '../../../Cards/ResourceCard/ResourceCard';
import { resourceCardImages } from '../../../../assets/images/resourceCards';
import { openDocumentation } from '../../../../utils/parsers';
import circleSlugs from '../../../../utils/circle_slugs.json';
import Labels from '../../../common/Labels/Labels';
import { SearchBarContainer } from '../../../common/SearchBar/SearchBar.css';
import { SearchBar } from '../../../common/SearchBar/SearchBar';
import { ToggleButtonContainer } from '../../../common/Dialog/GenericDialog.css';
import { OptionEntry } from '../../../Modules/Dialogs/NewModule.css';
import { ContentMarkers } from '../../../PageStyle/ContentMarkers';

export const PageStylesTemplate: React.FC = () => {
    const { contentMarkers }: PageStylesState = useAppSelector((state) => state.pageStyles);
    const [activeTab, setActiveTab] = useState<'SYSTEM_TEMPLATES' | 'USER_SUGGESTIONS'>('SYSTEM_TEMPLATES');

    // Hold the PageStyle object which is being worked on
    const [pageStyleTemplateToEdit, setPageStyleTemplateToEdit] = useState<any>(undefined);

    const [openNewTemplate, setOpenNewTemplate] = useState<boolean>(false);

    const [applyForAll, setApplyForAll] = useState<{ pageLogo?: boolean; pageBackgroundImage?: boolean }>({
        pageLogo: true,
        pageBackgroundImage: true
    });

    const [imageFiles, setImageFiles] = useState<{ pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType }>({
        pageLogo: {},
        pageBackgroundImage: {}
    });
    const [imageUrls, setImageUrls] = useState<{ pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType }>({
        pageLogo: {},
        pageBackgroundImage: {}
    });
    const [initialImages, setInitialImages] = useState<
        | {
              pageLogo: supportedResolutionsType;
              pageBackgroundImage: supportedResolutionsType;
          }
        | undefined
    >(undefined);
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const { templates: storeTemplates, loading, error }: templatesState = useAppSelector((state) => state.templates);

    const { error: imageError, loading: imageLoading }: FilesState = useAppSelector((state) => state.files);

    const { config } = useAppSelector((state) => state.config);

    const colorsContainerRef = useRef<HTMLDivElement>(null);
    const logoContainerRef = useRef<HTMLDivElement>(null);
    const backgroundContainerRef = useRef<HTMLDivElement>(null);

    const { isMobile, isDesktop, isLargeDesktop } = useScreenSize();

    const unsavedDependencyArray = [pageStyleTemplateToEdit, applyForAll];

    // Blocking navigation when there are unsaved changes
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCancelClick = () => {
        setOpenNewTemplate(false);
        setPageStyleTemplateToEdit(undefined);
        setInitialImages(undefined);
        setImageUrls({
            pageLogo: {},
            pageBackgroundImage: {}
        });
        setImageFiles({
            pageLogo: {},
            pageBackgroundImage: {}
        });
    };
    useEffect(() => {
        loadTemplates(searchTerm);
        if (!contentMarkers) loadContentMarkers();
        handleCancelClick();
    }, []);

    useEffect(() => {
        setShowUnsaved(calculateShowUnsaved());
    }, [...unsavedDependencyArray]);

    const expandPageStyle = (id: string) => {
        if (id === pageStyleTemplateToEdit?._id) {
            setPageStyleTemplateToEdit(undefined);
            setInitialImages(undefined);
            return;
        }
        const pageStyleTemplate = storeTemplates.pageStyle?.find((ps) => ps._id === id);
        setPageStyleTemplateToEdit({
            ..._.cloneDeep(pageStyleTemplate)
        });
        setApplyForAll({
            pageBackgroundImage: calculateApplyForAll(pageStyleTemplate, 'pageBackgroundImage'),
            pageLogo: calculateApplyForAll(pageStyleTemplate, 'pageLogo')
        });
        setInitialImages({
            pageLogo: pageStyleTemplate?.values?.pageLogo || {},
            pageBackgroundImage: pageStyleTemplate?.values?.pageBackgroundImage || {}
        });
    };
    const calculateApplyForAll = (style: any, key: string) => {
        const values: any[] = Object.values({ ...style?.values?.[key] });
        if (!values.length) return true;
        const firstUrl = decodeURIComponent(values[0]);
        const isOpenUrl = isOpenFileUrl(config.imgixBaseUrl, firstUrl);
        const firstFileName = !isOpenUrl ? firstUrl.split(config.imgixBaseUrl)[1].split('?')[0] : firstUrl.split('/').pop();
        return (
            !values
                .filter((_, index) => index > 0)
                .some((value) => {
                    const url = decodeURIComponent(value);
                    const isOpenUrl = isOpenFileUrl(config.imgixBaseUrl, url);
                    const fileName = !isOpenUrl ? url.split(config.imgixBaseUrl)[1].split('?')[0] : url.split('/').pop();
                    return fileName !== firstFileName;
                }) && values.length === 4
        );
    };

    const handleSave = async () => {
        let newPageStyleTemplate: Template = {
            _id: pageStyleTemplateToEdit?._id || '',
            type: templateTypes.PAGE_STYLE,
            values: {
                ...pageStyleTemplateToEdit?.values
            },
            projectIds: pageStyleTemplateToEdit?.projectIds || [],
            tenantIds: pageStyleTemplateToEdit?.tenantIds || [],
            icon: pageStyleTemplateToEdit?.icon,
            iconBackground: pageStyleTemplateToEdit?.iconBackground,
            audienceValues: pageStyleTemplateToEdit?.audienceValues || [],
            lastModified: pageStyleTemplateToEdit?.lastModified
        };

        const imagesResult = await handleImages(config, imageFiles, imageUrls, applyForAll, initialImages, createFiles);
        if (imagesResult !== null) {
            imagesResult.forEach((result, i) => {
                const key = i === 0 ? 'pageLogo' : 'pageBackgroundImage';
                const value = Object.assign({ ...newPageStyleTemplate.values[key] }, { ...result });
                _.set(newPageStyleTemplate.values, key, value);
            });
        } else return;

        updateTemplate(newPageStyleTemplate);
        handleCancelClick();
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleSave();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handleCreateClick = () => {
        handleCancelClick();
        setOpenNewTemplate(true);
    };

    const handleDelete = (id: string) => {
        const values = {
            title: 'Remove Page Style Template',
            text: ''
        };

        dialogConfirm(
            '',
            async () => {
                await deleteTemplate(id);
                loadTemplates(searchTerm);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Page Style Template?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one!
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const resetImageKeys = (parentKey: 'pageLogo' | 'pageBackgroundImage') => {
        let newImages: any = { ...imageFiles };
        let newStyle: any = { ...pageStyleTemplateToEdit };
        let newUrls: any = { ...imageUrls };
        let newInitialImages: any = { ...initialImages };
        newImages = _.set(newImages, parentKey, {});
        newStyle = _.set(newStyle, `values.${parentKey}`, {});
        newUrls = _.set(newUrls, parentKey, {});
        newInitialImages = _.set(newInitialImages, parentKey, {});

        // when there is no background image selected the increasedTopMargin values should be false
        if (parentKey === 'pageBackgroundImage') {
            newStyle.values.increasedTopMargin = false;
        }
        setImageFiles(newImages);
        setPageStyleTemplateToEdit(newStyle);
        setImageUrls(newUrls);
        setInitialImages(newInitialImages);
    };

    const loadTemplates = async (searchTerm?: string) => {
        await dispatch(fetchTemplates({ type: templateTypes.PAGE_STYLE, searchTerm })).unwrap();
    };

    const loadContentMarkers = async () => {
        return await dispatch(fetchContentMarkers()).unwrap();
    };

    const handleOnSearch = (searchTerm: string) => {
        loadTemplates(searchTerm);
    };

    const createTemplate = async (template: Template) => {
        const response = await dispatch(_createTemplate(template)).unwrap();

        if (response.id) {
            loadTemplates();
            setSearchTerm(undefined);
            return response.id;
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const deleteTemplate = async (id: string) => {
        const response = await dispatch(_deleteTemplate({ templateId: id, type: templateTypes.PAGE_STYLE })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const releaseTemplate = async (id: string) => {
        const response = await dispatch(_releaseTemplate({ templateId: id, type: templateTypes.PAGE_STYLE })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const retractTemplate = async (id: string) => {
        const response = await dispatch(_retractTemplate({ templateId: id, type: templateTypes.PAGE_STYLE })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const createFiles = async (files: File[], index: number) => {
        const prefix = `system_templates/pageStyle_backgrounds`;

        try {
            const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const handleImageSelect = (
        image: File | string,
        parentKey: 'pageLogo' | 'pageBackgroundImage',
        key: keyof supportedResolutionsType
    ) => {
        let newImages: any = { ...imageFiles };
        let newUrls: any = { ...imageUrls };
        let newStyleObject: any = { ...pageStyleTemplateToEdit };
        let newInitialImages: any = { ...initialImages };
        if (!image) {
            if (applyForAll[parentKey]) {
                newImages = _.set(newImages, parentKey, {});
                newStyleObject = _.set(newStyleObject, `values.${parentKey}`, {});
                newUrls = _.set(newUrls, parentKey, {});
                newInitialImages = _.set(newInitialImages, parentKey, {});
            } else {
                newImages[parentKey] = _.omit(newImages[parentKey], key);
                newStyleObject.values[parentKey] = _.omit(newStyleObject.values[parentKey], key);
                newUrls[parentKey] = _.omit(newUrls[parentKey], key);
                newInitialImages[parentKey] = _.omit(newInitialImages[parentKey], key);
            }
        } else {
            if (typeof image === 'string') {
                const isOpenUrl = !image.includes(`system_templates`);
                _.set(newUrls, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
                _.set(newImages, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
                _.set(newStyleObject, `values.${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
            } else {
                _.set(newImages, `${parentKey}.${key}`, image);
                _.set(newStyleObject, `values.${parentKey}.${key}`, image);
            }
        }
        // when there is no background image selected the increasedTopMargin values should be false
        if (!Object.keys(newStyleObject?.values.pageBackgroundImage || {}).length) {
            newStyleObject = { ...newStyleObject, values: { ...newStyleObject?.values, increasedTopMargin: false } };
        }
        setImageFiles(newImages);
        setPageStyleTemplateToEdit(newStyleObject);
        setImageUrls(newUrls);
        setInitialImages(newInitialImages);
    };

    const calculateShowUnsaved = () => {
        if (!pageStyleTemplateToEdit) return false;
        let oldPageStyle = { ...storeTemplates?.pageStyle?.find((ps) => ps._id === pageStyleTemplateToEdit?._id) };
        return !_.isEqual(oldPageStyle, pageStyleTemplateToEdit);
    };

    const renderExpandedPageStyle = () => {
        return (
            <div>
                <ColorsContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>Colors</ImageHeader>
                        <PageStyleStripeArrows containerRef={colorsContainerRef} />
                    </PageStyleSectionTitle>
                    <ColorsContainerValues ref={colorsContainerRef}>
                        {availableColors.map((color, i) => (
                            <ColorCard key={`color_${i}`}>
                                <ColorName>{_.capitalize(color.split(/(?=[A-Z])/).join(' '))}</ColorName>
                                <CustomColorPicker
                                    value={pageStyleTemplateToEdit?.values?.colors?.[color] || (defaultColorValues as any)[color]}
                                    onChange={(alphaHex: string) => {
                                        setPageStyleTemplateToEdit({
                                            ...pageStyleTemplateToEdit,
                                            values: {
                                                ...pageStyleTemplateToEdit.values,
                                                colors: {
                                                    ...pageStyleTemplateToEdit.values?.colors,
                                                    [color]: alphaHex
                                                }
                                            }
                                        });
                                    }}
                                />
                            </ColorCard>
                        ))}
                    </ColorsContainerValues>
                </ColorsContainer>

                <ImageFieldContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>
                            Page Background
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'style_and_branding_background_image')}
                        </ImageHeader>
                        <PageStyleStripeArrows containerRef={backgroundContainerRef} />
                    </PageStyleSectionTitle>
                    <ImageFields ref={backgroundContainerRef}>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={pageStyleTemplateToEdit?.values?.pageBackgroundImage?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.pageBackgroundImage ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'bigScreen')}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'bigScreen')}
                            alwaysShowLabel
                            withoutFileManager
                            accepts={acceptedExtensionsPageBackground}
                        />
                        {!applyForAll.pageBackgroundImage && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleTemplateToEdit?.values?.pageBackgroundImage?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'pc')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'pc')}
                                    alwaysShowLabel
                                    withoutFileManager
                                    accepts={acceptedExtensionsPageBackground}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleTemplateToEdit?.values?.pageBackgroundImage?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'tablet')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'tablet')}
                                    alwaysShowLabel
                                    withoutFileManager
                                    accepts={acceptedExtensionsPageBackground}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleTemplateToEdit?.values?.pageBackgroundImage?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'mobile')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'mobile')}
                                    alwaysShowLabel
                                    withoutFileManager
                                    accepts={acceptedExtensionsPageBackground}
                                />
                            </>
                        )}
                    </ImageFields>
                    <PageStyleTogglesContainer>
                        <ToggleButtonContainer>
                            <DialogToggleButton
                                checked={!!applyForAll?.pageBackgroundImage}
                                toggleCallback={() => {
                                    setApplyForAll({
                                        ...applyForAll,
                                        pageBackgroundImage: !applyForAll?.pageBackgroundImage
                                    });
                                    resetImageKeys('pageBackgroundImage');
                                }}
                                text={'Apply for all screens'}
                                tooltipText={applyForAll?.pageBackgroundImage ? 'apply_for_all_on' : 'apply_for_all_off'}
                            />
                        </ToggleButtonContainer>

                        <OptionEntry>
                            <DialogCheckbox
                                active={pageStyleTemplateToEdit.values.increasedTopMargin}
                                value={pageStyleTemplateToEdit.values.increasedTopMargin}
                                onClick={() => {
                                    setPageStyleTemplateToEdit({
                                        ...pageStyleTemplateToEdit,
                                        values: {
                                            ...pageStyleTemplateToEdit.values,
                                            increasedTopMargin: !pageStyleTemplateToEdit.values.increasedTopMargin
                                        }
                                    });
                                }}
                                text={'Increased Top Margin'}
                                disabled={!Object.keys(pageStyleTemplateToEdit?.values?.pageBackgroundImage || {}).length}
                            />
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'style_and_branding_increased_margin')}
                        </OptionEntry>
                    </PageStyleTogglesContainer>
                </ImageFieldContainer>
                <ImageFieldContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>Page Logo</ImageHeader>
                        <PageStyleStripeArrows containerRef={logoContainerRef} />
                    </PageStyleSectionTitle>
                    <ImageFields ref={logoContainerRef}>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={pageStyleTemplateToEdit?.values?.pageLogo?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll.pageLogo ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'bigScreen')}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'bigScreen')}
                            alwaysShowLabel
                            withoutFileManager
                            accepts={acceptedExtensionsPageLogo}
                        />
                        {!applyForAll.pageLogo && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleTemplateToEdit?.values?.pageLogo?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'pc')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'pc')}
                                    alwaysShowLabel
                                    withoutFileManager
                                    accepts={acceptedExtensionsPageLogo}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleTemplateToEdit?.values?.pageLogo?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'tablet')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'tablet')}
                                    alwaysShowLabel
                                    withoutFileManager
                                    accepts={acceptedExtensionsPageLogo}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={pageStyleTemplateToEdit?.values?.pageLogo?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'mobile')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'mobile')}
                                    alwaysShowLabel
                                    withoutFileManager
                                    accepts={acceptedExtensionsPageLogo}
                                />
                            </>
                        )}
                    </ImageFields>
                    <ToggleButtonContainer>
                        <DialogToggleButton
                            checked={!!applyForAll?.pageLogo}
                            toggleCallback={() => {
                                setApplyForAll({
                                    ...applyForAll,
                                    pageLogo: !applyForAll?.pageLogo
                                });
                                resetImageKeys('pageLogo');
                            }}
                            text={'Apply for all screens'}
                            tooltipText={applyForAll?.pageLogo ? 'apply_for_all_on' : 'apply_for_all_off'}
                        />
                    </ToggleButtonContainer>
                </ImageFieldContainer>
                {renderContentMarkersSection()}
            </div>
        );
    };

    const buildTableColumns = () => {
        return Object.entries({
            icon: 'Icon',
            name: 'Page Style Name',
            placed: 'Part of Page(s)',
            lastModified: 'Last Modified'
        }).map(([key, value], index) => {
            return (
                <HeaderTableCell
                    text={value}
                    key={`${index}_cell`}
                    columnSize={PageStyleTemplatesTableSizes[key as keyof typeof PageStyleTemplatesTableSizes]}
                />
            );
        });
    };

    const buildTableBody = () => {
        const pageStylesTemplateList = storeTemplates.pageStyle || [];
        return (
            <>
                {pageStylesTemplateList?.map((styleTemplate, index) => {
                    const dateString = generateDateStringForTables(styleTemplate.lastModified!);
                    const expanded = pageStyleTemplateToEdit?._id === styleTemplate._id;
                    const releasedStatus = styleTemplate?.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED;
                    const releasedTooltip = styleTemplate?.released
                        ? 'superadmin_page_style_templates_icon_released'
                        : 'superadmin_page_style_templates_icon_unreleased';

                    const pageStyleValues = expanded ? pageStyleTemplateToEdit : styleTemplate;
                    const isLast = pageStylesTemplateList.length - 1 === index;

                    return (
                        <>
                            <TableRowWrapper data-cy={`page-style-container-${index}`} key={styleTemplate._id}>
                                {/* TEMPLATE ICON TABLE CELL */}
                                <WidthTableCell {...PageStyleTemplatesTableSizes.icon}>
                                    <TemplateImageWrapper $background={pageStyleValues?.iconBackground}>
                                        <TemplateImage src={pageStyleValues?.icon} />
                                    </TemplateImageWrapper>
                                </WidthTableCell>

                                {/* TEMPLATE NAME TABLE CELL */}
                                <WidthTableCell {...PageStyleTemplatesTableSizes.name}>
                                    <TemplateStyleViewName
                                        onClick={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandPageStyle(styleTemplate?._id);
                                        }}
                                    >
                                        <TruncatedText>{pageStyleValues.values.name || EMPTY_WORD_STRING}</TruncatedText>
                                    </TemplateStyleViewName>
                                </WidthTableCell>

                                {/* TEMPLATE PLACED TABLE CELL */}
                                <WidthTableCell {...PageStyleTemplatesTableSizes.placed}>
                                    <Labels
                                        values={styleTemplate.placed || []}
                                        type={MoreInfoTypes.CONNECTED_TO}
                                        noOfLabels={isDesktop ? (isLargeDesktop ? 3 : 2) : isMobile ? 0 : 1}
                                        onClickLabel={(page: any) => {
                                            navigate(PageRoutes.SUPERADMIN_PAGE.replace(':page_id', page._id));
                                        }}
                                    />
                                </WidthTableCell>

                                {/* TEMPLATE LAST MODIFIED TABLE CELL */}
                                <WidthTableCell {...PageStyleTemplatesTableSizes.lastModified}>
                                    <StyleViewLastModified>
                                        <span>{dateString}</span>
                                    </StyleViewLastModified>
                                </WidthTableCell>

                                {/* TEMPLATE ACTIONS TABLE CELL */}
                                <WidthTableCell {...PageStyleTemplatesTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandPageStyle(styleTemplate?._id);
                                        }}
                                        open={expanded}
                                        onEdit={() => {
                                            if (showUnsaved && pageStyleTemplateToEdit?._id !== styleTemplate._id)
                                                return renderAlertUnsavedChanges();
                                            setOpenNewTemplate(true);
                                            pageStyleTemplateToEdit?._id !== styleTemplate._id && expandPageStyle(styleTemplate._id);
                                        }}
                                        onRemove={() => {
                                            showUnsaved ? renderAlertUnsavedChanges() : handleDelete(styleTemplate._id);
                                        }}
                                        tooltipTexts={{
                                            edit: 'superadmin_page_style_templates_icon_edit',
                                            delete: 'superadmin_page_style_templates_icon_delete',
                                            arrowClose: 'page_style_icon_hide',
                                            arrowOpen: 'page_style_icon_show',
                                            published: releasedTooltip
                                        }}
                                        publishedStatus={releasedStatus}
                                    />
                                </WidthTableCell>
                            </TableRowWrapper>

                            {/* EXTENDED PAGE STYLE TABLE ROW */}
                            {expanded && (
                                <>
                                    <TableRowWrapper style={{ background: 'transparent' }}>
                                        <WidthTableCell
                                            style={{ padding: 24, background: 'rgba(0,0,0,0.06)', maxWidth: 0 }}
                                            colSpan={5}
                                            $um={'px'}
                                        >
                                            {renderExpandedPageStyle()}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}
            </>
        );
    };

    const renderContentMarkersSection = () => {
        const onContentMarkersSave = (contentMarker: ContentMarker, contentMarkerKey: string) => {
            const newPageStyleToEdit = {
                ...pageStyleTemplateToEdit
            };
            newPageStyleToEdit.values.contentMarkers = {
                ...(newPageStyleToEdit.values.contentMarkers || {}),
                [contentMarkerKey]: contentMarker
            };
            setPageStyleTemplateToEdit(newPageStyleToEdit);
        };

        return (
            <ContentMarkers
                existingContentMarkers={pageStyleTemplateToEdit.values?.contentMarkers || {}}
                handleSave={onContentMarkersSave}
            />
        );
    };

    const renderUserSuggestions = () => {
        return <></>;
    };

    const renderNoPageStyles = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.pageStylesCard}
                title={'New Page Style'}
                subtitle={'Create a Page Style Template'}
                primaryButtonLabel={'Create Page Style Template'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    handleCreateClick();
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.page_styles);
                }}
                createResource
            />
        </NoResourcesContainer>
    );

    const isEmpty = !loading && !storeTemplates?.pageStyle?.length;
    const beError = error || imageError;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <>
            {beError && renderError(beError)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        withProfile
                        withoutSearch
                        loading={loading}
                        title="Page Style Templates"
                        withAddButton={!isEmpty}
                        addLabel={'Create Page Style Template'}
                        onAdd={() => {
                            showUnsaved ? renderAlertUnsavedChanges() : handleCreateClick();
                        }}
                    />

                    <GroupFieldsContainer withBottomMargin>
                        <GroupField
                            key="SYSTEM_TEMPLATES"
                            onClick={() => setActiveTab('SYSTEM_TEMPLATES')}
                            $active={activeTab === 'SYSTEM_TEMPLATES'}
                        >
                            System Templates
                        </GroupField>
                        <GroupField
                            key="USER_SUGGESTIONS"
                            onClick={() => {
                                setActiveTab('USER_SUGGESTIONS');
                            }}
                            $active={activeTab === 'USER_SUGGESTIONS'}
                            $disabled
                        >
                            User Suggestions
                        </GroupField>
                    </GroupFieldsContainer>
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Name'}
                            disabled={loading}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                        />
                    </SearchBarContainer>
                    {loading ? (
                        <Loader title="Page Styles" />
                    ) : activeTab === 'SYSTEM_TEMPLATES' ? (
                        isEmpty ? (
                            renderNoPageStyles()
                        ) : (
                            <GenericTable columns={buildTableColumns()} body={buildTableBody()} />
                        )
                    ) : (
                        renderUserSuggestions()
                    )}
                    {!loading && !isEmpty && (
                        <PageActionsWrapper>
                            <PageActionButton
                                id={PAGE_STYLE_ADD_BUTTON_ID}
                                onClick={() => {
                                    showUnsaved ? renderAlertUnsavedChanges() : handleCreateClick();
                                }}
                                label={'Create Page Style Template'}
                                type={'BLUE'}
                            />
                        </PageActionsWrapper>
                    )}
                    {pageStyleTemplateToEdit && (
                        <PageActions
                            onSave={() => handleSave()}
                            onCancel={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                            }}
                            disabled={{ save: !showUnsaved }}
                            loading={{ save: imageLoading }}
                        />
                    )}

                    <NewPageStyleTemplate
                        open={openNewTemplate}
                        pageStyleTemplate={pageStyleTemplateToEdit}
                        onClose={() => {
                            setOpenNewTemplate(false);
                        }}
                        onSave={async (template) => {
                            if (pageStyleTemplateToEdit) {
                                setPageStyleTemplateToEdit({ ...template });
                                setOpenNewTemplate(false);
                            } else {
                                createTemplate(template);
                                handleCancelClick();
                            }
                        }}
                        onRelease={(id) => {
                            releaseTemplate(id);
                            handleCancelClick();
                        }}
                        onRetract={(id) => {
                            retractTemplate(id);
                            handleCancelClick();
                        }}
                    />
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};
