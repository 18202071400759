import styled, { css, CSSProperties } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { withTheme } from '@material-ui/core';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { SelectWrapper } from '../Select/Select.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { devices, TruncatedText } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const CheckboxContainer = withTheme(styled(Link)<{
    $active: boolean;
    $center: boolean;
    $disabled?: boolean;
    $reversed?: boolean;
    $whiteTheme?: boolean;
    $capitalizeText?: boolean;
}>`
    height: 24px;
    display: flex;
    gap: 8px;
    max-width: 100%;
    cursor: pointer;
    align-items: center;
    color: ${(props) => (props.$whiteTheme ? props.theme.palette.text.light : props.theme.palette.text.main)};
    width: ${(props) => props.$center && 'fit-content'};
    margin: ${(props) => props.$center && '16px auto 0'};

    ${({ $disabled }) => $disabled && `cursor: not-allowed; opacity: 0.7; pointer-events: none;`}
    svg {
        padding: 0;
    }

    ${TruncatedText} {
        text-transform: ${(props) => (props.$capitalizeText ? 'capitalize' : 'unset')};
        ${fontSizesCSS.Caption1}
        margin: 0;
    }

    &:hover {
        text-decoration: none;
    }

    ${({ $reversed }) =>
        $reversed
            ? `div:not(:first-child) {
        display: flex;
     }`
            : `div:first-child {
        display: flex;
    }`}
`);
export const RadioLabel = styled.label`
    &:not(:first-child) {
        margin-left: 26px;
    }

    position: relative;
    width: 12px;
    height: 12px;
    border: 1px solid ${theme.palette.grey.main};
    border-radius: 50%;
    margin-right: 12px;
`;

export const RadioChecked = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        background-color: ${theme.palette.primary.main};
        opacity: 0;
    }
`;

export const RadioCheckBox = styled.input`
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + ${RadioChecked}:before {
        opacity: 1;
    }
`;
export const DoubleFieldSecondContainer = styled.div``;

export const LabelWrapper = styled.div`
    color: ${theme.palette.label.main};
    ${fontSizesCSS.Caption3};
    margin-bottom: 4px;
`;

export const DialogWrapper = styled.div<{
    $withIcon?: boolean;
    $empty?: boolean;
    $overflow?: boolean;
    $withoutActions?: boolean;
}>`
    border-radius: 4px;
    height: 100%;
    background-color: ${theme.palette.background.main};
    ${(props) => props.$empty && 'border-radius: 12px'};
    box-sizing: border-box;

    .MuiDialogActions-root {
        padding: 36px;
    }

    .MuiDialogTitle-root {
        display: flex;
        padding: 36px;
        justify-content: space-between;
        align-items: center;

        svg {
            padding: 0;
        }
    }

    .MuiDialogContent-root {
        position: relative;
        box-sizing: border-box;
        padding: 0 36px;

        // the combination of margin and padding aligns the scrollbar properly according to design
        margin-right: 24px;
        padding-right: 12px;

        ${({ $withoutActions }) => $withoutActions && 'margin-bottom:36px'};
        height: 100%;
        max-height: calc(
            90vh - ${({ $withoutActions }) => ($withoutActions ? '104px' : '212px')}
        ); // 104px title height, rest is actions height
        overflow: ${({ $overflow }) => ($overflow ? 'auto' : 'unset')};

        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-track {
            background: transparent;
        }
        ::-webkit-scrollbar-thumb {
            background: ${theme.palette.background.border};
            border-radius: 4px;
        }

        display: ${(props) => (props.$withIcon ? 'flex' : 'block')};
        ${(props) => props.$withIcon && 'align-items: center'};

        p {
            ${fontSizesCSS.Caption1}
        }
    }

    .MuiFormControl-root {
        .MuiInputBase-root {
            background: ${theme.palette.white.main};
        }
    }

    p.Mui-error {
        ${fontSizesCSS.Caption3}
    }

    ${SelectWrapper} {
        [class$='indicatorSeparator'] {
            display: none;
        }
    }
`;

export const StyledDialogScrollContent = styled(DialogContent)``;

const templateDialogStyles = css`
    div.MuiPaper-root {
        width: 1158px;
        max-height: 95vh;
    }

    .cc_select__control {
        width: auto;
    }

    ${DoubleFieldSecondContainer} {
        width: 333px;
    }

    ${devices.desktopS} {
        div.MuiPaper-root {
            width: 776px;
        }
    }

    ${devices.tablet} {
        div.MuiPaper-root {
            width: 432px;
        }
    }

    // the templates is handled differently, it has an own content wrapper with a virtualized list that handles the scrolling
    .MuiDialogContent-root {
        padding-right: 36px;
        margin-right: 0;
        overflow: hidden;
        max-height: calc(-72px + 90vh);
    }
`;
const audienceDialogStyles = css`
    div.MuiPaper-root {
        width: 1158px;
    }

    ${devices.desktopS} {
        div.MuiPaper-root {
            width: 776px;
        }

        .cc_select__control {
            width: 200px;
        }
    }

    ${devices.tablet} {
        div.MuiPaper-root {
            width: 432px;
        }

        .cc_select__control {
            width: 160px;
        }
    }
`;
const timePickerDialogStyle = css`
    div.MuiBackdrop-root {
        display: none;
    }

    div.MuiPaper-root {
        width: 325px;
    }
`;
const timePickerRangeDialogStyle = css`
    div.MuiBackdrop-root {
        display: none;
    }

    div.MuiPaper-root {
        width: 570px;
    }
`;

const datePickerRangeDialogStyle = css`
    div.MuiPaper-root {
        width: 570px;
    }
`;
const datePickerDialogStyle = css`
    div.MuiPaper-root {
        width: 325px;
    }
`;
const resourceDialogStyles = css`
    div.MuiPaper-root {
        max-width: 1158px;

        div.MuiDialogContent-root > div {
            flex-wrap: wrap;
            justify-content: center;
        }

        ${devices.desktopS} {
            width: 778px;
        }

        ${devices.tablet} {
            width: 528px;
        }
    }
`;

// this is subject to change after design is aligned
// could be that we don't need a special case for FM inside dialog
export const HelpIconWrapper = styled.div``;
const fileManagerDialogStyles = css`
    div.MuiPaper-root {
        width: 80vw;

        div.MuiDialogContent-root {
            overflow: unset;
            height: 70vh;
            box-sizing: border-box;
        }
    }

    ${HelpIconWrapper} {
        margin-right: 320px;
    }
`;

const pageStylesDialogStyle = css`
    div.MuiPaper-root {
        width: 80vw;
        overflow: auto;

        ${DialogWrapper} {
            height: auto;
        }
    }
`;

const displayConditionsDialogStyle = css`
    div.MuiDialogContent-root {
        overflow: unset;
        height: 70vh;
    }

    div.MuiPaper-root {
        width: 60vw;
    }
`;

const fileManagerPreviewStyles = css`
    .MuiDialog-container {
        overflow-y: unset;
    }

    div.MuiPaper-root {
        div.MuiDialogContent-root {
            overflow: unset;
            width: 1068px;
            height: 708px;

            ${devices.desktopS} {
                width: 888px;
                height: 606px;
            }

            ${devices.tablet} {
                width: 648px;
                height: 559px;
            }

            ${devices.mobileL} {
                width: 528px;
                height: 413px;
            }

            box-sizing: border-box;
        }
    }
`;

const moreInfoDialogStyle = css`
    div.MuiPaper-root {
        width: 608px;
    }
`;

export const StyledDialog = styled(Dialog)<{ empty?: boolean; type?: string }>`
    margin: auto;

    ${({ type }) =>
        type === 'LOCKED_ALERT' &&
        `z-index: 1500 !important;`} // this needs to stay important because you can't override it otherwise. It is set as a style attribute and from MUI theme you can only modify it for every dialog, which does not help
    .MuiDialog-container {
        overflow-y: scroll;
    }

    .MuiDialog-paper {
        overflow-y: unset;
    }

    .MuiDialog-paperFullWidth {
        overflow-y: visible;
    }

    .MuiDialogContent-root {
        ${(props) => props.empty && 'padding: unset'};
    }

    div.MuiDialogTitle-root {
        ${(props) => props.empty && 'padding: unset; margin: 0'};
    }

    ${(props) => {
        switch (props.type) {
            case 'AUDIENCE':
                return audienceDialogStyles;
            case 'TEMPLATE':
                return templateDialogStyles;
            case 'RESOURCE':
                return resourceDialogStyles;
            case 'FILE_MANAGER':
                return fileManagerDialogStyles;
            case 'FILE_PREVIEW':
                return fileManagerPreviewStyles;
            case 'PAGE_STYLE':
                return pageStylesDialogStyle;
            case 'DISPLAY_CONDITION':
                return displayConditionsDialogStyle;
            case 'TIME_PICKER':
                return timePickerDialogStyle;
            case 'MORE_INFO':
                return moreInfoDialogStyle;
            case 'TIME_PICKER_RANGE':
                return timePickerRangeDialogStyle;
            case 'DATE_PICKER_RANGE':
                return datePickerRangeDialogStyle;
            case 'DATE_PICKER':
                return datePickerDialogStyle;
            default:
                return `   
                div.MuiPaper-root {
                    width: 608px;
                }
                ${devices.tablet} {
                    div.MuiPaper-root {
                        width: 528px;
                    }
                }
            `;
        }
    }}
}

.MuiBackdrop-root {
    background-color: ${theme.palette.background.backdrop};
}
`;

export const ColorPickerContainer = styled.div<{ $open?: boolean }>`
    position: relative;

    div.chrome-picker {
        opacity: ${(props) => (props.$open ? '1' : '0')};
        z-index: ${zIndexesCSS.zIndexDropdown};
        transition: opacity 0.3s ease-in-out;
        pointer-events: ${(props) => (props.$open ? 'all' : 'none')};
        left: 178px;
        bottom: 24px;
        position: absolute;
    }

    div.chrome-picker > div:first-child {
        height: 25px;
    }
`;

export const ColorBox = styled.div<{ $height: string; $width: string; $color?: string }>`
    width: ${(props) => props.$width};
    height: ${(props) => props.$height};
    background: ${(props) => props.$color || theme.palette.background.imageFields};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px 0;
    border: 1px dashed ${theme.palette.background.contrast};
    border-radius: 3px;
`;

export const ColorPickerIconContainer = styled.div`
    background: ${theme.palette.white.main};
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 10px;

    svg {
        padding: 0;
    }
`;

export const FileFieldContainer = styled.div<{ $fullHeight: boolean; $mb?: string; $disabled?: boolean }>`
    height: ${(props) => (props.$fullHeight ? '100px' : 'fit-content')};
    margin-bottom: ${(props) => (props.$mb ? props.$mb : '20px')};

    &:hover {
        cursor: ${({ $disabled }) => ($disabled ? 'unset' : 'pointer')};
    }
`;

export const FileField = styled.div`
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        ${fontSizesCSS.Caption3};

        span {
            color: ${theme.palette.primary.main};
            cursor: pointer;
        }
    }

    input {
        display: none;
    }
`;

export const FileItem = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    background: ${theme.palette.background.contrast};
    padding: 6px 16px;
    border-radius: 4px;
    margin: 16px 0;
    ${fontSizesCSS.Caption3};

    div {
        margin-left: auto;
        color: ${theme.palette.primary.main};
        cursor: pointer;
    }
`;

export const DialogTitleActions = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const FileNameUnderSelectionWrapper = styled.div`
    ${fontSizesCSS.Body2}
    color: ${theme.palette.grey.dark}
`;

export const AcceptedWrapper = styled.div<{ $fullMargin: boolean }>`
    margin-top: ${(props) => (props.$fullMargin ? '72px' : '4px')};

    ${LabelWrapper} {
        opacity: 0.5;
        ${fontSizesCSS.Caption3};
    }
`;

export const ErrorLabel = styled.div<{ error: boolean }>`
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.error.main};
    opacity: ${(props) => (props.error ? '1' : '0')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const DialogRow = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 24px;
    gap: 24px;
    margin-bottom: 24px;
    width: fit-content;
`;
export const RadioText = styled.div``;
export const RadioButtonWrapper = styled.div<{ $disabled?: boolean }>`
    display: flex;
    align-items: center;
    font-weight: bold;

    &:hover {
        cursor: unset;

        ${RadioLabel} {
            cursor: pointer;
        }

        ${RadioText} {
            cursor: default;
        }
    }

    ${({ $disabled }) =>
        $disabled &&
        `opacity: 0.7; span {
        cursor: not-allowed;
    }`};
`;

export const DoubleFieldColumn = styled.div<{ $error?: boolean }>`
    &:not(:first-child) {
        margin-left: 100px;
    }

    a {
        margin-bottom: 6px;

        &:last-of-type {
            margin-bottom: 0;
        }

        ${({ $error }) => $error && `svg { path { fill: ${theme.palette.error.main} } }`}
    }
`;

export const RowTitle = styled.div`
    margin-bottom: 6px;
    ${fontSizesCSS.Caption3}
    color: ${theme.palette.grey.main};
    opacity: 0.7;
`;

export const DialogDoubleFieldWrapper = styled.div<{
    $width1: number | string;
    $percentage?: boolean;
    modifyChildStyle?: boolean;
}>`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    div:nth-child(1) {
        width: ${({ $width1, modifyChildStyle, $percentage }) => (modifyChildStyle ? $width1 + ($percentage ? '%' : 'px') : '')};
    }

    ${SelectWrapper} {
        width: 140px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        [class$='IndicatorsContainer'] {
            flex: none;
        }
        [class$='ValueContainer'] {
            margin-bottom: 4px;
        }
        [class$='indicatorSeparator'] {
            display: none;
        }
    }

    ${DoubleFieldSecondContainer} {
        width: 160px;

        div {
            width: 100%;
        }
    }
`;
export const FileManagerAdornmentWrapper = styled.span`
    cursor: pointer;

    svg {
        padding-right: 12px;
    }
`;
export const TextInput = withTheme(styled(TextField)`
    margin-top: ${(props: any) => (props.$withTopMargin ? '8px' : '0')};
    width: 100%;
    border-radius: 4px;
    transform: unset;

    input {
        height: 34px;
        padding: 0 12px;
        background-color: ${(props) => props.theme.palette.white.main};
    }

    label {
        z-index: 1;
        position: relative;
        ${fontSizesCSS.Caption3};
        color: ${theme.palette.label.main};
    }
    textarea {
        padding: 5px 12px;
        pointer-events: none;
        ${fontSizesCSS.Body1};
        background-color: transparent;

        ::-webkit-scrollbar {
            width: 4px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
            margin: 4px 0;
        }

        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 8px;
        }

        &:focus {
            pointer-events: auto;
            ::-webkit-scrollbar-thumb {
                background: ${theme.palette.background.border};
            }
        }
    }
    .MuiInputBase-multiline {
        padding: 0;
    }

    .MuiFormHelperText-root {
        position: absolute;
        bottom: 0;
    }

    .MuiInputBase-root {
        margin-top: ${(props: any) => (props.noLabel ? '0' : '4px')};
        margin-bottom: ${(props: any) => (props.noError ? '0' : '20px')};
    }

    .MuiInputLabel-shrink {
        transform: unset;
    }

    .MuiInput-root {
        border: 1px solid ${(props) => (props.error ? props.theme.palette.error.main : 'rgba(0, 0, 0, 0.2)')};
        border-radius: 4px;

        input {
            border-radius: 4px;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px white inset;
            }
        }
    }

    .MuiInputBase-input.Mui-disabled {
        background-color: ${(props: any) =>
            props.whiteWhileDisabled ? props.theme.palette.white.main : props.theme.palette.background.pressed};
    }

    .MuiInputBase-adornedEnd {
        position: relative;
        svg {
            width: 16px;
            height: 16px;
        }

        input {
            padding-right: 0;
        }
    }

    .MuiInput-underline:after {
        content: none;
    }

    .MuiInput-underline:before {
        content: none;
    }

    p.MuiFormHelperText-root {
        ${fontSizesCSS.Caption3}
        white-space: nowrap;

        span {
            color: ${(props) => props.theme.palette.text.main};
            opacity: 0.7;
        }
    }

    ${FileManagerAdornmentWrapper} {
        svg {
            width: 20px;
            height: 20px;

            path {
                opacity: 0.7;
            }

            &:hover {
                path {
                    opacity: 1;
                }
            }
        }
    }
`);

export const InputLabelWithIconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        height: 14px;
    }
`;

export const InputToolTip = styled.div`
    width: max-content;
    height: 26px;
    position: absolute;
    background-color: ${theme.palette.text.main};
    color: ${theme.palette.white.main};
    bottom: -50px;
    z-index: 1;
    padding: 10px 12px;
    text-align: center;
    border-radius: 4px;
    display: none;
`;

export const LabelIcon = styled.div`
    position: relative;
    cursor: pointer;
    display: inline;
    margin-left: 5px;

    &:hover ${InputToolTip} {
        display: block;
    }

    svg {
        vertical-align: middle;
    }
`;

export const CloseIconWrapper = styled.div`
    float: right;
    cursor: pointer;
`;

export const MessageIconWrapper = styled.div`
    min-width: 40px;
    height: 36px;
    margin-right: 16px;

    svg {
        width: 100%;
        height: 100%;
        padding: 0;
    }
`;

export const ButtonStyle: CSSProperties = {
    width: '120px',
    height: '36px',
    margin: '0 0 0 20px',
    padding: '6px 16px',
    borderRadius: '4px'
};

export const SubtitleWrapper = styled.span`
    font-weight: bold;
    display: block;

    // in case of the subtitle this should not happen, since it should wrap in a new line
    // this is a safety in case of some unforeseen values gets passed, the ui won't break
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ImagePreviewIcon = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    svg {
        color: white;
        font-size: 100px;

        text-align: center;
        background-color: white;
        border-radius: 50%;
        padding: 10px 10px;

        &:hover {
            cursor: pointer;
        }
    }
`;

export const ContainerImagePreview = styled.div<{ width: number; height: number; um: string; isVideo: boolean }>`
    position: relative;
    width: ${({ width, um }) => `${width}${um}`};
    height: ${({ height, um }) => `${height}${um}`};
    max-width: 400px;
    border-radius: 4px;

    &:hover ${ImagePreviewIcon} {
        opacity: 1;
    }

    &:hover {
        img {
            filter: brightness(40%);
        }
    }

    img {
        display: block;
        transition: 0.3s ease;
        border-radius: 4px;
    }

    //checkered background
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%);
    background-size: 8px 8px; /* Must be a square */
    background-position: 0 0, 4px 0, 4px -4px, 0px 4px; /* Must be half of one side of the square */

    ${({ isVideo }) =>
        isVideo &&
        css`
            background: ${theme.palette.grey.light};
        `};
`;

export const ImagePreview = styled.img.attrs(({ src }) => ({ src }))`
    object-fit: cover;
    height: ${(props) => props.height + 'px'};
    width: ${(props) => props.width + 'px'};
`;

export const DialogTitle = styled(MuiDialogTitle)`
    gap: 16px;

    h2 {
        font-family: 'Inter-Medium';
        ${fontSizesCSS.Title3};
    }
`;

export const DialogTitleContentWrapper = styled.div`
    max-width: calc(100% - 68px);
`;

export const ToggleButtonContainer = styled.div`
    width: fit-content;
`;

export const ToggleContainer = styled.div<{ $disabled?: boolean }>`
    display: flex;
    align-items: center;
    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.7;
            cursor: not-allowed;

            ${ToggleSlider} {
                cursor: not-allowed;
            }
        `};
`;

export const ToggleText = styled.div`
    margin-left: 12px;
    ${fontSizesCSS.Body1}
`;

export const ToggleLabel = styled.label`
    width: 36px;
    height: 16px;
    position: relative;
    display: inline-block;
`;

export const ToggleSlider = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.palette.grey.light};
    transition: 0.4s;
    border-radius: 12px;
    cursor: pointer;

    &:before {
        content: '';
        background-color: ${theme.palette.white.main};
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: absolute;
        transition: 0.4s;
        left: 1px;
        top: 1px;
    }
`;
export const ToggleCheckBox = styled.input`
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + ${ToggleSlider} {
        background-color: ${theme.palette.primary.main};
    }

    &:checked + ${ToggleSlider}:before {
        transform: translateX(20px);
    }
`;

export const RemoveColorIconContainer = styled.div`
    margin-top: 16px;
    cursor: pointer;
    ${fontSizesCSS.Caption4};

    svg {
        width: 16px;
        height: 16px;
    }
`;

export const ColorFieldLabel = styled.div<{ $addOpacity?: boolean }>`
    min-height: 16px;
    ${fontSizesCSS.Caption3};
    color: ${theme.palette.text.main};
    ${({ $addOpacity }) => $addOpacity && 'opacity: 0.7'};
`;

export const WarningsWrapper = styled.div`
    display: flex;
    gap: 0;

    > div:not(:last-child) {
        margin-right: -7px;
    }
`;
