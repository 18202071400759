import React, { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import { TargetGroup } from '../../../types/TargetGroup';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../../hooks/redux';
import { validator } from '../../../utils/fnValidator';

import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';

export type NewTargetGroupProps = {
    open: boolean;
    targetGroup?: TargetGroup;
    duplicate?: boolean;
    onSave: (arg: TargetGroup) => void;
    onClose?: () => void;
};

const NewTargetGroup: FC<NewTargetGroupProps> = ({ open, targetGroup, duplicate, onSave, onClose }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [errors, setErrors] = useState<{ name?: string }>({});

    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const handleSaveClick = () => {
        if (!validateTargetGroup()) return;

        const newTargetGroup: TargetGroup = {
            _id: targetGroup?._id || '',
            name: name,
            active: false,
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            lastModified: targetGroup?.lastModified
        };
        onSave(newTargetGroup);
        handleCloseClick();
    };
    const handleCloseClick = () => {
        setName('');
        setErrors({});
        onClose?.();
    };

    const validateTargetGroup = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true }, name);
        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (targetGroup) {
            setName(targetGroup.name);
        }
    }, [targetGroup]);

    const saveButton: DialogButton = {
        label: !targetGroup ? 'Create' : 'Save',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };
    if (!isOpen) return null;
    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={targetGroup ? (duplicate ? 'Duplicate Group' : 'Edit Group') : 'Create Group'}
            onClose={() => handleCloseClick()}
            actionButtons={[cancelButton, saveButton]}
            dataCy={targetGroup ? 'edit-target-group-dialog' : 'new-target-group-dialog'}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.target_groups }}
        >
            <DialogTextField
                value={name}
                onChange={(e: any) => {
                    setName(e.target.value);
                }}
                error={errors.name}
                label={'Group Name'}
                dataCy={'group-name-input'}
            />
        </GenericDialog>
    );
};

export default NewTargetGroup;
