import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();

export const DialogTitle = styled.div``;

export const StyledText = styled.div`
    ${fontSizesCSS.Caption3}

    span {
        color: ${theme.palette.primary.main};
    }
`;

export const SelectLanguageDropdownLabel = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        display: inline-block;
    }
`;
