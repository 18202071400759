import { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import { useAppSelector, useAppDispatch as useDispatch } from '../../hooks/redux';
import { fetchContentMarkers, PageStylesState } from '../../redux/slices/pageStylesSlice';
import { ContentMarkerField, ContentMarkerName, ContentMarkersContainer, ContentMarkersHeader } from './PageStyle.css';
import icons from '../../style';
import { renderLockedWarningAlert } from '../../utils/fnLockingSystem';
import ContentMarkersDialog, { ContentMarker } from './Dialogs/ContentMarkersDialog';

export type ContentMarkersProps = {
    existingContentMarkers: any;
    handleSave: (contentMarker: ContentMarker, contentMarkerKey: string) => void;
    locked?: boolean;
    lockedBy?: string;
};

export const ContentMarkers: FC<ContentMarkersProps> = ({ existingContentMarkers, locked, lockedBy, handleSave }) => {
    const { contentMarkers }: PageStylesState = useAppSelector((state) => state.pageStyles);
    const [contentMarkersToShow, setContentMarkersToShow] = useState<any>({});
    const [openContentMarkersDialog, setOpenContentMarkersDialog] = useState<boolean>(false);
    const [contentMarkerToEdit, setContentMarkerToEdit] = useState<string>('');

    const dispatch = useDispatch();

    const loadContentMarkers = async () => {
        return await dispatch(fetchContentMarkers()).unwrap();
    };

    useEffect(() => {
        if (!contentMarkers) loadContentMarkers();
    }, []);

    useEffect(() => {
        setContentMarkersToShow(contentMarkers?.availableContentMarkers || {});
    }, [contentMarkers]);

    return (
        <>
            <ContentMarkersContainer>
                <ContentMarkersHeader>Content Markers</ContentMarkersHeader>
                {Object.keys(contentMarkersToShow).map((key, index) => {
                    return (
                        <ContentMarkerField
                            $contentMarker={existingContentMarkers?.[key]}
                            key={'content_marker_' + index}
                            onClick={(evt: any) => {
                                evt.preventDefault();
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                setOpenContentMarkersDialog(true);
                                setContentMarkerToEdit(key);
                            }}
                        >
                            {existingContentMarkers?.[key] ? (
                                <ContentMarkerName>
                                    <span>{contentMarkersToShow[key]}</span>
                                    <SVGInline src={icons.editLightIcon} />
                                </ContentMarkerName>
                            ) : (
                                <SVGInline src={icons.addIconWhite} />
                            )}
                        </ContentMarkerField>
                    );
                })}
            </ContentMarkersContainer>

            <ContentMarkersDialog
                open={openContentMarkersDialog}
                onSave={(contentMarker) => {
                    handleSave(contentMarker, contentMarkerToEdit);
                    setOpenContentMarkersDialog(false);
                }}
                onClose={() => {
                    setOpenContentMarkersDialog(false);
                }}
                title={(contentMarkersToShow?.[contentMarkerToEdit] || '') + ' Content Marker'}
                contentMarker={existingContentMarkers?.[contentMarkerToEdit]}
            />
        </>
    );
};
