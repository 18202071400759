import conditionsCard from './conditionsCard.png';
import itemsCard from './itemsCard.png';
import itemsNewCard from './itemsNewCard.png';
import menusCard from './menusCard.png';
import modulesCard from './modulesCard.png';
import modulesNewCard from './modulesNewCard.png';
import pagesCard from './pagesCard.png';
import pagesNewCard from './pagesNewCard.png';
import targetGroupsCard from './targetGroupsCard.png';
import fileManagerCard from './fileManagerCard.png';
import pageStylesCard from './pageStylesCard.png';
import aggregateCard from './aggregateCard.png';
import analyzeCard from './analyzeCard.png';
import optimizeCard from './optimizeCard.png';
import monetizeCard from './monetizeCard.png';
import personalizeCard from './personalizeCard.png';

export const resourceCardImages = {
    conditionsCard,
    itemsCard,
    itemsNewCard,
    menusCard,
    modulesCard,
    modulesNewCard,
    pagesCard,
    pagesNewCard,
    targetGroupsCard,
    fileManagerCard,
    pageStylesCard,
    analyzeCard,
    aggregateCard,
    optimizeCard,
    monetizeCard,
    personalizeCard
};
