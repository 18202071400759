import EPG from './epg.png';
import TVMovieDetails from './tv_movie_details.png';
import TVSeriesDetails from './tv_series_details.png';
import TVPlayer from './tv_player.png';
import VODMovieDetails from './vod_movie_details.png';
import VODSeriesDetails from './vod_series_details.png';
import VODPlayer from './vod_player.png';
import ProfileManagement from './profile_management.png';
import ChannelDetails from './channel_details.png';
import WatchNext from './watch_next.png';
import { PageIntents } from '../../../types/Page';

export const IntentsPlaceHolders = {
    [PageIntents.EPG]: EPG,
    [PageIntents.TV_MOVIE_DETAILS]: TVMovieDetails,
    [PageIntents.TV_SERIES_DETAILS]: TVSeriesDetails,
    [PageIntents.TV_PLAYER]: TVPlayer,
    [PageIntents.VOD_MOVIE_DETAILS]: VODMovieDetails,
    [PageIntents.VOD_PLAYER]: VODPlayer,
    [PageIntents.VOD_SERIES_DETAILS]: VODSeriesDetails,
    [PageIntents.PROFILE_MANAGEMENT]: ProfileManagement,
    [PageIntents.CHANNEL_DETAILS]: ChannelDetails,
    [PageIntents.WATCH_NEXT]: WatchNext
};
