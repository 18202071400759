import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { appendQueryParams } from '../fnUrl';
import { ConfigServiceResponse, FetchResponse } from '../../types/ApiTypes';
import { API_ERROR_STATUSES, SECRET_WEBUI_ACCESS_KEY } from '../Globals';
import { entityDataParser, permissionsAndPaginationParser } from '../parsers';
import { Project } from '../../types/Project';
import { Tenant } from '../../types/Tenant';
import { User } from '../../types/User';
import { UserGroup } from '../../types/UserGroup';
import { Language } from '../../types/Language';
import { Page } from '../../types/Page';
import { Module } from '../../types/Module';
import { Item } from '../../types/Item';
import { Menu } from '../../types/Menu';
import { TargetGroup, TargetGroupAbTestingStatus } from '../../types/TargetGroup';
import { Audience } from '../../types/Audience';
import { Setting, SettingSectionType } from '../../types/Setting';
import { DisplayCondition } from '../../types/DisplayCondition';
import { PageStyle } from '../../types/PageStyle';
import { Template } from '../../types/Template';
import { DEVICE_CATEGORIES } from '../../components/Analytics/AnalyticsConstants';
import { Application } from '../../components/Applications/ApplicationDialog';
import { FilterType } from '../../components/FileManager/FileManager';
import { ObjectFilter, objectFilterToQueryParam } from '../fnFilter';
import { FailedDeletionMessages, ObjectType } from '../../types/Object';
import { AbTestingGroup } from '../../types/AbTestingGroup';
import { CCSettings } from '../../types/CCSettings';
import { FeatureFlag } from '../../types/FeatureFlags';
import { ContentAsset } from '../../types/Asset';

class ConfigServiceAPI {
    private static _apiInstance: ConfigServiceAPI;

    public baseUrl: string;

    private constructor() {
        this.baseUrl = (process.env.REACT_APP_BACKEND_URL || `${window.location.protocol}//${window.location.host}`) + '/api';
    }

    public static get ApiInstance() {
        return this._apiInstance || (this._apiInstance = new this());
    }

    private fetch = async <T>(url: string, options: AxiosRequestConfig, dataParser: any = null) => {
        try {
            const response = await axios(url, options);
            let { data: json, permissions, totalResults } = permissionsAndPaginationParser(response);

            if (dataParser) {
                json = dataParser(json);
            }

            return {
                error: null,
                code: null,
                status: response.status,
                response: json as T,
                permissions,
                totalResults
            };
        } catch (ex: any) {
            let error = ex.response?.data?.error?.message || ex.response?.data?.errors || ex.message;
            let code = ex.response?.data?.error?.code;
            let errorData = ex.response?.data?.data;
            let status = parseInt(ex.response?.status || ex.status || 500);
            if (!error && API_ERROR_STATUSES.includes(status)) {
                error = 'Unknown error';
            }
            const errorObj: any = {
                message: error,
                code
            };
            if (errorData) {
                errorObj.data = errorData;
            }
            return {
                error: errorObj,
                status: ex.response?.status,
                response: null
            };
        }
    };

    private execute = async <T>(
        requestBody: string | FormData | null,
        dataParser: any,
        url: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        query: { [key: string]: boolean | number | string | undefined } = {},
        headers = {
            'Content-Type': 'application/json',
            'x-compression': 'true',
            'x-webui-only-access-secret': SECRET_WEBUI_ACCESS_KEY
        }
    ): Promise<FetchResponse<T>> => {
        const options: AxiosRequestConfig = {
            withCredentials: true,
            method,
            headers,
            data: requestBody !== null ? requestBody : undefined
        };
        const fetchResponse = await this.fetch<T>(appendQueryParams(url, query || {}), options, dataParser);

        return fetchResponse;
    };

    /* AUTH */
    public login = async (email: string, password: string, rememberMe = false): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/login`;
        const body = JSON.stringify({ email, password, rememberMe });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'POST', { info: true });
        return response;
    };

    public logout = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/logout`;

        const response = await this.execute<ConfigServiceResponse>(null, null, url, 'DELETE');
        return response;
    };

    public forgotPassword = async (email: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/forgot-password`;
        const body = JSON.stringify({ email });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'PUT');
        return response;
    };

    public resetPassword = async (token: string, newPassword: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/reset-password`;
        const body = JSON.stringify({ token, newPassword });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'PUT');
        return response;
    };

    public check2FADisabled = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/check2fa`;

        const response = await this.execute<ConfigServiceResponse>(null, null, url, 'GET');
        return response;
    };

    public loadPhonePrefixes = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/phone-prefixes`;

        const response = await this.execute<ConfigServiceResponse>(null, null, url, 'GET');
        return response;
    };

    public sendOTP = async (phoneNumber: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/send-otp`;
        const body = JSON.stringify({ phoneNumber });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'POST');
        return response;
    };

    public resendOTP = async (phoneNumber: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/resend-otp`;
        const body = JSON.stringify({ phoneNumber });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'POST');
        return response;
    };

    public verifyOTP = async (
        phoneNumber: string,
        otp: string,
        token: string,
        userId?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/verify-otp`;
        const body: any = { phoneNumber, otp };
        if (userId) body.userId = userId;
        if (token) body.token = token;

        const response = await this.execute<ConfigServiceResponse>(JSON.stringify(body), null, url, 'POST');
        return response;
    };

    public verifyOTPLogin = async (
        phoneNumber: string,
        otp: string,
        email: string,
        password: string,
        rememberMe = false
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/verify-otp-login`;
        const body = JSON.stringify({ phoneNumber, otp, email, password, rememberMe });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'POST');
        return response;
    };

    public activateAccount = async (
        token: string,
        password: string,
        phoneNumber: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/auth/activate`;
        const body = JSON.stringify({ token, password, phoneNumber });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'PUT');
        return response;
    };

    /* PROJECTS */
    public getAllProjects = async (
        addPermissions?: boolean,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            orderBy,
            searchTerm
        });
        return response;
    };

    public getAllDisabledProjects = async (orderBy?: string, searchTerm?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/disabled`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            orderBy,
            searchTerm
        });
        return response;
    };

    public getProjectDashboard = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/dashboard/${id}`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            level: 1
        });
        return response;
    };

    public createProject = async (project: Project): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects`;
        const body = JSON.stringify(project);

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public copyProject = async (projectId: string, tenantId: string, projectKey: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/copy`;
        const body = JSON.stringify({ projectId, tenantId, key: projectKey });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public duplicateProject = async (
        projectId: string,
        tenantId: string,
        projectKey: string,
        projectName: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/duplicate`;
        const body = JSON.stringify({ projectId, tenantId, key: projectKey, name: projectName });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateProject = async (project: Project): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects`;
        const body = JSON.stringify(project);

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public updateProjectMembers = async (projectId: string, members: string[]): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/updateMembers`;
        const body = JSON.stringify({ projectId, members });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public disableProject = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/disable`;
        const body = JSON.stringify({ _id: id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public enableProject = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/enable`;
        const body = JSON.stringify({ _id: id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteProject = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/`;
        const body = JSON.stringify({ _id: id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };
    public createToken = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/accessToken`;
        const body = JSON.stringify({ _id: id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public getToken = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/accessToken`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { id });

        // temporarily, a project can have 2 tokens so return only one
        const token = _.get(response, ['response', 'token'], '');
        if (token.includes('||')) {
            _.set(response, ['response', 'token'], token.split('||')[1]);
        }
        return response;
    };

    public getSecretPasscode = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/secretPasscode`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { id });
        return response;
    };

    public getPreviewToken = async (id: string, service: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/previewToken/${service}/${id}`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public createPreviewToken = async (id: string, service: string, token: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/projects/previewToken`;
        const body = JSON.stringify({
            _id: id,
            token,
            service
        });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /* USERS */
    public getAllUsers = async (
        addPermissions?: boolean,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            orderBy,
            searchTerm
        });
        return response;
    };

    public createUser = async (user: User): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users`;
        const body = JSON.stringify(user);

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateUser = async (user: User): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users`;
        const body = JSON.stringify(user);

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteUser = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users`;
        const body = JSON.stringify({ id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };
    public inviteUser = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/resend-email`;
        const body = JSON.stringify({ id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };
    public enableUser = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/enable`;
        const body = JSON.stringify({ id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };
    public disableUser = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/disable`;
        const body = JSON.stringify({ id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };
    public getUserInfo = async (addPermissions?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/userInfo`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            permissions: addPermissions
        });
        return response;
    };
    public changeUserPassword = async (oldPassword: string, newPassword: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/change-password`;
        const body = JSON.stringify({ oldPassword, newPassword });

        const response = await this.execute<ConfigServiceResponse>(body, null, url, 'PUT');
        return response;
    };
    /*TENANTS */

    public getAllTenants = async (
        addPermissions?: boolean,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            orderBy,
            searchTerm
        });
        return response;
    };

    public getAllDisabledTenants = async (orderBy?: string, searchTerm?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants/disabled`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            orderBy,
            searchTerm
        });
        return response;
    };

    public createTenant = async (tenant: Tenant): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants`;
        const body = JSON.stringify(tenant);

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateTenant = async (tenant: Tenant): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants`;
        const body = JSON.stringify(tenant);

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public disableTenant = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants/disable`;
        const body = JSON.stringify({ id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public enableTenant = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants/enable`;
        const body = JSON.stringify({ id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };
    public deleteTenant = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/tenants/`;
        const body = JSON.stringify({ id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };
    /*GROUPS */

    public getAllGroups = async (
        addPermissions?: boolean,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/userGroups`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            orderBy,
            searchTerm
        });
        return response;
    };
    public createGroup = async (group: UserGroup, userIds?: string[]): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/userGroups`;
        const body = JSON.stringify({ ...group, userIds });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public deleteGroup = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/userGroups`;
        const body = JSON.stringify({ id });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };
    public updateGroup = async (group: UserGroup, userIds: string[]): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/userGroups`;
        const body = JSON.stringify({ ...group, userIds });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /*ROLES */
    public getAllUserRoles = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/roles`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    /*PERMISSIONS */
    public getAllPermissions = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/users/permissions`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    /*LANGUAGES*/
    public getAllLanguageCodes = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/codes`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getAllTranslations = async (projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/${projectId}/allTranslations`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getAllCountryCodes = async (forAssetManager?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/countrycodes`;
        const response = await this.execute<ConfigServiceResponse>(
            null,
            entityDataParser,
            url,
            'GET',
            forAssetManager ? { assetManager: true } : {}
        );
        return response;
    };

    public getAllLanguages = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm
        });
        return response;
    };

    public getAllLockedLanguages = async (projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/locked`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            projectId
        });
        return response;
    };

    public getLanguage = async (id: string, addTranslations?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/${id}`;
        const query: any = {
            full: true
        };
        if (addTranslations) {
            query.addTranslations = true;
        }
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', query);

        return response;
    };

    public getTranslations = async (
        languageId: string,
        pageNumber?: number,
        pageSize?: number,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter,
        withDescriptions?: boolean
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/${languageId}/translations`;
        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};
        const query: any = {
            pageNumber,
            pageSize,
            searchTerm,
            orderBy,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        };
        if (withDescriptions) {
            query.descriptions = true;
        }

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', query);
        return response;
    };

    public createLanguage = async (language: Language): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages`;
        const body = JSON.stringify(language);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateLanguageList = async (languages: any[]): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/list`;
        const body = JSON.stringify({ languages });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public updateTranslations = async (
        tenantId: string,
        projectId: string,
        key: string,
        values: any,
        description?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages/translations`;
        const body: any = { projectId, tenantId, key, values };
        if (description) {
            body.description = description;
        }
        const response = await this.execute<ConfigServiceResponse>(JSON.stringify(body), entityDataParser, url, 'PUT');
        return response;
    };

    public updateLanguage = async (
        language: Language,
        translationDescriptions?: Record<string, string>
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages`;
        const body = JSON.stringify(translationDescriptions ? { ...language, translationDescriptions } : language);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteLanguage = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/languages`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /*PAGES*/

    public getAllPages = async (
        addPermissions?: boolean,
        projectId?: string,
        nestLevel?: number,
        pageSize?: number,
        pageNumber?: number,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages`;

        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            level: nestLevel,
            pageSize,
            pageNumber,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });

        return response;
    };

    public getAllPageIntents = async (projectId?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages/intents`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId });

        return response;
    };

    public getPageById = async (id: string, filter?: ObjectFilter): Promise<FetchResponse<ConfigServiceResponse>> => {
        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};

        const url = `${this.baseUrl}/objects/pages/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });

        return response;
    };

    public createPage = async (page: Page, addObjectToGroup: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages`;
        const body = JSON.stringify(page);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST', { addObjectToGroup });
        return response;
    };

    public updatePage = async (
        page: Page,
        shouldRepublish: boolean,
        addObjectToGroup: boolean
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages`;
        const body = JSON.stringify({ ...page });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', {
            shouldRepublish,
            addObjectToGroup
        });
        return response;
    };

    public deletePage = async (_id: string, withChildren?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages`;
        const body = JSON.stringify({ _id });

        const response = await this.execute<ConfigServiceResponse>(
            body,
            entityDataParser,
            url,
            'DELETE',
            withChildren ? { withChildren } : undefined
        );
        return response;
    };

    public publishPage = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages/publish`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public abortPublishPage = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages/publish/abort`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public deletePageList = async (pages: string[], withChildren?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pages/list`;
        const body = JSON.stringify({ pages });
        const response = await this.execute<ConfigServiceResponse>(
            body,
            entityDataParser,
            url,
            'DELETE',
            withChildren ? { withChildren } : undefined
        );
        return response;
    };

    /*MENUS*/

    public getAllMenus = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus`;
        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });

        return response;
    };

    public getMenuTypes = async (projectId?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus/types`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            projectId
        });

        return response;
    };

    public getMenuById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true
        });

        return response;
    };

    public createMenu = async (menu: Menu, addObjectToGroup: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus`;
        const body = JSON.stringify(menu);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST', { addObjectToGroup });
        return response;
    };

    public updateMenu = async (
        menu: Menu,
        shouldUnlockAfterSave: boolean = false,
        shouldRepublish: boolean = false,
        addObjectToGroup: boolean = false
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        let url = `${this.baseUrl}/objects/menus`;

        const body = JSON.stringify({ ...menu });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', {
            unlock: shouldUnlockAfterSave,
            shouldRepublish,
            addObjectToGroup
        });
        return response;
    };

    public deleteMenu = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public publishMenu = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus/publish`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public abortPublishMenu = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/menus/publish/abort`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    /*SETTINGS*/

    public getAllSettings = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings`;
        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });

        return response;
    };

    public getSettingById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true
        });

        return response;
    };

    public getSettingType = async (tenantId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/settingType`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            tenantId
        });
        return response;
    };

    public getSettingsUMTypes = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/settingsUMTypes`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getSettingsTitles = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/settingsTitles`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public createSetting = async (setting: Setting, addObjectToGroup: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings`;
        const body = JSON.stringify(setting);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST', { addObjectToGroup });
        return response;
    };

    public updateSetting = async (
        setting: Setting,
        shouldUnlockAfterSave: boolean = false,
        shouldRepublish: boolean = false,
        addObjectToGroup: boolean = false
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings`;
        const body = JSON.stringify(setting);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', {
            unlock: shouldUnlockAfterSave,
            shouldRepublish,
            addObjectToGroup
        });
        return response;
    };

    public deleteSetting = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public publishSetting = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/publish`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public abortPublishSetting = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/publish/abort`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public propagateSetting = async (
        _id: string,
        settingKey: SettingSectionType,
        propagateTo: string[],
        shouldRepublish?: boolean
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/settings/propagate`;
        const body = JSON.stringify({ _id, settingKey, propagateTo, shouldRepublish });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    /*PAGE_STYLES*/

    public getAllPageStyles = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm
        });

        return response;
    };

    public getPageStyleById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true
        });

        return response;
    };

    public getAllContentMarkers = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles/contentMarkers`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public createPageStyle = async (pageStyle: PageStyle): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles`;
        const body = JSON.stringify(pageStyle);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updatePageStyle = async (
        pageStyle: PageStyle,
        shouldUnlockAfterSave: boolean = false
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles`;
        const body = JSON.stringify(pageStyle);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', { unlock: shouldUnlockAfterSave });
        return response;
    };

    public deletePageStyle = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public changePageStyleDefault = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/pageStyles/updateDefault`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /*TARGET GROUPS*/

    public getAllTargetGroups = async (addPermissions?: boolean, projectId?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/targetGroups`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId
        });
        return response;
    };

    public getTargetGroupById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/targetGroups/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');

        return response;
    };

    public createTargetGroup = async (targetGroup: TargetGroup): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/targetGroups`;
        const body = JSON.stringify(targetGroup);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateTargetGroup = async (
        targetGroup: TargetGroup,
        objectsToUpdate?: { id: string; shouldRepublish: boolean }[]
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/targetGroups`;

        const body: any = { ...targetGroup };
        if (objectsToUpdate) {
            body.objectsToUpdate = objectsToUpdate;
        }

        const response = await this.execute<ConfigServiceResponse>(JSON.stringify(body), entityDataParser, url, 'PUT');
        return response;
    };

    public changeAbTestingStatus = async (_id: string, abTestingStatus: TargetGroupAbTestingStatus) => {
        const url = `${this.baseUrl}/targetGroups/abTestingStatus`;
        const body = JSON.stringify({ _id, abTestingStatus });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteTargetGroup = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/targetGroups`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /* AB TESTING GROUPS */
    public getAbTestingGroups = async (projectId?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/abTestingGroups`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { full: true, projectId });
        return response;
    };

    public getAbTestingGroupsByTargetGroupId = async (
        targetGroupId: string,
        createDefault?: boolean
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/abTestingGroups/targetGroup/${targetGroupId}`;
        const response = await this.execute<ConfigServiceResponse>(
            null,
            entityDataParser,
            url,
            'GET',
            createDefault ? { createDefault: true } : undefined
        );
        return response;
    };

    public createAbTestingGroupsForTargetGroup = async (
        targetGroupId: string,
        abTestingGroups: { name: string; percentage: number; _id?: string }[]
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/abTestingGroups/targetGroup/${targetGroupId}/list`;
        const body = JSON.stringify({ abTestingGroups });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public createAbTestingGroup = async (abTestingGroup: AbTestingGroup): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/abTestingGroups`;
        const body = JSON.stringify(abTestingGroup);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateAbTestingGroup = async (abTestingGroup: AbTestingGroup): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/abTestingGroups`;
        const body = JSON.stringify(abTestingGroup);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteAbTestingGroup = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/abTestingGroups`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /*AUDIENCES*/

    public getAudiencesByTargetGroupId = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences/group/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { full: true });
        return response;
    };

    public getAllAudiences = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm,
            ...(filter ? objectFilterToQueryParam(filter) : {})
        });
        return response;
    };

    public getAudienceById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true
        });
        return response;
    };

    public createAudience = async (
        audience: Audience,
        objectIdsToAddAudienceTo?: string[]
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences`;
        const body = JSON.stringify({ ...audience, objectIdsToAddAudienceTo });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateAudience = async (
        audience: Audience,
        shouldUnlockAfterSave: boolean = false,
        objectIdsToAddAudienceTo?: string[]
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences`;
        const body = JSON.stringify({ ...audience, objectIdsToAddAudienceTo });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', {
            unlock: shouldUnlockAfterSave
        });
        return response;
    };

    public deleteAudience = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public validateAudience = async (audience: any, projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences/validate`;
        const body = JSON.stringify({ ...audience, projectId });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public purgeCdn = async (token: string, audienceId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences/purge`;

        const body = JSON.stringify({ purgeToken: token, _id: audienceId });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    /*CONDITIONAL DISPLAY*/

    public getAllDisplayConditions = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/displayConditions`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm,
            ...(filter ? objectFilterToQueryParam(filter) : {})
        });
        return response;
    };

    public getPredefinedValues = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/displayConditions/values`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');

        return response;
    };

    public createDisplayCondition = async (condition: DisplayCondition): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/displayConditions`;
        const body = JSON.stringify(condition);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateDisplayCondition = async (
        condition: DisplayCondition,
        shouldUnlockAfterSave: boolean = false
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/displayConditions`;
        const body = JSON.stringify(condition);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', { unlock: shouldUnlockAfterSave });
        return response;
    };

    public deleteDisplayCondition = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/displayConditions`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /*SOURCES*/
    public getAllSources = async (
        addPermissions?: boolean,
        projectId?: string,
        searchTerm?: string,
        nestLevel?: number
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            searchTerm,
            level: nestLevel
        });
        return response;
    };

    public getSourceById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true
        });
        return response;
    };

    public getAllServices = async (projectId?: string, forTemplates?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources/services`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            projectId,
            forTemplates
        });
        return response;
    };

    public getAll3rdPartyServices = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources/thirdPartyServices`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public createSource = async (source: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources`;
        const body = JSON.stringify(source);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public updateSource = async (source: any, shouldUnlockAfterSave = false): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources`;
        const body = JSON.stringify(source);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', { unlock: shouldUnlockAfterSave });
        return response;
    };

    public deleteSource = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/dynamicSources`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /*MODULES*/

    public getModulesTemplates = async (projectId?: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/modules/templates`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId });
        return response;
    };

    public getModules = async (
        addPermissions?: boolean,
        projectId?: string,
        nestLevel?: number,
        pageSize?: number,
        pageNumber?: number,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/modules`;

        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            level: nestLevel,
            pageNumber,
            pageSize,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });
        return response;
    };

    public createModule = async (module: Module): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/modules`;
        const body = JSON.stringify(module);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateModule = async (module: Module, shouldUnlockAfterSave: boolean = false): Promise<FetchResponse<ConfigServiceResponse>> => {
        const updatedModule: Module = { ...module };
        const autoScrollKey = 'autoScroll';

        // we should avoid sending 'null' autoscroll values to be
        if (autoScrollKey in module && module.autoScroll === null) {
            delete updatedModule[autoScrollKey];
        }
        let url = `${this.baseUrl}/objects/modules`;

        const body = JSON.stringify(updatedModule);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', { unlock: shouldUnlockAfterSave });
        return response;
    };

    public deleteModule = async (_id: string, withChildren?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/modules`;
        const body: any = { _id };

        const response = await this.execute<ConfigServiceResponse>(
            JSON.stringify(body),
            entityDataParser,
            url,
            'DELETE',
            withChildren ? { withChildren } : undefined
        );
        return response;
    };

    public deleteModuleList = async (modules: string[], withChildren?: boolean): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/modules/list`;
        const body: any = { modules };

        const response = await this.execute<ConfigServiceResponse>(
            JSON.stringify(body),
            entityDataParser,
            url,
            'DELETE',
            withChildren ? { withChildren } : undefined
        );
        return response;
    };

    /*ITEMS*/

    public getItems = async (
        addPermissions?: boolean,
        projectId?: string,
        nestLevel?: number,
        pageSize?: number,
        pageNumber?: number,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        let url = `${this.baseUrl}/objects/items`;

        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            level: nestLevel,
            pageNumber,
            pageSize,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });
        return response;
    };

    public getItemById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { full: true });
        return response;
    };

    public getContentSourceTypes = async (projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/contentSourceTypes`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId });
        return response;
    };

    public getItemTypes = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/itemTypes`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getContentActionTypes = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/actionTypes`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getDeepinkOptions = async (projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/deeplinkOptions`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId });
        return response;
    };

    public createItem = async (item: Item): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items`;
        const body = JSON.stringify(item);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateItem = async (item: Item, shouldUnlockAfterSave: boolean = false): Promise<FetchResponse<ConfigServiceResponse>> => {
        let url = `${this.baseUrl}/objects/items`;

        const body = JSON.stringify(item);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', { unlock: shouldUnlockAfterSave });
        return response;
    };

    public deleteItem = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public publishItem = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/publish`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public deleteItemList = async (items: string[]): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/items/list`;
        const body = JSON.stringify({ items });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /* TEMPLATES */

    public getTemplates = async (
        type?: string,
        released?: boolean,
        projectId?: string,
        pageSize?: number,
        pageNumber?: number,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates`;
        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};

        const query: any = {
            type,
            full: true,
            pageNumber,
            pageSize,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        };
        if (released) query.released = true;
        if (projectId) query.projectId = projectId;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', query);
        return response;
    };

    public getTemplatesTags = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates/tags`;

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getTemplate = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { full: true });
        return response;
    };

    public createTemplate = async (template: Template): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates`;
        const body = JSON.stringify(template);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public releaseTemplate = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates/release`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public retractTemplate = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates/retract`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public updateTemplate = async (template: Template): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates`;
        const body = JSON.stringify(template);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteTemplate = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public applyTemplate = async (
        id: string,
        projectId: string,
        tenantId: string,
        pageId?: string,
        index?: number,
        groupId?: string,
        moduleId?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/templates/apply`;
        const body: any = { id, projectId, tenantId };
        if (pageId) {
            body.pageId = pageId;
        }
        if (typeof index === 'number') {
            body.index = index;
        }
        if (groupId) {
            body.groupId = groupId;
        }
        if (moduleId) {
            body.moduleId = moduleId;
        }
        const response = await this.execute<ConfigServiceResponse>(JSON.stringify(body), entityDataParser, url, 'POST');
        return response;
    };

    /* FILE MANAGER */

    public getFiles = async (
        prefix: string,
        filter?: FilterType,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            prefix,
            filter,
            orderBy,
            searchTerm
        });
        return response;
    };

    public getFile = async (path: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/byPath`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { path: encodeURIComponent(path) });
        return response;
    };

    public getSystemIcons = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/icons`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getTemplateIcons = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/templateIcons`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public getCustomIcons = async (projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/customIcons`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId });
        return response;
    };

    public getClientConfig = async (projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/config`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { prefix: projectId });
        return response;
    };

    public getUploadToken = async (projectId: string, fileSize?: number): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/token`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { prefix: projectId, fileSize });
        return response;
    };

    public uploadFiles = async (formData: FormData): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager`;
        const response = await this.execute<ConfigServiceResponse>(formData, entityDataParser, url, 'POST', undefined, {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-compression': 'true',
            'x-webui-only-access-secret': SECRET_WEBUI_ACCESS_KEY
        });
        return response;
    };

    public checkPlacement = async (path: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/checkPlacement`;
        const body = JSON.stringify({ path });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public encodeVideo = async (prefix: string, fileName: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/encode`;
        const body = JSON.stringify({ prefix, name: fileName });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public checkEncoded = async (path: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/checkEncoded`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { path });
        return response;
    };

    public updateFile = async (file: {
        originalPath: string;
        newPath: string;
        overwrite?: boolean;
    }): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager`;
        const body = JSON.stringify(file);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteFile = async (originalPath: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager`;
        const body = JSON.stringify({ path: originalPath });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public createFolder = async (folder: { prefix: string; folderName: string }): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/folder`;
        const body = JSON.stringify(folder);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public deleteFolder = async (folder: { prefix: string; folderName: string }): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/folder`;
        const body = JSON.stringify(folder);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public purgeImgixPath = async (path: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/filemanager/purgeImgixPath`;
        const body = JSON.stringify({ path });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    /* SEARCH */

    public globalSearch = async (projectId: string, searchTerm: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/search`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId, searchTerm });
        return response;
    };

    /* LOCK SYSTEM */
    public lockObject = async (_id: string, objectType: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/lock`;
        const body = JSON.stringify({ _id, objectType });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public unlockObject = async (_id: string, objectType: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/unlock`;
        const body = JSON.stringify({ _id, objectType });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public lockAudience = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences/lock`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public unlockAudience = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/audiences/unlock`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /* UNPUBLISHED_CHANGES / REVERT */

    public getAllWithUnpublishedChanges = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/allWithUnpublishedChanges`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm
        });
        return response;
    };

    public revertObjects = async (objectIds: string[]): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/revert`;
        const body = JSON.stringify({ objectIds });

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /* PREVIEWS */
    public getPreviews = async (
        link: string,
        service: string,
        specificAsset?: string,
        noOfItems?: number,
        sourceId?: string,
        projectId?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/previews`;
        const query: any = {
            url: link,
            service
        };
        if (specificAsset) {
            query.specificAsset = specificAsset;
        }
        if (noOfItems) {
            query.noOfItems = noOfItems;
        }
        if (sourceId) {
            query.sourceId = sourceId;
        }

        if (projectId) {
            query.projectId = projectId;
        }

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', query);
        return response;
    };

    public assetstoreSearch = async (
        assetType: string,
        languages: string[],
        searchTerm: string,
        genres?: string[],
        releaseYearStart?: number,
        releaseYearEnd?: number,
        streamingRights?: string,
        availabilityTypes?: string,
        sports?: string,
        tournaments?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/previews/assetstoreSearch`;
        const query: any = {
            assetType,
            languages: languages?.length ? languages.join('|') : undefined,
            searchTerm,
            genres: genres?.length ? genres.join('|') : undefined,
            releaseYearStart,
            releaseYearEnd,
            streamingRights,
            availabilityTypes,
            sports,
            tournaments
        };

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', query);
        return response;
    };

    /* ANALYTICS */

    public getPlatformData = async (
        fromDate: number,
        toDate: number,
        deviceCategory: string,
        tenantId: string,
        projectId: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/analytics/platform`;

        const query: any = {
            fromDate,
            toDate,
            deviceCategory,
            projectId,
            tenantId
        };
        if (deviceCategory === DEVICE_CATEGORIES.all) delete query['deviceCategory'];
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', query);
        return response;
    };

    /* COPY OBJECT TO PROJECT */
    public copyObject = async (
        _id: any,
        objectType: string,
        projectId: string,
        newTemplateValues?: { moduleId: string; template: string }[]
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/copy`;
        const body = JSON.stringify({ _id, objectType, projectId, newTemplateValues });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public copyObjectCheck = async (_id: any, objectType: string, projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/copy/check`;
        const body = JSON.stringify({ _id, objectType, projectId });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    /* APPLICATIONS */
    public getApplications = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/applications`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };
    public createApplication = async (application: Application): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/applications`;

        const body = JSON.stringify(application);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public updateApplications = async (application: Application): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/applications`;

        const body = JSON.stringify(application);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deleteApplication = async (applicationKey: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/applications`;

        const body = JSON.stringify({ applicationKey });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /* CONFIGURABLE VALUES */
    public getValues = async (type: string, projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/configurableValues/${type}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId });
        return response;
    };

    public createValue = async (
        type: string,
        value: string | number,
        projectId: string,
        label?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/configurableValues`;
        const body: any = { type, projectId, value };
        if (label) body.label = label;

        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    public updateValue = async (
        type: string,
        oldValue: string | number,
        value: string | number,
        projectId: string,
        label?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/configurableValues`;
        const body: any = { type, projectId, oldValue, value };
        if (label) body.label = label;

        const response = await this.execute<ConfigServiceResponse>(JSON.stringify(body), entityDataParser, url, 'PUT');
        return response;
    };

    public deleteValue = async (type: string, value: string | number, projectId: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/configurableValues`;
        const body = JSON.stringify({ type, projectId, value });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    /* CC SETTINGS */
    public getCCSettings = async (): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/ccSettings`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');
        return response;
    };

    public updateCCSettings = async (ccSettings: CCSettings): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/ccSettings`;
        const body = JSON.stringify(ccSettings);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /* FILTER DATA */
    public getFilterData = async (projectId: string, objectType: ObjectType): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/filterData`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', { projectId, objectType });
        return response;
    };

    /* OBJECT ACTIVITY */
    public getObjectActivity = async (
        objectId: string,
        objectType: ObjectType,
        withObjectName?: boolean
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/objectActivity`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            objectId,
            objectType,
            withObjectName
        });
        return response;
    };

    /* ADMIN LOCK SYSTEM */
    public adminLockObject = async (_id: string, objectType: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/adminLock`;
        const body = JSON.stringify({ _id, objectType });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public adminUnlockObject = async (_id: string, objectType: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/adminUnlock`;
        const body = JSON.stringify({ _id, objectType });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /* DEACTIVATE OBJECT SYSTEM */
    public deactivateObject = async (_id: string, objectType: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/deactivate`;
        const body = JSON.stringify({ _id, objectType });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public deactivateObjectList = async (objectIds: string[], objectType: any): Promise<FetchResponse<FailedDeletionMessages>> => {
        const url = `${this.baseUrl}/objects/deactivate/list`;
        const body = JSON.stringify({ objectIds, objectType });
        const response = await this.execute<FailedDeletionMessages>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public activateObject = async (_id: string, objectType: any): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/activate`;
        const body = JSON.stringify({ _id, objectType });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT');
        return response;
    };

    public activateObjectList = async (objectIds: string[], objectType: any): Promise<FetchResponse<FailedDeletionMessages>> => {
        const url = `${this.baseUrl}/objects/activate/list`;
        const body = JSON.stringify({ objectIds, objectType });
        const response = await this.execute<FailedDeletionMessages>(body, entityDataParser, url, 'PUT');
        return response;
    };

    /* FATURE FLAGS */
    public getAllFeatureFlags = async (
        addPermissions?: boolean,
        projectId?: string,
        orderBy?: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags`;
        const filtersToPass = filter ? objectFilterToQueryParam(filter) : {};

        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true,
            permissions: addPermissions,
            projectId,
            orderBy,
            searchTerm,
            ...(Object.keys(filtersToPass).length && filtersToPass)
        });

        return response;
    };

    public getFeatureFlagById = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            full: true
        });

        return response;
    };

    public createFeatureFlag = async (
        featureFlag: FeatureFlag,
        addObjectToGroup: boolean
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags`;
        const body = JSON.stringify(featureFlag);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST', { addObjectToGroup });
        return response;
    };

    public updateFeatureFlag = async (
        featureFlag: FeatureFlag,
        shouldUnlockAfterSave: boolean = false,
        shouldRepublish: boolean = false,
        addObjectToGroup: boolean = false
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags`;
        const body = JSON.stringify(featureFlag);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PUT', {
            unlock: shouldUnlockAfterSave,
            shouldRepublish,
            addObjectToGroup
        });
        return response;
    };

    public deleteFeatureFlag = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'DELETE');
        return response;
    };

    public publishFeatureFlag = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags/publish`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };
    public abortPublishFeatureFlag = async (_id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/objects/featureFlags/publish/abort`;
        const body = JSON.stringify({ _id });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');
        return response;
    };

    /* ASSET MANAGER */

    public getAssets = async (
        tenantId: string,
        projectId: string,
        offset: number,
        size: number,
        searchTerm?: string
    ): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/assetmanager`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET', {
            tenantId,
            projectId,
            offset,
            size,
            ...(searchTerm && { searchTerm })
        });

        return response;
    };

    public createAsset = async (asset: ContentAsset): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/assetmanager`;
        const body = JSON.stringify(asset);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');

        return response;
    };

    public updateAsset = async (asset: ContentAsset): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/assetmanager/${asset.id}`;
        const body = JSON.stringify(asset);
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'PATCH');

        return response;
    };

    public deleteAsset = async (id: string): Promise<FetchResponse<ConfigServiceResponse>> => {
        const url = `${this.baseUrl}/assetmanager/${id}`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'DELETE');

        return response;
    };

    /* IMPORT */
    public startImport = async (countries: string[]) => {
        const url = `${this.baseUrl}/assetmanager/startImport`;
        const body = JSON.stringify({ countries });
        const response = await this.execute<ConfigServiceResponse>(body, entityDataParser, url, 'POST');

        return response;
    };

    public getImportStatus = async () => {
        const url = `${this.baseUrl}/assetmanager/importStatus`;
        const response = await this.execute<ConfigServiceResponse>(null, entityDataParser, url, 'GET');

        return response;
    };
}

export default ConfigServiceAPI.ApiInstance;
