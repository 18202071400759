export type ContentAsset = {
    id: string;
    projectId: string;
    tenantId: string;
    title: string;
    shortDescription?: string;
    longDescription?: string;
    releaseDate: number;
    copyright?: string;
    contentLanguage: string;
    countries: string[];
    tags: string;
    thumbnail: string;
    heroBackground?: string;
    video: string;
    lastUpdated?: number;
    importStatus?: AssetImportStatus;
};

export enum AssetImportStatus {
    IMPORTED = 'imported',
    NOT_IMPORTED = 'not imported'
}

export enum OverallImportStatus {
    DONE = 'done',
    IN_PROGRESS = 'in_progress',
    UNKNOWN = 'unknown'
}

export type ImportStatus = {
    status?: OverallImportStatus;
    importedAt?: number;
};
