import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { ActionWrapper, ActionIconHolder, ActionContainer } from '../../common/Actions/Actions.css';
import { WidthTableCell } from '../../common/Table/Table.css';

const theme: any = getTheme();

export const ConditionsWrapper = styled.div<{ $dialog?: boolean }>`
    margin-bottom: 24px;
    ${({ $dialog }) => !$dialog && 'margin-top: -23px;'}
`;

export const Condition = styled.div`
    height: 72px;
    box-sizing: border-box;
    padding: 20px 24px;
    margin-bottom: 1px;
    background: ${theme.palette.white.main};
    display: flex;
    gap: 24px;
    align-items: center;
    cursor: pointer;

    ${ActionContainer} {
        margin-left: auto;
    }

    &:hover {
        ${ActionWrapper} {
            ${ActionIconHolder} {
                display: inline;
                &:last-child {
                    display: none;
                }
            }
        }
    }
`;

export const SubTitleWrapper = styled.span`
    ${fontSizesCSS.Body1};
`;

export const ConditionTitle = styled.div`
    ${fontSizesCSS.Body2};
    display: flex;
    align-items: center;
    svg {
        width: 16px;
        height: 16px;
        padding-left: 12px;
    }
    ${SubTitleWrapper} {
        font-weight: normal;
    }
    &:hover {
        cursor: pointer;
    }
`;

export const ConditionValues = styled.div`
    display: flex;
    gap: 4px;
`;

export const ConditionPlacedColumn = styled.div`
    display: flex;
    gap: 4px;
`;

export const ConditionStateColumn = styled.div`
    width: 5%;
    min-width: 56px;
`;

export const ConditionTitleInput = styled.div`
    max-width: 320px;
    padding-top: 24px;

    .MuiFormControl-root {
        margin: 0;
    }
`;

export const LastModified = styled.div`
    ${fontSizesCSS.Body1};
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    img {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-left: auto;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
    }
`;

export const ActionsColumn = styled(WidthTableCell)`
    margin: 8px 0 0 auto;
`;

export const LanguageFlag = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 50%;
    object-fit: cover;
`;

export const StateToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 12px;
`;

export const ConfigurableOption = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:hover {
        svg {
            opacity: 0.7;
            &:hover {
                opacity: 1;
            }
        }
    }
    svg {
        width: 16px;
        height: 16px;
        opacity: 0;
    }
`;

export const ConfigurableOptionActions = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const ConfigurableSelectWrapper = styled.div`
    width: 100%;
    .cc_select__multi-value,
    .cc_select__single-value {
        ${ConfigurableOptionActions} {
            display: none;
        }
    }
`;
