import React, { FC, useEffect, useRef, useState } from 'react';
import GenericDialog, {
    DialogButton,
    DialogCheckbox,
    DialogFileField,
    DialogTextField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import {
    ColorCard,
    ColorName,
    ColorsContainer,
    ColorsContainerValues,
    ImageFieldContainer,
    ImageFields,
    ImageHeader,
    PageStyleSectionTitle,
    PageStyleTogglesContainer,
    StyleTextFieldWrapper
} from '../PageStyle.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import CustomColorPicker from '../../common/ColorPicker/CustomColorPicker';
import _ from 'lodash';
import { availableColors, defaultColorValues } from '../../../types/PageStyle';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { PageStylesState } from '../../../redux/slices/pageStylesSlice';
import { supportedResolutionsType } from '../../Items/Dialogs/BackgroundDialog';
import { getImgixUrl } from '../../../utils/Globals';
import { uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import {
    acceptedExtensionsPageBackground,
    acceptedExtensionsPageLogo,
    handleImages,
    pageStyleImageInfo,
    PageStyleStripeArrows
} from '../PageStyle';
import { ContentMarker } from './ContentMarkersDialog';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { OptionEntry } from '../../Modules/Dialogs/NewModule.css';
import { ContentMarkers } from '../ContentMarkers';

export type PageStyleDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (style: any) => void;
};

export const PageStyleDialog: FC<PageStyleDialogProps> = ({ open, onClose, onSave }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [styleObject, setStyleObject] = useState<any>({});
    const [applyForAll, setApplyForAll] = useState<{ pageLogo?: boolean; pageBackgroundImage?: boolean }>({
        pageLogo: true,
        pageBackgroundImage: true
    });
    const [styleName, setStyleName] = useState<string>('');
    const colorsContainerRef = useRef<HTMLDivElement>(null);
    const logoContainerRef = useRef<HTMLDivElement>(null);
    const backgroundContainerRef = useRef<HTMLDivElement>(null);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { config } = useAppSelector((state) => state.config);

    const [imageFiles, setImageFiles] = useState<{ pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType }>({
        pageLogo: {},
        pageBackgroundImage: {}
    });
    const [imageUrls, setImageUrls] = useState<{ pageLogo: supportedResolutionsType; pageBackgroundImage: supportedResolutionsType }>({
        pageLogo: {},
        pageBackgroundImage: {}
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleSave = async () => {
        let newStyle: any = {
            name: styleName,
            tenantId: activeTenantId,
            projectId: activeProjectId
        };

        if (Object.keys(styleObject).length) {
            newStyle = Object.assign(newStyle, _.pick(styleObject, ['colors', 'increasedTopMargin', 'contentMarkers']));
        }
        const imagesResult = await handleImages(config, imageFiles, imageUrls, applyForAll, {}, createFiles);

        if (imagesResult !== null) {
            imagesResult.forEach((result, i) => {
                const key = i === 0 ? 'pageLogo' : 'pageBackgroundImage';
                const value = Object.assign({ ...newStyle[key] }, { ...result });
                _.set(newStyle, key, value);
            });
        } else return;

        onSave(newStyle);
        handleClose();
    };

    const resetImageKeys = (parentKey: 'pageLogo' | 'pageBackgroundImage') => {
        let newImages: any = { ...imageFiles };
        let newStyle: any = { ...styleObject };
        let newUrls: any = { ...imageUrls };
        newImages = _.set(newImages, parentKey, {});
        newStyle = _.set(newStyle, parentKey, {});
        newUrls = _.set(newUrls, parentKey, {});

        // when there is no background image selected the increasedTopMargin values should be false
        if (parentKey === 'pageBackgroundImage') {
            newStyle.increasedTopMargin = false;
        }
        setImageFiles(newImages);
        setStyleObject(newStyle);
        setImageUrls(newUrls);
    };

    const createFiles = async (files: File[]) => {
        const prefix = `${activeProjectId}/pageStyle_backgrounds`;
        try {
            const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const handleImageSelect = (
        image: File | string,
        parentKey: 'pageLogo' | 'pageBackgroundImage',
        key: keyof supportedResolutionsType
    ) => {
        let newImages: any = { ...imageFiles };
        let newUrls: any = { ...imageUrls };
        let newStyleObject: any = { ...styleObject };

        //Deleting Image
        if (!image) {
            if (applyForAll[parentKey]) {
                newImages = _.set(newImages, parentKey, {});
                newUrls = _.set(newUrls, parentKey, {});
                newStyleObject = _.set(newStyleObject, parentKey, {});
            } else {
                newImages[parentKey] = _.omit(newImages[parentKey], key);
                newUrls[parentKey] = _.omit(newUrls[parentKey], key);
                newStyleObject[parentKey] = _.omit(newStyleObject[parentKey], key);
            }
        } else {
            //Adding Image
            if (typeof image === 'string') {
                const isOpenUrl = !image.includes(`${activeProjectId}`);
                _.set(newUrls, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
                _.set(newImages, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
                _.set(newStyleObject, `${parentKey}.${key}`, isOpenUrl ? image : getImgixUrl(config.imgixBaseUrl, image));
            } else {
                _.set(newImages, `${parentKey}.${key}`, image);
                _.set(newStyleObject, `${parentKey}.${key}`, image);
            }
        }
        // when there is no background image selected the increasedTopMargin values should be false
        if (!Object.keys(newStyleObject?.pageBackgroundImage || {}).length) {
            newStyleObject = { ...newStyleObject, increasedTopMargin: false };
        }
        setImageFiles(newImages);
        setImageUrls(newUrls);
        setStyleObject(newStyleObject);
    };

    const handleClose = () => {
        setStyleName('');
        setStyleObject({});
        setImageUrls({
            pageLogo: {},
            pageBackgroundImage: {}
        });
        setImageFiles({
            pageLogo: {},
            pageBackgroundImage: {}
        });
        onClose();
    };

    const renderContentMarkersSection = () => {
        const onContentMarkersSave = (contentMarker: ContentMarker, contentMarkerKey: string) => {
            const newStyleObject = {
                ...styleObject
            };
            newStyleObject.contentMarkers = {
                ...(newStyleObject.contentMarkers || {}),
                [contentMarkerKey]: contentMarker
            };
            setStyleObject(newStyleObject);
        };

        return <ContentMarkers existingContentMarkers={styleObject?.contentMarkers || {}} handleSave={onContentMarkersSave} />;
    };

    if (!isOpen) {
        return null;
    }
    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSave
    };

    const closeButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleClose
    };

    return (
        <GenericDialog
            title={'Page Style'}
            type={DialogTypes.PageStyle}
            actionButtons={[closeButton, saveButton]}
            onClose={handleClose}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.page_styles }}
        >
            <StyleTextFieldWrapper>
                <DialogTextField
                    label={'Page Style Name'}
                    value={styleName}
                    onChange={(evt: any) => {
                        setStyleName(evt.target.value);
                    }}
                />
            </StyleTextFieldWrapper>
            <div style={{ padding: 24, background: 'rgba(0,0,0,0.06)' }}>
                <ColorsContainer>
                    <PageStyleSectionTitle>
                        <ImageHeader>Colors</ImageHeader>
                        <PageStyleStripeArrows containerRef={colorsContainerRef} />
                    </PageStyleSectionTitle>
                    <ColorsContainerValues ref={colorsContainerRef}>
                        {availableColors.map((color, i) => (
                            <ColorCard key={`color_${i}`}>
                                <ColorName>{_.capitalize(color.split(/(?=[A-Z])/).join(' '))}</ColorName>
                                <CustomColorPicker
                                    value={styleObject?.colors?.[color] || (defaultColorValues as any)[color]}
                                    onChange={(alphaHex: string) =>
                                        setStyleObject({
                                            ...styleObject,
                                            colors: {
                                                ...styleObject?.colors,
                                                [color]: alphaHex
                                            }
                                        })
                                    }
                                />
                            </ColorCard>
                        ))}
                    </ColorsContainerValues>
                </ColorsContainer>

                <ImageFieldContainer isDialog>
                    <PageStyleSectionTitle>
                        <ImageHeader>
                            Page Background
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'style_and_branding_background_image')}
                        </ImageHeader>
                        <PageStyleStripeArrows containerRef={backgroundContainerRef} />
                    </PageStyleSectionTitle>
                    <ImageFields ref={backgroundContainerRef}>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={styleObject?.pageBackgroundImage?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll?.pageBackgroundImage ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'bigScreen')}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'bigScreen')}
                            alwaysShowLabel
                            accepts={acceptedExtensionsPageBackground}
                        />
                        {!applyForAll?.pageBackgroundImage && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={styleObject?.pageBackgroundImage?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'pc')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'pc')}
                                    alwaysShowLabel
                                    accepts={acceptedExtensionsPageBackground}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={styleObject?.pageBackgroundImage?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'tablet')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'tablet')}
                                    alwaysShowLabel
                                    accepts={acceptedExtensionsPageBackground}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={styleObject?.pageBackgroundImage?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageBackgroundImage', 'mobile')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageBackgroundImage', 'mobile')}
                                    alwaysShowLabel
                                    accepts={acceptedExtensionsPageBackground}
                                />
                            </>
                        )}
                    </ImageFields>
                    <PageStyleTogglesContainer>
                        <DialogToggleButton
                            checked={!!applyForAll?.pageBackgroundImage}
                            toggleCallback={() => {
                                setApplyForAll({
                                    ...applyForAll,
                                    pageBackgroundImage: !applyForAll?.pageBackgroundImage
                                });
                                resetImageKeys('pageBackgroundImage');
                            }}
                            text={'Apply for all screens'}
                            tooltipText={applyForAll?.pageBackgroundImage ? 'apply_for_all_on' : 'apply_for_all_off'}
                        />
                        <OptionEntry>
                            <DialogCheckbox
                                active={styleObject.increasedTopMargin}
                                value={styleObject.increasedTopMargin}
                                onClick={() => {
                                    setStyleObject({
                                        ...styleObject,
                                        increasedTopMargin: !styleObject.increasedTopMargin
                                    });
                                }}
                                text={'Increased Top Margin'}
                                disabled={!Object.keys(styleObject?.pageBackgroundImage || {}).length}
                            />
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'style_and_branding_increased_margin')}
                        </OptionEntry>
                    </PageStyleTogglesContainer>
                </ImageFieldContainer>
                <ImageFieldContainer isDialog>
                    <PageStyleSectionTitle>
                        <ImageHeader>Page Logo</ImageHeader>
                        <PageStyleStripeArrows containerRef={logoContainerRef} />
                    </PageStyleSectionTitle>
                    <ImageFields ref={logoContainerRef}>
                        <DialogFileField
                            imageInfo={pageStyleImageInfo}
                            customAddIcon={icons.addIconWhite}
                            preview={styleObject?.pageLogo?.bigScreen}
                            fieldKey={'Big Screen (1920x1080)'}
                            customLabel={applyForAll?.pageLogo ? 'All Screens' : 'Big Screen (1920x1080)'}
                            localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'bigScreen')}
                            fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'bigScreen')}
                            openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'bigScreen')}
                            alwaysShowLabel
                            accepts={acceptedExtensionsPageLogo}
                        />
                        {!applyForAll?.pageLogo && (
                            <>
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={styleObject?.pageLogo?.pc}
                                    fieldKey={'PC (1280x720)'}
                                    customLabel={'PC (1280x720)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'pc')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'pc')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'pc')}
                                    alwaysShowLabel
                                    accepts={acceptedExtensionsPageLogo}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={styleObject?.pageLogo?.tablet}
                                    fieldKey={'Tablet (960x540)'}
                                    customLabel={'Tablet (960x540)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'tablet')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'tablet')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'tablet')}
                                    alwaysShowLabel
                                    accepts={acceptedExtensionsPageLogo}
                                />
                                <DialogFileField
                                    imageInfo={pageStyleImageInfo}
                                    customAddIcon={icons.addIconWhite}
                                    preview={styleObject?.pageLogo?.mobile}
                                    fieldKey={'Mobile (360x202)'}
                                    customLabel={'Mobile (360x202)'}
                                    localFileCallBack={(file) => handleImageSelect(file, 'pageLogo', 'mobile')}
                                    fileManagerCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'mobile')}
                                    openUrlCallback={(url: string) => handleImageSelect(url, 'pageLogo', 'mobile')}
                                    alwaysShowLabel
                                    accepts={acceptedExtensionsPageLogo}
                                />
                            </>
                        )}
                    </ImageFields>
                    <DialogToggleButton
                        checked={!!applyForAll?.pageLogo}
                        toggleCallback={() => {
                            setApplyForAll({
                                ...applyForAll,
                                pageLogo: !applyForAll?.pageLogo
                            });
                            resetImageKeys('pageLogo');
                        }}
                        text={'Apply for all screens'}
                        tooltipText={applyForAll?.pageLogo ? 'apply_for_all_on' : 'apply_for_all_off'}
                    />
                </ImageFieldContainer>
                {renderContentMarkersSection()}
            </div>
        </GenericDialog>
    );
};
