import YoutubeCardLogo from './youtubeCardLogo.svg';
import PrimeVideoCardLogo from './primeVideoCardLogo.svg';
import DaznCardLogo from './daznCardLogo.jpeg';
import HorizonSportsCardLogo from './horizonSportsCardLogo.png';
import MotorRacingCardLogo from './motorRacingCardLogo.png';
import SWR3Logo from './swr3CardLogo.png';
import TedTalksLogo from './tedTalksLogoAggregate.svg';

export const providerCardLogos = {
    YoutubeCardLogo,
    PrimeVideoCardLogo,
    DaznCardLogo,
    HorizonSportsCardLogo,
    MotorRacingCardLogo,
    SWR3Logo,
    TedTalksLogo
};
