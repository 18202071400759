import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { translationKey } from '../types/Language';
import { sortObject } from './fnSort';
import { validTranslationKeyRegex } from './fnValidator';
import { Module } from '../types/Module';
import { itemTypes } from '../types/Item';

export const getTranslationKeys = (
    key: string,
    allTranslations: Record<string, translationKey>,
    languageCodes: any,
    includeMissingTranslations: boolean = false
) => {
    if (!(key in allTranslations)) return [];

    const values = allTranslations[key].values;
    return Object.keys(values)
        .map((key) => {
            const { name } = languageCodes.find((elem: any) => elem.code === key) || {};
            if (values[key] || includeMissingTranslations) {
                return {
                    code: key,
                    name,
                    translation: values[key]
                };
            }
        })
        .map((i) => i);
};

export const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) return 'n/a';

    const index = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`);
    if (index === 0) return `${bytes}${sizes[index]}`;
    return `${(bytes / 1024 ** index).toFixed(1)}${sizes[index]}`;
};

export const changeInPercentage = (a: number, b: number) => {
    return Math.floor(100 * Math.abs((b - a) / a));
};

export const clientTokenGenerator = (apiKey: string, headers: any, headerKeys: { [key: string]: string }) => {
    const sentHeaders = sortObject(_.pick(headers, Object.values(headerKeys)));
    const token = Object.keys(sentHeaders).reduce((prev, curr) => prev + `${curr}=${sentHeaders[curr]}`, `apiKey=${apiKey}`);
    return token ? CryptoJS.MD5(token).toString() : '';
};

export const getRestrictedSections: (projectId: string, permissions: any) => string[] | undefined = (projectId, permissions) => {
    const permissionsSections = permissions?.restrictedSections?.find((elem: any) => elem.projectId === projectId)?.sections;
    if (permissionsSections?.includes('groups')) {
        const index = permissionsSections?.indexOf('groups');
        if (index > -1) {
            const permissions = [...permissionsSections];
            permissions.splice(index, 1, 'target_groups');
            return permissions;
        }
    }
    return permissionsSections;
};

export const flattenTranslations = (translations: any = {}, flatTranslations: any = {}) => {
    for (let key in translations) {
        let newKey = '';
        if (!validTranslationKeyRegex.test(key)) {
            const regExToReplace = /[^a-zA-Z0-9_&\-/ ]/g;
            newKey = key.replace(regExToReplace, '');
        }
        if (typeof translations[key] !== 'object') {
            if (_.has(flatTranslations, key)) {
                flatTranslations.cc_error_translation = `This file can not be saved because it contains keys that already exist`;
            }
            if (newKey) {
                flatTranslations[newKey] = translations[key];
            } else {
                flatTranslations[key] = translations[key];
            }
        } else {
            flattenTranslations(translations[key], flatTranslations);
        }
    }

    return flatTranslations;
};

export const removeEmptyParams = (obj: any) => {
    const filteredObject: any = {};
    Object.keys(obj)
        .filter((key) => !((typeof obj[key] === 'string' || Array.isArray(obj[key])) && !obj[key].length))
        .forEach((key) => {
            filteredObject[key] = obj[key];
        });

    return filteredObject;
};

export const checkSameOrder = (array1: string[] = [], array2: string[] = []) => {
    if (array1.length !== array2?.length) return false;
    return array1.every((element, index) => element === array2[index]);
};

export const calculateTop10offsetsForModule = (module: Module) =>
    module?.items?.reduce((acc, _, index, items) => {
        if (index === 0) {
            acc[index] = 1;
            return acc;
        }

        let previousOffset = acc[index - 1];
        let prevItem = items[index - 1];

        if (previousOffset === -1) {
            acc[index] = -1;
        } else if (prevItem.itemType === itemTypes.EDITORIAL) {
            acc[index] = previousOffset + 1;
        } else if (prevItem.maxNumberOfItems || prevItem.singleAsset) {
            acc[index] = previousOffset + (prevItem.singleAsset ? 1 : parseInt(prevItem.maxNumberOfItems));
        } else {
            acc[index] = -1;
        }

        return acc;
    }, {});

export const audienceVersionTypeSymbols: any = {
    greater_than: '>',
    lower_than: '<',
    in_between: '-'
};
