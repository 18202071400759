import React, { FC, useEffect, useRef, useState } from 'react';
import { DialogDropdownMultiple, DialogDropdownSingle } from '../../common/Dialog/GenericDialog';
import { ConfigurableValueDialog } from './ConfigurableValueDialog';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import {
    ConfigurableValuesState,
    createValue,
    deleteValue,
    fetchValues,
    unsetConfigurableValuesError,
    updateValue
} from '../../../redux/slices/configurableValuesSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { ConfigurableOption, ConfigurableOptionActions, ConfigurableSelectWrapper } from './Conditions.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { dialogConfirm } from '../../../utils/fnDialogs';
import { calculateIsTenantAdmin, PermissionsState } from '../../../redux/slices/permissionsSlice';
import tooltipsJSON from '../../../utils/tooltips.json';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';

export enum ConfigurableKeys {
    DISPLAY_CONDITIONS_SEGMENTS = 'displayConditions_segments',
    DISPLAY_CONDITIONS_RATINGS = 'displayConditions_ratings',
    DISPLAY_CONDITIONS_SUBSCRIBED = 'displayConditions_subscribed',
    DISPLAY_CONDITIONS_DATA_PROTECTION_REGULATION = 'displayConditions_dataProtectionRegulation',
    SETTINGS_CHANNEL_GENRES_LIST = 'settings_channelGenresList',
    SETTINGS_DEVICE_PROFILE_MECHANISM = 'settings_deviceProfileMechanism',
    ITEM_ALTERATE_BEHAVIOUR_LABEL = 'item_alterateBehaviourLabel',
    MODULE_ALTERATE_BEHAVIOUR_LABEL = 'module_alterateBehaviourLabel'
}

const configurableTitles = {
    [ConfigurableKeys.DISPLAY_CONDITIONS_SEGMENTS]: 'Segment',
    [ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS]: 'Age Rating',
    [ConfigurableKeys.DISPLAY_CONDITIONS_SUBSCRIBED]: 'Package',
    [ConfigurableKeys.DISPLAY_CONDITIONS_DATA_PROTECTION_REGULATION]: 'Data Protection Regulation',
    [ConfigurableKeys.SETTINGS_CHANNEL_GENRES_LIST]: 'Channel Genres List',
    [ConfigurableKeys.SETTINGS_DEVICE_PROFILE_MECHANISM]: 'Device Profile Mechanism',
    [ConfigurableKeys.ITEM_ALTERATE_BEHAVIOUR_LABEL]: 'Label',
    [ConfigurableKeys.MODULE_ALTERATE_BEHAVIOUR_LABEL]: 'Label'
};

export type ConfigurableSelectProps = {
    id?: string;
    type: ConfigurableKeys;
    selectedValue: ConfigurableValue;
    error?: string;
    isMultiple?: boolean;
    onSelectedValuesChange: (arg: any) => void;
    noLabel?: boolean;
    noError?: boolean;
    orderMatters?: boolean;
    noPlaceholderForSelect?: boolean;
    clearable?: boolean;
    newValueTooltipKey?: string;
    tooltipKey?: string;
    placeholder?: string;
    newOptionName?: string;
    isDisabled?: boolean;
    hideLabelField?: boolean;
};

type ConfigurableValue = string | number | string[] | number[] | null;

export const ConfigurableSelect: FC<ConfigurableSelectProps> = ({
    id,
    type,
    selectedValue,
    error,
    isMultiple,
    onSelectedValuesChange,
    noLabel,
    noError,
    orderMatters,
    noPlaceholderForSelect,
    clearable,
    newValueTooltipKey,
    tooltipKey,
    placeholder,
    newOptionName,
    isDisabled,
    hideLabelField
}) => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { configurableValues, loading }: ConfigurableValuesState = useAppSelector((state) => state.configurableValues);
    const dispatch = useDispatch();

    const [showNewValueDialog, setShowNewValueDialog] = useState<boolean>(false);
    const [valueToEdit, setValueToEdit] = useState<string | number>('');
    const [labelToEdit, setLabelToEdit] = useState<string>('');

    const [selectError, setSelectError] = useState<any>(null);

    const isAdmin = userPermissions?.isSuperAdmin || (activeTenantId && calculateIsTenantAdmin(activeTenantId, userPermissions));
    const title = configurableTitles?.[type] || '';
    const currentValueRef = useRef<ConfigurableValue>(null);
    const { values = [], labels = {} } = configurableValues?.[type] || {};

    useEffect(() => {
        currentValueRef.current = selectedValue;
    }, [selectedValue]);

    const isValueSelected = (value: string | number): boolean => {
        const currentValue = currentValueRef.current;
        if (!Array.isArray(currentValue)) return currentValue === value;

        return typeof value === 'string'
            ? (currentValue as Array<string>).includes(value)
            : typeof value === 'number'
            ? (currentValue as Array<number>).includes(value)
            : false;
    };

    const _createValue = async (value: string | number, label?: string) => {
        try {
            await dispatch(createValue({ type, projectId: activeProjectId || '', value, label })).unwrap();
            await loadValues();
            onSelectedValuesChange(Array.isArray(currentValueRef.current) ? [...currentValueRef.current, value] : value);
        } catch (ex: any) {
            const error = ex?.error?.message ? ex.error : { message: 'unknown error' };
            setSelectError(error);
        }
    };

    const _updateValue = async (value: string | number, label?: string) => {
        const currentValue = currentValueRef.current;

        try {
            await dispatch(updateValue({ type, projectId: activeProjectId || '', value, oldValue: valueToEdit, label })).unwrap();

            await loadValues();

            if (!isValueSelected(valueToEdit)) return;

            if (Array.isArray(currentValue)) {
                const index =
                    typeof valueToEdit === 'number'
                        ? (currentValue as Array<number>).indexOf(valueToEdit)
                        : (currentValue as Array<string>).indexOf(valueToEdit);
                const newValues = [...currentValue];
                newValues.splice(index, 1, value);
                onSelectedValuesChange(newValues);
                return;
            }

            onSelectedValuesChange(value);
        } catch (err) {
            const error = err?.error?.message ? err.error : { message: 'unknown error' };
            setSelectError(error);
        }
    };

    const _deleteValue = async (value: string | number) => {
        try {
            await dispatch(deleteValue({ type, projectId: activeProjectId || '', value })).unwrap();
            await loadValues();

            if (isValueSelected(value)) {
                onSelectedValuesChange(
                    Array.isArray(currentValueRef.current) ? [...currentValueRef.current].filter((val) => val !== value) : ''
                );
            }
        } catch (err) {
            setSelectError(err);
        }
    };

    const loadValues = async () => {
        await dispatch(fetchValues({ type, projectId: activeProjectId || '' })).unwrap();
    };

    const handleDeleteClick = (key: string | number) => {
        const deleteDialogValues = {
            title: `Delete ${title}`,
            text: `Are you sure you want to delete this ${title}?`
        };
        dialogConfirm(
            '',
            () => {
                _deleteValue(key);
            },
            deleteDialogValues,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            }
        );
    };

    useEffect(() => {
        if (!type) return;
        if (!configurableValues?.[type]) {
            loadValues();
        }
    }, [type]);

    useEffect(() => {
        type && activeProjectId && loadValues();
    }, [activeProjectId]);

    const configurableOptions = [...values].map((key) => {
        const label = labels?.[key] || (type === ConfigurableKeys.DISPLAY_CONDITIONS_RATINGS ? `${key}+` : `${key}`);
        return {
            value: key,
            label: (
                <ConfigurableOption>
                    <span>{label}</span>

                    {isAdmin && (
                        <ConfigurableOptionActions>
                            <SVGInline
                                key={'editIcon' + key}
                                src={icons.editIcon}
                                onMouseDown={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    setShowNewValueDialog(true);
                                    setValueToEdit(key);
                                    setLabelToEdit(labels?.[key]);
                                }}
                            />

                            <SVGInline
                                key={'deleteIcon' + key}
                                src={icons.trashIcon}
                                onMouseDown={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    handleDeleteClick(key);
                                }}
                            />
                        </ConfigurableOptionActions>
                    )}
                </ConfigurableOption>
            ),
            valueForSearch: `${key} ${label}`
        };
    });

    const multiSelectValue = Array.isArray(selectedValue)
        ? (selectedValue as Array<string | number>)
              .map((value) => {
                  const option = configurableOptions.find((elem) => elem.value === value);
                  if (!option) return null;
                  return {
                      value: value,
                      label: option?.label,
                      valueForSearch: option?.valueForSearch
                  };
              })
              // filter out nulls, this happens when a value gets deleted,
              // there will be a ticket where we decide how we handle the deletion of a configurable value on the backend,
              // but until then we hide it on the frontend
              .filter((val) => val)
        : [];

    const singleSelectValue = !Array.isArray(selectedValue) ? selectedValue : '';

    return (
        <>
            <ConfigurableSelectWrapper>
                {isMultiple ? (
                    <DialogDropdownMultiple
                        id={id}
                        options={configurableOptions}
                        value={multiSelectValue}
                        onChange={(val: any) => {
                            onSelectedValuesChange(val.map((v: any) => v.value));
                        }}
                        placeholder={placeholder || `Select ${title}`}
                        labelText={placeholder || `Select ${title}`}
                        newOption={{
                            name: newOptionName || title,
                            onClick: () => {
                                setShowNewValueDialog(true);
                            }
                        }}
                        allowSelectAll
                        isDisabled={isDisabled || loading}
                        error={error}
                        noLabel={noLabel}
                        noError={noError}
                        orderMatters={orderMatters}
                        noPlaceholder={noPlaceholderForSelect}
                        toolTipText={tooltipKey ? (tooltipsJSON as any)[tooltipKey] : undefined}
                    />
                ) : (
                    <DialogDropdownSingle
                        options={configurableOptions}
                        placeholder={placeholder || `Select ${title}`}
                        labelText={placeholder || `Select ${title}`}
                        onChange={(value: any) => {
                            onSelectedValuesChange(value.value);
                        }}
                        value={configurableOptions.find((opt) => opt.value === singleSelectValue) || ''}
                        error={error}
                        newOption={{
                            name: newOptionName || title,
                            onClick: () => {
                                setShowNewValueDialog(true);
                            }
                        }}
                        isDisabled={isDisabled || loading}
                        noPlaceholder={noPlaceholderForSelect}
                        noError={noError}
                        noLabel={noLabel}
                        clearable={clearable}
                        toolTipText={tooltipKey ? (tooltipsJSON as any)[tooltipKey] : undefined}
                    />
                )}
            </ConfigurableSelectWrapper>

            <ConfigurableValueDialog
                type={type}
                title={title}
                open={showNewValueDialog}
                onClose={() => {
                    setShowNewValueDialog(false);
                    setValueToEdit('');
                    setLabelToEdit('');
                }}
                existingValue={valueToEdit}
                existingLabel={labelToEdit}
                onSave={(value, label) => {
                    !!valueToEdit ? _updateValue(value, label) : _createValue(value, label);
                }}
                hideLabelField={hideLabelField}
                tooltipKey={newValueTooltipKey}
            />
            {selectError && (
                <BackendErrorDialog
                    error={selectError}
                    customCallback={() => {
                        setSelectError(null);
                        dispatch(unsetConfigurableValuesError());
                    }}
                />
            )}
        </>
    );
};
